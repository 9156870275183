import { useState } from 'react';
import moment from 'moment';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { Box, Button, Menu, PaperProps, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type DateRangeFilterProp = {
	fromDateTime: any;
	toDateTime: any;
	minDateTime?: any;
	maxDateTime?: any;
	handleChange: any;
	handleApplyDateChange: any;
};

const DateTimePickerPaperProps: Partial<PaperProps> = {
	sx: {
		'.MuiPickersPopper-root': {
			top: 0
		},
		'.MuiButtonBase-root': {
			borderRadius: 0,
			background: 'transparent',
			color: 'white.main'
		},
		'.MuiTypography-root': {
			fontSize: '15px',
			padding: 0,
			border: 'none'
		},
		'.MuiTypography-root.Mui-selected': {
			color: '#00d08d',
			border: 'none'
		},
		'.MuiPickersDay-root.Mui-selected': {
			backgroundColor: '#00d08d !important'
		},
		'.MuiClockNumber-root': {
			color: 'white.main'
		},
		'.MuiClockPicker-root': {
			marginTop: '10px'
		},
		'.MuiDateTimePickerToolbar-separator': {
			padding: '10px'
		},
		'.MuiDateTimePickerToolbar-dateContainer .MuiTypography-root': {
			fontFamily: 'Montserrat !important',
			color: 'text.main',
			textTransform: 'unset',
			fontSize: '15px',
			fontWeight: 700
		},
		'.MuiDayPicker-weekDayLabel': {
			color: 'text.main',
		},
	}
}

const DateRangeFilter = ({
	fromDateTime,
	toDateTime,
	minDateTime,
	maxDateTime,
	handleChange,
	handleApplyDateChange
}: DateRangeFilterProp) => {
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = useState<null | HTMLElement>(null);
	const [dateTime, setDateTime] = useState<any>([fromDateTime, toDateTime]);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateMenuClose = () => {
		setDateRangeMenuOpen(null);
	};

	const handleDateTimeChange = (from: any, to: any) => {
		setDateTime([from, to]);
		handleChange([from, to]);
	};

	const handleClearDateRange = () => {
		const from = moment().startOf('day').subtract(1, 'hours');
		const to = moment().endOf('day').subtract(1, 'hours');
		handleDateTimeChange(from, to);
		handleDateMenuClose();
	};

	const handleApplyChanges = () => {
		handleApplyDateChange(dateTime);
		handleDateMenuClose();
	};

	return (
		<>
			<Box
				onClick={handleDateClick}
				sx={{
					display: 'flex',
					alignItems: 'center',
					bgcolor: 'blue.dark',
					width: 'max-content',
					borderRadius: '4px',
					cursor: 'pointer',
					p: 1,
					gap: 2,
				}}
			>
				<Typography lineHeight="normal">
					{dateTime[0] && moment(dateTime[0]).tz('America/New_York').format('DD-MM-YYYY')}&nbsp;To&nbsp;
					{dateTime[1] && moment(dateTime[1]).tz('America/New_York').format('DD-MM-YYYY')}
				</Typography>
				<DatePickerIcon />
			</Box>

			<Menu
				id="date-range-menu"
				MenuListProps={{
					'aria-labelledby': 'date-range-button'
				}}
				anchorEl={dateRangeMenuOpen}
				open={!!dateRangeMenuOpen}
				onClose={handleDateMenuClose}
				PaperProps={{
					style: {
						width: '30ch'
					},
				}}
			>
				<LocalizationProvider dateAdapter={AdapterMoment}>
					<Stack py={1} px={2} gap={2}>
						<Typography>Absolute time range</Typography>
						<Stack>
							<Typography>From</Typography>
							<DateTimePicker
								InputProps={{
									sx: {
										bgcolor: 'primary.dark',
										'&:hover': {
											borderBottom: '1px solid #00d08d',
											borderRadius: 0
										}
									}
								}}
								PaperProps={DateTimePickerPaperProps}
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{ placement: 'auto-end' }}
								toolbarTitle={
									<Typography
										sx={{
											color: 'white.main',
											marginBottom: '10px'
										}}
									>{`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}</Typography>
								}
								ampm={false}
								value={moment(dateTime[0]).tz('America/New_York')}
								minDateTime={minDateTime && moment(minDateTime).tz('America/New_York')}
								maxDateTime={maxDateTime && moment(maxDateTime).tz('America/New_York')}
								onChange={(value) => {
									handleDateTimeChange(value, dateTime[1]);
								}}
								renderInput={(params: any) => <TextField {...params} fullWidth name="startDateTime" />}
							/>
						</Stack>

						<Stack>
							<Typography>To</Typography>
							<DateTimePicker
								InputProps={{
									sx: {
										bgcolor: 'primary.dark',
										'&:hover': {
											borderBottom: '1px solid #00d08d',
											borderRadius: 0
										}
									}
								}}
								PaperProps={DateTimePickerPaperProps}
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{
									placement: 'auto-end'
								}}
								toolbarTitle={
									<Typography
										sx={{
											color: 'white.main',
											marginBottom: '10px'
										}}
									>{`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}</Typography>
								}
								ampm={false}
								value={moment(dateTime[1]).tz('America/New_York')}
								minDateTime={moment(dateTime[0]).tz('America/New_York')}
								maxDateTime={maxDateTime && moment(maxDateTime).tz('America/New_York')}
								onChange={(value) => {
									handleDateTimeChange(dateTime[0], value);
								}}
								renderInput={(params: any) => <TextField {...params} fullWidth name="endDateTime" />}
							/>
						</Stack>
						<Stack direction="row" gap={2}>
							<Button variant="contained" style={{ width: '50%' }} onClick={handleClearDateRange}>
								Clear
							</Button>

							<Button variant="contained" onClick={handleApplyChanges} style={{ width: '50%', color: '#00d08d' }}>
								Apply
							</Button>
						</Stack>
					</Stack>
				</LocalizationProvider>
			</Menu>
		</>
	);
};

export default DateRangeFilter;

import axios from 'axios';
import { url, endpoints, isLocalEnv } from './config';
import jwt_decode from 'jwt-decode';
import moment from 'moment';


const ssoLoginLocalStore = localStorage.getItem('sso-login');
const ssoLogin = ssoLoginLocalStore ? ssoLoginLocalStore === 'true' : process.env.REACT_APP_SSO_LOGIN === 'true';
const sessionCookie = 'id_token';

export const getAuthorazieHeader = () => {
	const headers = {
		'Content-Type': 'application/json;charset=UTF-8',
		authorization: `Bearer`
	};
	const jwtToken = getCookie(sessionCookie);
	if (jwtToken) {
		headers.authorization = 'Bearer ' + jwtToken;
	}
	return headers;
};

export const setCookie = (cname: string, cvalue: string) => {
	if (isLocalEnv) {
		document.cookie = cname + '=' + cvalue + ';path=/;Secure';
	} else {
		document.cookie = cname + '=' + cvalue + ';path=/;HttpOnly;Secure';
	}
};

export const getCookie = (cname: string) => {
	const name = cname + '=';
	const ca = document.cookie.split(';');
	// console.log(ca, 'ca==');
	// console.log(cname, 'cname==');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return ''; // Cookie with the given name not found
};

export const deleteCookie = (name: string) => {
	localStorage.removeItem('jobState');
	localStorage.removeItem('alertJobState');
	localStorage.removeItem('jobExecutionsState');
	localStorage.removeItem('alertJobExecutionsState');
	localStorage.removeItem('selectedAlert');
	localStorage.removeItem('jobRunsState');
	localStorage.removeItem('sso-login');
	localStorage.clear();
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	window.location.href = '/';
	return 'Cookie Deleted Successfully';
};

export const getDecodedToken = () => {
	const jwtToken = getCookie('id_token');
	// console.log(jwtToken, 'jwtToken==')
	if (!jwtToken) {
		return false;
	}
	const decoded: any = jwt_decode(jwtToken);
	// console.log(decoded, 'decoded11111');
	localStorage.removeItem('defaultSector');
	localStorage.setItem('defaultSector', decoded.sector?.name);
	return decoded;
	// if (ssoLogin) {
	// 	const data = sessionStorage.getItem('auth') ?? 'null';
	// 	const auth = JSON.parse(data);
	// 	return auth;
	// } else {
	// 	const jwtToken = getCookie('token');
	// 	if (!jwtToken) {
	// 		return false;
	// 	}
	// 	const decoded: any = jwt_decode(jwtToken);
	// 	return decoded;
	// }
};

export const landingPage: any = {
	// Admin: '/dashboard/business',
	// admin: '/dashboard/business',
	// 'Platform Support': '/jobs',
	// User: '/dashboard/business'
	'Platform Operations Admin/SRE' : '/LandingPage',
	'PepWise System User': '/LandingPage',
	'Data Operations Admin':'/data-pipeline/programs',
	'Data Operations User': '/data-pipeline/programs',
	'D&AI Executive User': '/dataestate/executive-summary',
	'Program Executive': '/ibp/executive',
};

export const login = async (data: { email: string; password: string }) => {
	try {
		const res = await axios.post(`${url}${endpoints.login}`, data);
		if (res.data && res.data.success) {
			localStorage.setItem('defaultSector', res.data.data.user.sector['name']);
			const token = res.data.data.id_token;
			setCookie('id_token', token);
		}
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobMapping = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.jobMapping}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const jobListingFilter = async (page: string, size: string, sortingInfo: any, filterInfo: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobListingFilter}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const jobListing = async (page: string, size: string, sortingInfo: any, filterInfo: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobListing}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobSearch = async (searchDetails: any, filterInfo: any, columnKey: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		sortBy: '',
		orderBy: '',
		filter: filterInfo,
		queryText: searchDetails,
		columnKey: columnKey
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobListing}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobFilterList = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.jobFilter}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getJobOverview = async (jobId: string) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.jobOverview}?jobId=${jobId}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getJobRunHistory = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	jobId: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		jobId: jobId,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobRunHistory}`, requestBody, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getJobLineage = async (sortingInfo: any, jobId: string) => {
	const headers = getAuthorazieHeader();
	let queryString = '';
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.jobDependencies}?jobId=${jobId}${queryString}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const updateJob = async (patchData: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.jobUpdate}`, patchData, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const updateJobRunHistory = async (patchData: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.patch(`${url}${endpoints.jobRunUpdate}`, patchData, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getJobExecutionsFilter = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobExecutionsFilter}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getJobExecutions = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	isDataestate?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		isDataEstate: isDataestate
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobExecutions}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobSearchForExecutions = async (
	searchDetails: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 50,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime,
		sortBy: 'name',
		orderBy: 'asc',
		filter: filterInfo,
		jobSearch: searchDetails
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobExecutions}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getJobFutureExecutions = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		fromDateTime: moment(fromDateTime),
		toDateTime: moment(toDateTime),
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobFutureExecutions}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getJobFutureExecutionsFilter = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		fromDateTime: moment(fromDateTime),
		toDateTime: moment(toDateTime),
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobFutureExecutionsFilter}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const jobSearchForSchedules = async (
	searchDetails: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		fromDateTime: moment(fromDateTime),
		toDateTime: moment(toDateTime),
		sortBy: 'name',
		orderBy: 'asc',
		filter: filterInfo,
		jobSearch: searchDetails
	};
	try {
		const res = await axios.post(`${url}${endpoints.jobFutureExecutions}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getjobRunSchedules = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime: any,
	toDateTime: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	try {
		const res = await axios.get(`${url}${endpoints.jobRunSchedules}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobSearchForRunSchedules = async (
	searchDetails: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${1}&size=${100}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
	queryString = `${queryString}&sortBy='name'`;
	queryString = `${queryString}&orderBy='asc'`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	if (searchDetails) queryString = `${queryString}&jobSearch=${searchDetails}`;
	try {
		const res = await axios.get(`${url}${endpoints.jobRunSchedules}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getJobExecutionsMetrices = async (fromDateTime: string, toDateTime: string) => {
	const headers = getAuthorazieHeader();
	const queryString = `fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`;
	try {
		const res = await axios.get(`${url}${endpoints.jobExecutionsMetrices}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getBoxRunsList = async (boxName: any, fromDateTime: any, toDateTime: any, scheduleTime: any) => {
	const headers = getAuthorazieHeader();
	const queryString = `boxName=${boxName}&fromDateTime=${fromDateTime}&toDateTime=${toDateTime}&scheduleTime=${scheduleTime}`;
	const requestBody = {
		boxName: boxName,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime
	};
	try {
		const res = await axios.post(`${url}${endpoints.boxRunsList}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSector = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.sector}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSectorSql = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.sectorSql}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDatabricksSectors = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.databricksSectors}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getReasons = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.reasonsOfSla}?${queryString}`, { headers });
		console.log(res, 'resons');
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSystem = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.system}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getProcess = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.process}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getMachines = async (page: string, size: string, sortingInfo: any, filterInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	try {
		const res = await axios.get(`${url}${endpoints.machines}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getReports = async (forDate: string) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.reports}?forDate=${forDate}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// export const getUsers = async (page: string, size: string, sortingInfo: any) => {
// 	const headers = getAuthorazieHeader();
// 	let queryString = `page=${page}&size=${size}`;
// 	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
// 	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
// 	try {
// 		const res = await axios.get(`${url}${endpoints.users}?${queryString}`, { headers });
// 		return res.data;
// 	} catch (err) {
// 		return err;
// 	}
// };

export const getActionTakenData = async (fromDateTime: string, toDateTime: string) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(
			`${url}${endpoints.jobExeActionTaken}?fromDateTime=${fromDateTime}&toDateTime=${toDateTime}`,
			{ headers }
		);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const businessDashboard = async (forDate: any, sector: any, scheduler: any) => {
	const headers = getAuthorazieHeader();

	try {
		const res = await axios.get(
			`${url}${endpoints.business}?forDate=${forDate}&sector=${sector}&scheduler=${scheduler}`,
			{ headers }
		);
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getProcessRuns = async (processId: any, forDate: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.processRuns}?processId=${processId}&forDate=${forDate}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const businessDashboardPieChart = async (forDate: any, sector: any, scheduler: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(
			`${url}${endpoints.businessPiechart}?forDate=${forDate}&sector=${sector}&scheduler=${scheduler}`,
			{ headers }
		);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const dailyCritialPieChart = async (forDate: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.dailyCritical}?forDate=${forDate}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getJobDependenciesChart = async (jobId: string) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.jobDependencyChart}?jobId=${jobId}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks summary
export const getDBSummaryDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// if(localStorage.getItem("defaultSector") && localStorage.getItem("defaultSector")!='All'){
	// 	sector=localStorage.getItem("defaultSector")
	// }
	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		sortDir: sortingInfo.sortDir,
		filter: filterInfo
	};

	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;

	try {
		const res = await axios.post(`${url}${endpoints.databricksSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Summary date filter
export const jobSearchForDBSummary = async (
	pageNumber: any,
	size: any,
	sortingInfo: any,
	fromDateTime: any,
	filterInfo: any,
	sector: any,
	searchQuery: any,
	searchField: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	let storedSectorFilter = localStorage.getItem('ds-summary-sector-filter')
		? JSON.parse(localStorage.getItem('ds-summary-sector-filter') ?? '')
		: null;
	if (storedSectorFilter) {
		sector = storedSectorFilter;
	}
	const requestBody = {
		pageNumber,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		sortDir: sortingInfo.sortDir,
		filter: filterInfo,
		searchQuery,
		searchField
	};

	// let queryString = `page=${1}&size=${100}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}`;
	// queryString = `${queryString}&sortBy='name'`;
	// queryString = `${queryString}&orderBy='asc'`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	// if (searchDetails) queryString = `${queryString}&jobSearch=${searchDetails}`;
	try {
		const res = await axios.post(`${url}${endpoints.databricksSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks workspace spent utilization
export const getDBWorkspaceSpentDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksWorkspaceSpent}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksWorkspaceSpent}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Cost summary Workspace spent
// export const jobSearchForDBWorkspace = async (
// 	searchDetails: any,
// 	filterInfo: any,
// 	fromDateTime: string,
// 	toDateTime: string
// ) => {
// 	const headers = getAuthorazieHeader();
// 	let queryString = `page=${1}&size=${100}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}`;
// 	queryString = `${queryString}&sortBy='name'`;
// 	queryString = `${queryString}&orderBy='asc'`;
// 	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
// 	if (searchDetails) queryString = `${queryString}&jobSearch=${searchDetails}`;
// 	try {
// 		const res = await axios.get(`${url}${endpoints.databricksWorkspaceSpent}?${queryString}`, { headers });
// 		return res.data;
// 	} catch (err) {
// 		return err;
// 	}
// };

export const jobSearchForDBWorkspace = async (
	searchDetails: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string,
	searchQuery: any,
	searchField: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		toDateTime: moment(toDateTime).format('YYYY-MM-DD'),
		sortBy: 'name',
		orderBy: 'asc',
		filter: filterInfo,
		searchField: searchQuery,
		searchQuery: searchDetails
	};

	try {
		const res = await axios.post(`${url}${endpoints.databricksWorkspaceSpent}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks project spent utilization
export const getDBProjectSpentDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksProjectSpent}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksProjectSpent}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Cost summary Project Spent
// export const jobSearchForProjectSpent = async (
// 	searchDetails: any,
// 	filterInfo: any,
// 	fromDateTime: string,
// 	toDateTime: string
// ) => {
// 	const headers = getAuthorazieHeader();
// 	let queryString = `page=${1}&size=${100}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}`;
// 	queryString = `${queryString}&sortBy='name'`;
// 	queryString = `${queryString}&orderBy='asc'`;
// 	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
// 	if (searchDetails) queryString = `${queryString}&jobSearch=${searchDetails}`;
// 	try {
// 		const res = await axios.get(`${url}${endpoints.databricksProjectSpent}?${queryString}`, { headers });
// 		return res.data;
// 	} catch (err) {
// 		return err;
// 	}
// };

export const jobSearchForProjectSpent = async (
	searchDetails: any,
	filterInfo: any,
	fromDateTime: string,
	toDateTime: string,
	searchQuery: any,
	searchField: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		toDateTime: moment(toDateTime).format('YYYY-MM-DD'),
		sortBy: 'name',
		orderBy: 'asc',
		filter: filterInfo,
		searchField: searchQuery,
		searchQuery: searchDetails
	};

	try {
		const res = await axios.post(`${url}${endpoints.databricksProjectSpent}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Stale Users List
export const getStaleUsersDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${JSON.stringify(sector)}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.governanceStaleUsers}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.governanceStaleUsers}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Stale Users Search
export const StaleUsersSearch = async (
	page: any,
	size: any,
	filterInfo: any,
	fromDateTime?: any,
	sector?: any,
	searchQuery?: any,
	searchField?: any
) => {
	console.log(filterInfo);
	console.log(searchQuery, searchField);
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&sector=${JSON.stringify(sector)}`;
	// if (filterInfo && Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	// if (searchQuery) queryString = `${queryString}&searchQuery=${searchQuery}&searchField=${searchField}`;
	const date =
		localStorage.getItem('processedDate') !== undefined
			? localStorage.getItem('processedDate')
			: moment(fromDateTime).format('YYYY-MM-DD');
	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		filter: filterInfo,
		searchQuery: searchQuery,
		searchField: searchField
	};

	try {
		// const res = await axios.get(`${url}${endpoints.governanceStaleUsers}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.governanceStaleUsers}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Governance Jobs List
export const getGovernanceRunningJobsDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any,
	filterByColumnInfo?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${JSON.stringify(sector)}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;
	// if (Object.keys(filterByColumnInfo).length) queryString = `${queryString}&filterBy=${encodeURIComponent(JSON.stringify(filterByColumnInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		filterBy: filterByColumnInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.governanceRunningJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.governanceRunningJobs}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Governance Jobs Search
export const GovernanceRunningjobSearch = async (
	page: any,
	size: any,
	fromDateTime: string,
	filterInfo: any,
	sector: any,
	searchQuery: string,
	searchField: string,
	filterByColumnInfo?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined
			? localStorage.getItem('processedDate')
			: moment(fromDateTime).format('YYYY-MM-DD');
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(date).format('YYYY-MM-DD')}&sector=${JSON.stringify(sector)}`;

	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	// if (searchQuery) queryString = `${queryString}&searchQuery=${searchQuery}&searchField=${searchField}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		filter: filterInfo,
		filterBy: filterByColumnInfo,
		searchField: searchField,
		searchQuery: searchQuery
	};

	try {
		// const res = await axios.get(`${url}${endpoints.governanceRunningJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.governanceRunningJobs}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Jobs List
export const getDatabricksJobsDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any,
	drillDownStatusFilter?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&sector=${JSON.stringify(sector)}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;
	// if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) queryString = `${queryString}&filter=${JSON.stringify(drillDownStatusFilter)}`;
	// console.log(queryString, 'queryString');

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filterBy: filterInfo,
		filter: {}
	};

	if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) {
		requestBody['filter'] = drillDownStatusFilter;
	}

	try {
		// const res = await axios.get(`${url}${endpoints.databricksJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksJobs}`, requestBody, { headers });
		console.log(res.data, 'res.data==');
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Jobs date filter
export const databricksJobSearch = async (
	searchQuery: any,
	searchField: any,
	fromDateTime: string,
	filterInfo: any,
	sector: any,
	drillDownStatusFilter: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${1}&size=${100}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filterBy=${JSON.stringify(filterInfo)}`;
	// if (searchQuery) queryString = `${queryString}&searchField=${searchField}&searchQuery=${searchQuery}`;
	// if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) queryString = `${queryString}&filter=${JSON.stringify(drillDownStatusFilter)}`;

	const requestBody = {
		page: '1',
		size: '50',
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		filterBy: filterInfo,
		searchField: searchField,
		searchQuery: searchQuery,
		filter: {}
	};

	if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) {
		requestBody['filter'] = drillDownStatusFilter;
	}

	try {
		// const res = await axios.get(`${url}${endpoints.databricksJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksJobs}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};


export const getWorkbooks = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	drillDownFilter: any,
	fromDate?: string,
	sector?: any,
	instance?: any,
	searchQuery?: any,
	searchField?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page,
		size,
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		drillDownFilter,
		searchQuery,
		searchField
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauWorkbooks}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getTableauUsers = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any,
	searchQuery?: any,
	searchField?: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&fromDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&sectors=${sector}&instance=${instance}`;
	// let queryString = `page=${page}&size=${size}&fromDate=${moment(fromDate).format('YYYY-MM-DD')}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	if (searchField && searchQuery?.length >= 3)
		queryString = `${queryString}&searchField=${searchField}&searchQuery=${searchQuery}`;
	if (searchField) queryString = `${queryString}&searchField=${searchField}`;
	try {
		const res = await axios.get(`${url}${endpoints.tableauUsers}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// MLOBS SERVICES //
export const getModels = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	drillDownStatus: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	if (drillDownStatus) queryString = `${queryString}&drillDownStatus=${drillDownStatus}`;
	try {
		const res = await axios.get(`${url}${endpoints.models}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getModel = async (modelId: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.model}/${modelId}`);
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getModelPerformanceMetrics = async (modelId: any, inferenceId: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.modelPerformanceMetric}/${modelId}/${inferenceId}`);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getModelfeatureDriftMetrics = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	modelId: any,
	inferenceId: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	try {
		const res = await axios.get(
			`${url}${endpoints.modelfeatureDriftMetrics}/${modelId}/${inferenceId}?${queryString}`,
			{ headers }
		);
		return res.data;
	} catch (err) {
		return err;
	}
};
// MLOBS SERVICES //

// Databricks Resource Opt- CPU
export const getDBCPUUtilizationDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksCPUUtilization}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksCPUUtilization}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobSearchCPUUtilization = async (
	searchQuery: any,
	searchField: any,
	fromDateTime: any,
	filterInfo: any,
	sector: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${1}&size=${50}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	// if (searchQuery) queryString = `${queryString}&searchField=${searchField}&searchQuery=${searchQuery}`;

	const requestBody = {
		page: '1',
		size: '50',
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		filter: filterInfo,
		searchField: searchField,
		searchQuery: searchQuery
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksCPUUtilization}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksCPUUtilization}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Resource opt Memory Utilization
export const getDBMemoryUtilizationDate = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${page}&size=${size}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&toDateTime=${moment(toDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksMemoryUtilization}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksMemoryUtilization}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobSearchMemoryUtilization = async (
	searchQuery: any,
	searchField: any,
	fromDateTime: any,
	filterInfo: any,
	sector: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${1}&size=${50}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}&sector=${sector}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	// if (searchQuery) queryString = `${queryString}&searchField=${searchField}&searchQuery=${searchQuery}`;

	const requestBody = {
		page: '1',
		size: '50',
		fromDateTime: moment(fromDateTime).format('YYYY-MM-DD'),
		sector: sector,
		filter: filterInfo,
		searchField: searchField,
		searchQuery: searchQuery
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksMemoryUtilization}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksMemoryUtilization}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau Performance
export const getTableauJobs = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauPerformance}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const jobSearchTableauJobs = async (
	pageNumber: any,
	size: any,
	sortingInfo: any,
	fromDate: string,
	filterInfo: any,
	sector: any,
	instance: any,
	searchQuery: any,
	searchField: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(pageNumber),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		searchQuery: searchQuery,
		searchField: searchField
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauPerformance}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau workbook search
export const SearchForTableauWorkbooks = async (searchDetails: any, filterInfo: any, fromDate: string) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${1}&size=${100}&fromDate=${moment(fromDate).format('YYYY-MM-DD')}`;
	queryString = `${queryString}&sortBy='name'`;
	queryString = `${queryString}&orderBy='asc'`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	if (searchDetails) queryString = `${queryString}&jobSearch=${searchDetails}`;
	try {
		const res = await axios.get(`${url}${endpoints.tableauWorkbooks}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// PCOBS SERVICES

export const getResourceGroups = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	drillDownTaggedToProject: any,
	drillDownSector: any,
	drillDownBySector: any,
	drillDownTaggedToSector: any,
	resource_group_id: any,
	whereFilter?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page,
		size,
		resource_group_id: resource_group_id,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		isResourceTaggedToProject: drillDownTaggedToProject === 'Resources not tagged' ? false : '',
		isResourceGroupTaggedToSector: drillDownTaggedToSector === 'Resource groups not tagged' ? false : '',
		// whereFilter: whereFilter?.column
		whereFilter: whereFilter?.column ? encodeURIComponent(whereFilter.column) : ''
	};

	if (drillDownTaggedToProject) {
		requestBody.isResourceTaggedToProject = drillDownTaggedToProject === 'Resources not tagged' ? false : true;
	}

	if (drillDownTaggedToSector) {
		requestBody.isResourceGroupTaggedToSector = drillDownTaggedToSector === 'Resource groups not tagged' ? false : true;
	}

	let filter = {};

	if (drillDownSector || drillDownBySector) {
		filter = { ...filterInfo };
		if (drillDownSector) {
			const formattedDrillDownSector = { DFSectorname: [drillDownSector] };
			filter = { ...filter, ...formattedDrillDownSector };
		} else if (drillDownBySector) {
			const formatteddrillDownBySector = { DFSectorname: [drillDownBySector] };
			filter = { ...filter, ...formatteddrillDownBySector };
		}
	} else if (Object.keys(filterInfo).length) {
		filter = { ...filterInfo };
	} else if (drillDownSector) {
		const formattedDrillDownSector = { DFSectorname: [drillDownSector] };

		filter = { ...filter, ...(drillDownSector === 'unknown' ? {} : formattedDrillDownSector) };
	} else if (drillDownBySector) {
		const formatteddrillDownBySector = { DFSectorname: [drillDownBySector] };

		filter = { ...filter, ...(drillDownBySector === 'unknown' ? {} : formatteddrillDownBySector) };
	}

	// Add filter to requestBody if it's not empty
	if (Object.keys(filter).length) {
		requestBody.filter = filter;
	}
	try {
		const res = await axios.post(`${url}${endpoints.resourceGroups}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
// Search Resource List Filter column

export const getResourceSearchFilterColumn = async (
	filterInfo: any,
	drillDownTaggedToProject: any,
	drillDownSector: any,
	drillDownBySector: any,
	drillDownTaggedToSector: any,
	resource_group_id: any,
	searchQuery: string,
	searchField: string
) => {
	console.log(searchQuery + ' ' + searchField);
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filterInfo,
		resource_group_id,
		searchQuery,
		searchField,
		isResourceTaggedToProject: drillDownTaggedToProject === 'Resources not tagged' ? false : '',
		isResourceGroupTaggedToSector: drillDownTaggedToSector === 'Resource groups not tagged' ? false : '',
		page: '1',
		size: '100'
	};

	let filter = {};
	if (Object.keys(filterInfo).length && (drillDownSector || drillDownBySector)) {
		filter = { ...filterInfo };

		if (drillDownSector) {
			const formattedDrillDownSector = { DFSectorname: [drillDownSector] };
			filter = { ...filter, ...formattedDrillDownSector };
		} else if (drillDownBySector) {
			const formatteddrillDownBySector = { DFSectorname: [drillDownBySector] };
			filter = { ...filter, ...formatteddrillDownBySector };
		}
	} else if (Object.keys(filterInfo).length) {
		filter = { ...filterInfo };
	} else if (drillDownSector) {
		const formattedDrillDownSector = { DFSectorname: [drillDownSector] };

		filter = { ...filter, ...(drillDownSector === 'unknown' ? {} : formattedDrillDownSector) };
	} else if (drillDownBySector) {
		const formatteddrillDownBySector = { DFSectorname: [drillDownBySector] };

		filter = { ...filter, ...(drillDownBySector === 'unknown' ? {} : formatteddrillDownBySector) };
	}

	// Add filter to requestBody if it's not empty
	if (Object.keys(filter).length) {
		requestBody.filter = filter;
	}

	try {
		const res = await axios.post(`${url}${endpoints.resourceGroups}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const patchResourceGroup = async (patchData: any) => {
	const headers = getAuthorazieHeader();
	patchData.id = [patchData.id];
	try {
		const res = await axios.post(`${url}${endpoints.resourceGroup}`, patchData, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getResourceGroupSectors = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page,
		size,
		sortingInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.resourceGroupSectors}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getResources = async (
	resourceGroupId: string,
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	drillDownStatus: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	if (drillDownStatus) queryString = `${queryString}&drillDownStatus=${drillDownStatus}`;
	try {
		const res = await axios.get(
			`${url}${endpoints.resourceGroup}?resource_group_id=${resourceGroupId}&&${queryString}`,
			{
				headers
			}
		);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const patchResource = async (patchData: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.put(`${url}${endpoints.resource}`, patchData, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

// PCOBS SERVICES

export const getWorkspaceList = async (fromDate: any, sector?: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `fromDateTime=${fromDate}&sector=${JSON.stringify(sector)}`;
	try {
		const res = await axios.get(`${url}${endpoints.workspaceList}?${queryString}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getCostSummaryChart = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.costSummaryGrafana}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getDatabricksJobsChart = async (fromDateTime: any) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	let queryString = `fromDateTime=${date}`;
	try {
		const res = await axios.get(`${url}${endpoints.jobsGrafana}?${queryString}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getReasonChart = async (reasonFromDate: any, reasonRangeValue: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `forDate=${reasonFromDate}&filter=${reasonRangeValue}`;
	console.log(queryString, 'queryStringDEbug');
	try {
		const res = await axios.get(`${url}${endpoints.reasonChart}?${queryString}`, {
			headers
		});
		return res.data;
	} catch (err) {
		return err;
	}
};

// PCOBS FREE TEXT SEARCH

export const getResourceListMapping = async (
	page: string,
	pageSize: string,
	sortingInfo: any,
	filterInfo: any,
	searchQuery: any,
	whereFilter: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page,
		pageSize,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		// whereFilter
		// searchQuery,
		whereFilter: encodeURIComponent(whereFilter)
	};

	try {
		const res = await axios.post(`${url}${endpoints.resourceGroups}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

//Tableau Performance Drilldown
export const getExtractTrendDetails = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	drillDownStatus: any,
	fromDate: string,
	sector: any,
	serverFilter: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: serverFilter,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		status: drillDownStatus
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauPerformanceDrilldown}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Filter search
export const getExtractTrendDetailsSearch = async (
	page: string,
	size: any,
	sortingInfo: any,
	fromDate: string,
	filterInfo: any,
	drillDownStatus: any,
	sector: any,
	serverFilter: string,
	searchQuery: string,
	searchField: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: serverFilter,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		status: drillDownStatus,
		searchQuery: searchQuery,
		searchField: searchField
	};

	try {
		const res = await axios.post(`${url}${endpoints.tableauPerformanceDrilldown}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

/* Notification Alert Rules */
export const getAlerts = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.notificationAlerts}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
/* Notification Alert Rules */

/* Databricks Grafana Date filter */
export const getDatabricksSummaryChart = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.summaryGrafana}`, {
			headers
		});
		console.log('response', res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDatabricksResourceOptimizationChart = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.resourceOptimizationGrafana}`, {
			headers
		});
		console.log('response', res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDatabricksGovernanceChart = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.governanceGrafana}`, {
			headers
		});
		console.log('response', res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

/* End of Databricks Grafana Date filter*/

/*Databricks Inital load API call */
export const getDBSummary = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string | null,
	toDateTime?: string | null,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// if(localStorage.getItem("defaultSector") && localStorage.getItem("defaultSector")!='All'){
	// 	sector=localStorage.getItem("defaultSector")
	// }
	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		sortDir: sortingInfo.sortDir,
		filter: filterInfo
	};

	// const date = localStorage.getItem("processedDate") !== undefined ? localStorage.getItem("processedDate") : ""
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;
	try {
		const res = await axios.post(`${url}${endpoints.databricksSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Resource opt CPU Utilization
export const getDBCPUUtilization = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;

	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		const res = await axios.post(`${url}${endpoints.databricksCPUUtilization}`, requestBody, { headers });
		// const res = await axios.post(`${url}${endpoints.databricksCPUUtilization}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Resource opt Memory Utilization
export const getDBMemoryUtilization = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date = localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : '';
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksMemoryUtilization}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksMemoryUtilization}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDatabricksJobs = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any,
	drillDownStatusFilter?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${JSON.stringify(sector)}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filterBy=${encodeURIComponent(JSON.stringify(filterInfo))}`;
	// if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) queryString = `${queryString}&filter=${JSON.stringify(drillDownStatusFilter)}`;
	// console.log(queryString, 'queryString');

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filterBy: filterInfo,
		filter: {}
	};

	if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) {
		requestBody['filter'] = drillDownStatusFilter;
	}

	try {
		// const res = await axios.get(`${url}${endpoints.databricksJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksJobs}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDBWorkspaceSpent = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	console.log(sector);
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksWorkspaceSpent}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksWorkspaceSpent}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDBProjectSpent = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${sector}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.databricksProjectSpent}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.databricksProjectSpent}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getGovernanceRunningJobs = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any,
	filterByColumnInfo?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${JSON.stringify(sector)}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${encodeURIComponent(JSON.stringify(filterInfo))}`;
	// if (Object.keys(filterByColumnInfo).length) queryString = `${queryString}&filterBy=${encodeURIComponent(JSON.stringify(filterByColumnInfo))}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		filterBy: filterByColumnInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.governanceRunningJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.governanceRunningJobs}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getStaleUsers = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;
	// let queryString = `page=${page}&size=${size}&fromDateTime=${date}&toDateTime=${date}&sector=${JSON.stringify(sector)}`;
	// if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	// if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filter=${JSON.stringify(filterInfo)}`;

	const requestBody = {
		page,
		size,
		fromDateTime: date,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};

	try {
		// const res = await axios.get(`${url}${endpoints.governanceStaleUsers}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.governanceStaleUsers}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

/*End of Databricks Inital load API call*/

// Tableau Governance Detail view
export const getTableauGovernanceDetailView = async (
	page: string,
	size: string,
	sortingInfo: any,
	drillDownStatus: any,
	drillDownFilter: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filterBy: filterInfo,
		drillDownStatus: drillDownStatus,
		filter: drillDownFilter
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauGovernanceDetailView}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau Governance Users

export const getTableauGovernanceUsers = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any,
	drillDownFilter?: any
) => {
	console.log('Governance Users');
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filterBy: filterInfo,
		filter: drillDownFilter
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauGovernanceUsers}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau Governance Total Orphaned Users

export const getTableauGovernanceOrphanUsersDetails = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo?: any,
	fromDate?: string,
	sector?: any,
	instance?: any,
	searchQuery?: any,
	searchField?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		searchQuery: searchQuery,
		searchField: searchField
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauGovernanceOrphanUsers}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau Governance Total Orphaned Extracts

export const getTableauGovernanceOrphanExtractDetails = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any,
	searchQuery?: any,
	searchField?: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		searchQuery: searchQuery,
		searchField: searchField
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauGovernanceOrphanExtract}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau governance user search
export const SearchForTableauGovernanceUser = async (
	searchQuery: any,
	searchField: any,
	filterInfo: any,
	fromDate: string,
	instance: any,
	drillDownFilter: any,
	sector: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		filterBy: filterInfo,
		filter: drillDownFilter,
		searchQuery: searchQuery,
		searchField: searchField
	};
	try {
		const res = await axios.post(`${url}${endpoints.tableauGovernanceUsers}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

//Tableau governance workbook search
export const SearchForTableauGovernanceDetail = async (
	drillDownStatus: any,
	drillDownFilter: any,
	sector: any,
	searchQuery: any,
	searchField: any,
	filterInfo: any,
	fromDate: string,
	instance: any
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		fromDate: moment(fromDate).format('YYYY-MM-DD'),
		sectors: sector,
		instance: instance,
		filterBy: filterInfo,
		drillDownStatus: drillDownStatus,
		filter: drillDownFilter,
		searchQuery: searchQuery,
		searchField: searchField
	};

	try {
		const res = await axios.post(`${url}${endpoints.tableauGovernanceDetailView}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau goverance space utilized by unused extracts
export const getTableauGovernanceSpaceUtilized = async (
	page: string,
	size: string,
	sortingInfo: any,
	drillDownStatus: any,
	drillDownFilter: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&fromDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&sectors=${sector}&instance=${instance}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (drillDownStatus) queryString = `${queryString}&drillDownStatus=${drillDownStatus}`;
	if (drillDownFilter) queryString = `${queryString}&filter=${drillDownFilter}`;
	if (Object.keys(filterInfo).length)
		queryString = `${queryString}&filterBy=${encodeURIComponent(JSON.stringify(filterInfo))}`;
	try {
		const res = await axios.get(`${url}${endpoints.tableauGovernanceDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau goverance space utilized by unused extracts
export const getTableauGovernanceSpaceUtilizedSearch = async (
	searchQuery: any,
	searchField: any,
	filterInfo: any,
	fromDate?: string,
	sector?: any,
	instance?: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${1}&size=${100}&fromDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&sectors=${sector}&instance=${instance}`;
	if (searchQuery) queryString = `${queryString}&searchQuery=${searchQuery}&searchField=${searchField}`;
	if (Object.keys(filterInfo).length)
		queryString = `${queryString}&filterBy=${encodeURIComponent(JSON.stringify(filterInfo))}`;
	try {
		const res = await axios.get(`${url}${endpoints.tableauGovernanceDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Tableau Rule Observation
export const getTableauObsRules = async (page: string, size: string, sortingInfo: any, filterInfo: any, name?: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&name=${name}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) {
		const filterString = JSON.stringify(filterInfo);
		const encodedFilterString = encodeURIComponent(filterString);
		queryString = `${queryString}&filter=${encodedFilterString}`;
	}
	try {
		const res = await axios.get(`${url}${endpoints.tableauExtractFailureRuleDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Start presto summary drilldown
// Batch Ids used
export const getPrestoSummaryBatchDrilldown = async (
	page: string,
	size: string,
	fromDate: string,
	onlyBatchId: boolean,
	sortingInfo: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&filterDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&onlyBatchId=${onlyBatchId}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Uesed Ids used
export const getPrestoSummaryUserDrilldown = async (
	page: string,
	size: string,
	fromDate: string,
	onlyUserId: boolean,
	sortingInfo: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&filterDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&onlyUserId=${onlyUserId}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Completed queries
export const getPrestoSummaryCompletedQueriesDrilldown = async (
	page: string,
	size: string,
	fromDate: string,
	onlyCompletedQueries: boolean,
	sortingInfo: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&filterDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&onlyCompletedQueries=${onlyCompletedQueries}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Failed queries
export const getPrestoSummaryFailedQueriesDrilldown = async (
	page: string,
	size: string,
	fromDate: string,
	onlyFailedQueries: boolean,
	sortingInfo: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&filterDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&onlyFailedQueries=${onlyFailedQueries}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Queries executed
export const getPrestoSummaryQueriesExecutedDrilldown = async (
	page: string,
	size: string,
	fromDate: string,
	onlyQueriesExecuted: boolean,
	sortingInfo: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&filterDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&onlyQueriesExecuted=${onlyQueriesExecuted}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Largest Queries
export const getPrestoSummaryLargestQueriesDrilldown = async (
	page: string,
	size: string,
	fromDate: string,
	onlyLargestQueries: boolean,
	sortingInfo: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=20&filterDate=${moment(fromDate).format(
		'YYYY-MM-DD'
	)}&onlyLargestQueries=${onlyLargestQueries}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// when first coming to dashboard table view
export const getPrestoSummaryQueryDetails = async (page: string, size: string, fromDate: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&filterDate=${moment(fromDate).format('YYYY-MM-DD')}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.prestoSummaryDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
// End presto drildown

// Presto Rule Observation
export const getprestoObsRules = async (page: string, size: string, sortingInfo: any, filterInfo: any, name?: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&name=${name}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) {
		const filterString = JSON.stringify(filterInfo);
		const encodedFilterString = encodeURIComponent(filterString);
		queryString = `${queryString}&filter=${encodedFilterString}`;
	}
	try {
		const res = await axios.get(`${url}${endpoints.tableauExtractFailureRuleDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

// Datagrowth Databricks Storage
export const getStorage = async (page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	try {
		const res = await axios.get(`${url}${endpoints.datagrowthDatabricksStorage}?${queryString}`, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

// Databricks Rule Observation
export const getDatabricksObsRules = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	name?: any
) => {
	const headers = getAuthorazieHeader();
	let queryString = `page=${page}&size=${size}&name=${name}`;
	if (sortingInfo.sortBy) queryString = `${queryString}&sortBy=${sortingInfo.sortBy}`;
	if (sortingInfo.sortDir) queryString = `${queryString}&orderBy=${sortingInfo.sortDir}`;
	if (Object.keys(filterInfo).length) {
		const filterString = JSON.stringify(filterInfo);
		const encodedFilterString = encodeURIComponent(filterString);
		queryString = `${queryString}&filter=${encodedFilterString}`;
	}
	try {
		const res = await axios.get(`${url}${endpoints.tableauExtractFailureRuleDrilldown}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSchedulers = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.schedulers}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const ibpJobListing = async (page: string, size: string, sortingInfo: any, filterInfo: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.ibpJobListing}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const ibpJobSearch = async (searchDetails: any, filterInfo: any, columnKey: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 100,
		sortBy: '',
		orderBy: '',
		filter: filterInfo,
		queryText: searchDetails,
		columnKey: columnKey
	};
	try {
		const res = await axios.post(`${url}${endpoints.ibpJobListing}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const ibpJobRunsListing = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	gdoName?: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: parseInt(page),
		size: parseInt(size),
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		fromDateTime: '2024-04-02T12:19:11.104Z',
		toDateTime: '2024-05-02T12:19:11.104Z',
		filter: filterInfo,
		gdoName: gdoName
	};
	try {
		const res = await axios.post(`${url}${endpoints.ibpJobRunsListing}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const ibpMonitorMetrics = async () => {
	const headers = getAuthorazieHeader();
	const requestBody = {};
	try {
		const res = await axios.post(`${url}${endpoints.ibpMonitorMetrics}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const ibpCostByServiceCall = async () => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filterByService: ''
	};
	try {
		const res = await axios.post(`${url}${endpoints.ibpCostByService}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const ibpCostByServiceGrafanaCall = async (serviceName: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filterByService: serviceName
	};
	try {
		const res = await axios.post(`${url}${endpoints.ibpCostGrafana}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchBIApplicationSummary = async (filter: string, sector: any, month: any, startDate?: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		sector: sector,
		month: month,
		startDate: startDate || null,
		dateFilter: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.biApplicationSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchCrossEnvData = async (dimension: string, kpiName: string, month: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		dimension: dimension,
		vendor: '',
		month: month,
		kpiName: kpiName
	};
	try {
		const res = await axios.post(`${url}${endpoints.crossEnvKPI}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchPerformanceData = async (dimension: string, filterMonth?: string, sectors?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		dimension: dimension,
		startDate: filterMonth || null,
		sectors: sectors || [],
		dateFilter: filterMonth
	};
	try {
		const res = await axios.post(`${url}${endpoints.dataEstatePerformance}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchAnnualSpendDataSourceIncidents = async (filter: string, filterMonth?: string, sectors?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		startDate: filterMonth || null,
		sectors: sectors || [],
		dateFilter: filterMonth
	};
	try {
		const res = await axios.post(`${url}${endpoints.annualSpendDataSourceIncidents}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDatabricksClusters = async (filter: string, filterMonth?: any, sectors?: any, dateFilter?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		months: filterMonth || [],
		sector: sectors || [],
		dateFilter: dateFilter
	};
	try {
		const res = await axios.post(`${url}${endpoints.databricksClusters}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDataprocessingSummary = async (filterMonth?: string, sectors?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		groupByFilters: 'KPI_NAME,DIMENSION,MONTH',
		startDate: filterMonth || null,
		sectors: sectors || [],
		dateFilter: filterMonth
	};
	try {
		const res = await axios.post(`${url}${endpoints.dataEstateSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDataProcessingTrends = async (kpiName: string, filterMonth?: string, sectors?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		startDate: filterMonth || null,
		sectors: sectors || [],
		dateFilter: filterMonth
	};
	try {
		const res = await axios.post(`${url}${endpoints.dataProcessingTrend}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchServiceRequestSummary = async (filter: string, filterMonth?: string, sectors?: any, formattedDate?: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		startDate: filterMonth || null,
		sectors: sectors || [],
		dateFilter: filterMonth,
		formattedDate
	};
	try {
		const res = await axios.post(`${url}${endpoints.serviceRequestSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchTrends = async (kpiName: string, vendor: string, sector: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		vendor: vendor,
		sector: sector,
		month: month,
		dateFilter: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.biApplicationTrend}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDECostSummary = async (filter: string, dimension: any, sector: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		dimension: dimension,
		sector: sector,
		month: month,
		dateFilter: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.deCostSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const fetchDECostTrends = async (filter: string, dimension: any, sector: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		dimension: dimension,
		sector: sector,
		month: month,
		dateFilter: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.deCostSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDatabricksClustersCount = async (startDate?: string, endDate?: string, isCount?: boolean) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		startDate,
		endDate,
		isCount
	};
	try {
		const res = await axios.post(`${url}${endpoints.databricksClustersCount}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const dataEstateOverallSummary = async () => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: moment().format('MM') + '/' + moment().format('YY')
	};
	try {
		const res = await axios.post(`${url}${endpoints.dataEstateOverallSummary}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const dataEstateExecutiveSummary = async () => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: moment().format('MM') + '/' + moment().format('YY')
	};
	try {
		const res = await axios.post(`${url}${endpoints.dataEstateExecutiveSummary}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchAzureMonthlyCost = async () => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: moment().format('MM') + '/' + moment().format('YY')
	};
	try {
		const res = await axios.post(`${url}${endpoints.azureMonthlyCost}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDEStorageSummary = async (sector: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		groupByFilters: 'KPI_NAME,DIMENSION,MONTH',
		sectors: sector,
		startDate: month || null,
		month: month,
		dateFilter: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.deStorageSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const fetchDEStorageTrends = async (kpiName: string, vendor: any, sector: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		vendor: vendor,
		sector: sector,
		month: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.deStorageTrends}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDEStorageTable = async (kpiName: string, dimension: any, sector?: any, month?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		dimension: dimension,
		sector: sector || [],
		month: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.summaryTable}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchQuerySummary = async (filter: any, sectors: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		month: month,
		dateFilter: month,
		sectors: sectors || []
	};
	try {
		const res = await axios.post(`${url}${endpoints.queriesSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const fetchDEQueryTrends = async (kpiName: string, vendor: any, sector: any, month: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		vendor: vendor,
		sector: sector,
		month: month,
		dateFilter: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.queriesTrends}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDECostTabel = async (kpiName: string, dimension: any, sector?: any, month?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		dimension: dimension,
		sector: sector || [],
		month: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.summaryTable}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDataEstateLastSyncData = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.lastSyncDate}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const FetchFinOpsOverviewCloudCost = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsOverviewSummary}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const FetchFinOpsOverviewCloudGrowth = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsOverviewSummary}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchCloudCostTrends = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsOverviewSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchCostBreakdown = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsOverviewSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const QuerySummaryTable = async (kpiName: string, dimension: string, sector: any, month: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		dimension: dimension,
		sector: sector,
		month: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.summaryTable}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const dataProcessingTable = async (kpiName: string, dimension?: string, sector?: any, month?: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		dimension: dimension,
		sector: sector,
		month: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.summaryTable}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const programSummaryDetail = async (filter: any, page: string, size: string, sortingInfo: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page,
		size,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsProgramSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchProgramCloudCost = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsProgramSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchProgramDATeams = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsProgramSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const FetchFinOpsCostKpi = async (filter?: any) => {
	console.log(filter)
	const headers = getAuthorazieHeader();
	const requestBody = {
		type: 'cost',
		filterData: filter ? filter : {}
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsCostKpi}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};
export const FetchFinOpsDetails = async (filter: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsDetailSummary}`, requestBody, { headers });
		console.log(res.data);
		return res.data;
	} catch (err) {
		return err;
	}
};
export const FetchFinOpsDetailsSummary = async (page: any, size: any, filterInfo: any, groupingInfo: any, sortingInfo?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		pageData: {
			page,
			size
		},
		filterData: groupingInfo ? groupingInfo : {},
		sortingInfo: sortingInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsCostKpi}`, requestBody, { headers });
		console.log(res, 'res details summary');
		return res.data;
	} catch (err) {
		return err;
	}
};
export const FetchFinOpsResourceSummary = async (page: any, size: any, project: any, resourceType: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: '',
		offset: '',
		project: project,
		resourceType: resourceType
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsResourceDetail}`, requestBody, { headers });
		console.log(res, 'res resource summary');
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getDetailSummarySector = async (filter: any, searchQuery: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: [filter],
		searchQuery: searchQuery
	};
	try {
		const res = await axios.post(`${url}${endpoints.filterDetail}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSlaReportData = async (
	
	fromDateTime?: string,
	toDateTime?: string,
	
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		
		startDate: fromDateTime,
		endDate: toDateTime,
		
	};
	try {
		const res = await axios.post(`${url}${endpoints.incidentCriticalSlaReport}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getIncidentDetails = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	incidentType?: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		startDate: fromDateTime,
		endDate: toDateTime,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo,
		incidentType: incidentType
	};
	try {
		const res = await axios.post(`${url}${endpoints.incidentCriticalJobReport}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const incidentSearchForJobExecutions = async (
	searchDetails: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: 1,
		size: 50,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime,
		sortBy: 'scheduledJob',
		orderBy: 'asc',
		filter: filterInfo,
		jobSearch: searchDetails
	};
	try {
		const res = await axios.post(`${url}${endpoints.incidentCriticalJobReport}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
// export const fetchChartData = async (startDate: string, endDate: string) => {
// 	const headers = getAuthorazieHeader();
// 	const requestBody = {
// 		startDate,
// 		endDate
// 	};
// 	try {
// 		const res = await axios.post(`${url}${endpoints.chartDataSla}`, requestBody, { headers });
// 		return res.data;
// 	} catch (err) {
// 		return err;
// 	}
// };

export const fetchChartData = async (params: any) => {
	const headers = getAuthorazieHeader();
	let {incidentId, startDate, endDate} = params
	const requestBody = {
		startDate:  moment(startDate).format('YYYY-MM-DD HH:mm:ss.SSSSSSS'),
		endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss.SSSSSSS'),
		incidentId
	};
	try {
		const res = await axios.post(`${url}${endpoints.chartDataSla}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getOverviewSummaryMetrics = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.overviewSummaryMetrics}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const getServiceReq = async (
	kpiName: string,
	dimension: string,
	sector: string,
	month: string,
	page: string,
	size: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		dimension: dimension,
		sector: sector,
		month: month
	};
	let queryString = `page=${page}&size=${size}`;
	try {
		const res = await axios.post(`${url}${endpoints.summaryTable}?${queryString}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const biApplicationSummary = async (kpiName: string, dimension: string, sector?: any, month?: string) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		kpiName: kpiName,
		dimension: dimension,
		sector: sector || [],
		month: month
	};
	try {
		const res = await axios.post(`${url}${endpoints.summaryTable}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDBDatabricks = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	sector?: any
) => {
	const headers = getAuthorazieHeader();
	const date =
		localStorage.getItem('processedDate') !== undefined ? localStorage.getItem('processedDate') : fromDateTime;

	const requestBody = {
		page,
		size,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime,
		sector: sector,
		sortBy: sortingInfo.sortBy,
		sortDir: sortingInfo.sortDir,
		filter: filterInfo
	};
	console.log(requestBody, 'requestBody');
	try {
		const res = await axios.post(`${url}${endpoints.databricksSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getProgramSectorList = async (
	
) => {
	const headers = getAuthorazieHeader();
	

	const requestBody = {

	};
	console.log(requestBody, 'requestBody');
	try {
		const res = await axios.post(`${url}${endpoints.dataPipelineSectorList}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getProgramList = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.dataPipelineProgramList}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getProgramById = async (id: any) => {
	let body = {
		programID: id
	}
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.dataPipelineProgramById}`,body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const createDataPipelineProgram = async (body: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.createProgram}`, body,  { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const editDataPipelineProgram = async (data: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		programID: data.id,
		sector: data.sector,
        programName: data.programName
	}
	try {
		const res = await axios.post(`${url}${endpoints.editProgram}`, body,  { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const deleteDataPipelineProgram = async (id: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		programID: id
	}
	try {
		const res = await axios.post(`${url}${endpoints.deleteProgram}`, body,  { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const deleteDataPipelineProject = async (id: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		projectID: id
	}
	try {
		const res = await axios.post(`${url}${endpoints.deleteProject}`, body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const deleteDataPipelineSchedule = async (data: any) => {
	const headers = getAuthorazieHeader();
	console.log(data)
	try {
		const res = await axios.post(`${url}${endpoints.bulkScheduleDelete}`, data, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const updateReorderSchedule = async (data: any) => {
	const headers = getAuthorazieHeader();
	const reqBody = { data }
	try {
		const res = await axios.post(`${url}${endpoints.updateReorderedSchedules}`, reqBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const editDataPipelineProject = async (data: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		projectID: data.id,
		projectOwner: data.projectOwner,
        projectName: data.projectName,
        projectDescription: data.projectDescription
	}
	try {
		const res = await axios.post(`${url}${endpoints.editProject}`, body,  { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getProjectListByProgram = async (id: any, page: any, pageSize: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		programID: id,
		page,
		pageSize
	}
	try {
		const res = await axios.post(`${url}${endpoints.projectListByProgram}`, body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchDEIncidentDetails = async (sector: string,page: string, size: string, month:any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		sector: sector,
		dateFilter: month
	};
	let queryString = `page=${page}&size=${size}`;
	try {
		const res = await axios.post(`${url}${endpoints.majorIncidentDetail}?${queryString}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getTeradataJobNameList = async (
	schedulerType: string,
	jobName?: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		jobName,
		schedulerType
	};
	try {
		const res = await axios.post(`${url}${endpoints.teradataJobNameList}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSearchScheduleJobNameList = async (
	reqBody: any,
	pageNumber: string,
	pageSize: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = reqBody;
	requestBody['page'] = pageNumber;
	requestBody['size'] = pageSize;
	try {
		const res = await axios.post(`${url}${endpoints.searchScheduleList}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const linkAddScheduleToProject = async (reqBody: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = reqBody;
	try {
		const res = await axios.post(`${url}${endpoints.addScheduleToProject}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getMappedJobScheduleList = async (
	projectId: any,
	pageNumber: string,
	pageSize: string,
	startDate: string,
	endDate: string, 
	filterData: any,
	is24Hrs:boolean,
	sortData: any ={},

) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		projectId: projectId,
		page: pageNumber,
		size: pageSize,
		startDate: startDate,
		endDate: endDate,
		filterData,
		IS24Hrs:is24Hrs,
		...sortData
	};
	console.log("REQQQ", requestBody)
	try {
		const res = await axios.post(`${url}${endpoints.getScheduleExecutionsList}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getScheduleList = async (
	projectId: any,
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		projectId: projectId
	};
	try {
		const res = await axios.post(`${url}${endpoints.getIndexSchedulesList}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getSlaCountDetail = async (
	projectId: any,
	startDate: string,
	endDate: string,
	is24hrs: boolean
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		projectId: projectId,
		start: startDate,
		end: endDate,
		hours: is24hrs ? 24 : 0
	};
	try {
		const res = await axios.post(`${url}${endpoints.slaCountDetails}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getProjectOwnerList = async (name: any) => {
	const headers = getAuthorazieHeader();
	let body = {}
	if (name) {
		body  = {
			searchName: name
		}
	}
	try {
		const res = await axios.post(`${url}${endpoints.getOwnerList}`, body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getClarityProjectList = async (name: any) => {
	const headers = getAuthorazieHeader();
	let body = {}
	if (name) {
		body  = {
			searchProject: name
		}
	}
	try {
		const res = await axios.post(`${url}${endpoints.getClarityProject}`, body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const createDataPipelineProject = async (body: any) => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.createProject}`, body,  { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getJobPerformanceChartData = async (params: any) => {
	
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.dataPipelineProjectSlaMetrics}`,params, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const powerBICapacityMetricsData = async (sector?: any) => {
	let queryString = `sector=${JSON.stringify(sector)}`;
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.powerBICapacityMetrics}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const powerBICapacityDetailData = async (pageNumber:string, size:string,sector: any,sortingInfo: any,filterInfo: any) => {
	console.log(filterInfo,"filterInfo service")
	let queryString = {
		sector:sector,
		sortBy:sortingInfo.sortBy,
		orderBy:sortingInfo.sortDir,
		filterBy:filterInfo,
		page:pageNumber,
		pageSize:size
	};
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.powerBICapacityDetails}`,queryString, { headers });
		if(parseInt(res.data.data.page.currentPage) > res.data.data.page.totalPages){
			let queryString = `sector=${JSON.stringify(sector)}&sortBy=${sortingInfo.sortBy}&orderBy=${sortingInfo.sortDir}&page=1&pageSize=${size}`;
			const res = await axios.post(`${url}${endpoints.powerBICapacityDetails}?${queryString}`, {}, { headers });
			return res.data;
		}
		else{
			return res.data;
		}
	} catch (err) {
		return err;
	}
};
export const powerBICapacitySearch = async (
	searchQuery: any,
	searchField: any,
	filterInfo: any,
	sector: any,
	drillDownStatusFilter: any
) => {
	const headers = getAuthorazieHeader();
	// let queryString = `page=${1}&size=${100}&fromDateTime=${moment(fromDateTime).format('YYYY-MM-DD')}`;
	// if (Object.keys(filterInfo).length) queryString = `${queryString}&filterBy=${JSON.stringify(filterInfo)}`;
	// if (searchQuery) queryString = `${queryString}&searchField=${searchField}&searchQuery=${searchQuery}`;
	// if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) queryString = `${queryString}&filter=${JSON.stringify(drillDownStatusFilter)}`;

	const requestBody = {
		page: '1',
		size: '50',
		sector: sector,
		filterBy: filterInfo,
		searchField: searchField,
		searchQuery: searchQuery,
		filter: {}
	};

	if (drillDownStatusFilter && Object.keys(drillDownStatusFilter).length) {
		requestBody['filter'] = drillDownStatusFilter;
	}

	try {
		// const res = await axios.get(`${url}${endpoints.databricksJobs}?${queryString}`, { headers });
		const res = await axios.post(`${url}${endpoints.powerBICapacityDetails}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};
export const fetchSectors = async (sector?: any) => {
	let queryString = `sector=${JSON.stringify(sector)}`;
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.powerBIgetSectors}?${queryString}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const powerBICapacityStatus = async (
    pageNumber:string, 
    size:string,
    sector: any,
    status: any,
    sortingInfo: any 
) => {

    let queryString = `sector=${JSON.stringify(sector)}&status=${encodeURIComponent(status)}&sortBy=${sortingInfo.sortBy}&orderBy=${sortingInfo.sortDir}&page=${pageNumber}&pageSize=${size}`;
    const headers = getAuthorazieHeader();
    
    try {
        const res = await axios.post(`${url}${endpoints.powerBICapacityStatus}?${queryString}`, {}, { headers });
        
        if (res.data.data.page.currentPage > res.data.data.page.totalPages) {
            let queryString = `sector=${JSON.stringify(sector)}&status=${encodeURIComponent(status)}&sortBy=${sortingInfo.sortBy}&orderBy=${sortingInfo.sortDir}&page=1&pageSize=${size}`;
            const res = await axios.post(`${url}${endpoints.powerBICapacityStatus}?${queryString}`, {}, { headers });
            return res.data;
        } else{
            return res.data;
        }
    } catch (err) {
        console.error("Error fetching capacity status:", err);
        throw err; // Rethrow error for handling in the calling function
    }
};





export const getProjectById = async (data: any) => {
	
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.post(`${url}${endpoints.dataPipelineProjectById}`,data, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getCustomDashboard = async () => {
	const headers = getAuthorazieHeader();
	try {
		const res = await axios.get(`${url}${endpoints.customDashboard}`, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const fetchFinOpsExecutiveSummary = async (filter: string, filterBy?: any) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		filter: filter,
		filterBy: filterBy
	};
	try {
		const res = await axios.post(`${url}${endpoints.finOpsExecutiveSummary}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDpProjectKpi = async (id: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		programId: Number(id)
	}
	try {
		const res = await axios.post(`${url}${endpoints.dpProjectKpiCount}`, body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getNormalCompletionTime = async (id: any) => {
	const headers = getAuthorazieHeader();
	let body = {
		jobId: Number(id)
	}
	try {
		const res = await axios.post(`${url}${endpoints.getNormalCompletion}`, body, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getDatabricksClusterImpactDetails = async (
	page: string,
	size: string,
	sortingInfo: any,
	filterInfo: any,
	fromDateTime?: string,
	toDateTime?: string,
	incidentId?: string
) => {
	const headers = getAuthorazieHeader();
	const requestBody = {
		page: page,
		size: size,
		fromDateTime: fromDateTime,
		toDateTime: toDateTime,
		incidentId: incidentId,
		sortBy: sortingInfo.sortBy,
		orderBy: sortingInfo.sortDir,
		filter: filterInfo
	};
	try {
		const res = await axios.post(`${url}${endpoints.getDatabricksClusterImpactDetails}`, requestBody, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getAddedSchedules = async (data: any) => {
	const headers = getAuthorazieHeader();

	try {
		const res = await axios.post(`${url}${endpoints.addedSchedulesList}`, data, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};

export const getAddedScheduleSearchFilterColumn = async (data: any) => {
	const headers = getAuthorazieHeader();

	try {
		const res = await axios.post(`${url}${endpoints.getAddedScheduleFilterColumn}`, data, { headers });
		return res.data;
	} catch (err) {
		return err;
	}
};



import { useEffect, useState, useRef, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { useProSidebar } from 'react-pro-sidebar';
import { deleteCookie, getDecodedToken, getAlerts } from './service/service';
import moment from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as FullScreenIcon } from './assets/fullscreen.svg';
import { ReactComponent as ExitFullScreenIcon } from './assets/exit_fullscreen.svg';
import { OverflowTooltip } from './components/overflow_tooltip';
import { HeaderContext } from './Context/HeaderContext';
import { Badge, Box, Tooltip, Zoom } from '@mui/material';
import { ReactComponent as NotificationBellIcon } from './assets/notification.svg';
import RulesEngine from './components/RulesEngine/RulesEngine';
import {useRedirectLogin} from 'hooks/useRedirectHooks';
import AppRoutes from 'routes/routes';

import Sidebar from './components/landingScreen/sidebar';
import Header from './components/landingScreen/header';
import Login from './components/loginScreen/loginScreen';
import FormLogin from './components/loginScreen/formLoginScreen';	
import SideDrawer from 'components/SideDrawer';
import { setAuthenticated, setAuthToken, setAuthType, setCapabilities, setRole, usePepwiseAuthController } from 'Context/contexts/AuthContext';
// Lazy load components
// const Sidebar = lazy(() => import('./components/landingScreen/sidebar'));
// const Header = lazy(() => import('./components/landingScreen/header'));
// const Login = lazy(() => import('./components/loginScreen/loginScreen'));
// const FormLogin = lazy(() => import('./components/loginScreen/formLoginScreen'));

const synced = moment();

function App() {
	const dataFetchedRef = useRef(false);
	const [fullscreen, setFullScreen] = useState(false);
	const [originalRuleEngineData, setOriginalRuleEngineData] = useState<any>([]);
	const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
	const [controller, dispatch] = usePepwiseAuthController();
	const {redirectToLogin, redirectAfterLogin} = useRedirectLogin();

	const { isAuthenticated, accessToken } = controller;

	document.addEventListener('fullscreenchange', exitHandler);
	document.addEventListener('webkitfullscreenchange', exitHandler);
	document.addEventListener('mozfullscreenchange', exitHandler);
	document.addEventListener('MSFullscreenChange', exitHandler);

	function exitHandler() {
		if (!document.fullscreenElement) {
			setFullScreen(false);
		}
	}

	moment.tz.setDefault('America/New_York');
	moment.defaultFormat = 'MM-DD-YYYY HH:mm:ss';
	// Idle timeout -> logout
	// idleTime: 180 is equal to 3 hrs to logout and redirect to '/' home screen for sso login flow
	const idleout = () => {
		deleteCookie('id_token');
	};
	// useIdle({ onIdle: idleout, idleTime: 180 });
	const handleFullScreen = () => {
		if (fullscreen) document.exitFullscreen();
		else document.body.requestFullscreen();
		setFullScreen(!fullscreen);
	};

	// [Context Api]
	const [isNull, setIsNull] = useState(true);
	const [lastSync, setLastSync] = useState(synced.fromNow());
	const value = { isNull, setIsNull, lastSync, setLastSync };

	// check if session is still valid
	useEffect(() => {
		let isRedirectToLogin = false;
		if (location.pathname === '/userLogin') {
			localStorage.setItem('sso-login', 'false');
			setAuthType(dispatch, 'local');
		}
		if (location.pathname === '/') {
			localStorage.setItem('sso-login', 'true');
			setAuthType(dispatch, 'sso');
		}
		let authenticated = false;
		if (isAuthenticated) {
			if (accessToken?.exp) {
				const currentTime = Math.floor(Date.now() / 1000);
				//Check access token is expired
				if (accessToken.exp >= currentTime) {
					authenticated = true;
				}
				
				setAuthenticated(dispatch, false);
				setAuthToken(dispatch, null);
			}
		}
		if (authenticated === false) {
			const auth = getDecodedToken();
			if (auth) {
				const currentTime = Math.floor(Date.now() / 1000);
				if (auth.exp >= currentTime) {
					setAuthType(dispatch, 'local');
					setAuthToken(dispatch, auth);
					setAuthToken(dispatch, auth)
					setRole(dispatch, auth.role?.name)
					setCapabilities(dispatch, auth.role?.capabilities)
					setAuthenticated(dispatch, true);
				} else {
					isRedirectToLogin = true;
				}
			} else {
				isRedirectToLogin = true;
			}
			if (isRedirectToLogin && !(['/', '/userLogin'].includes(location.pathname))) {
				redirectToLogin()
			}
		}
		// const ssoLoginLocalStore = localStorage.getItem('sso-login');
		// const ssoLogin = ssoLoginLocalStore ? ssoLoginLocalStore === 'true' : false;
		// const sessionCookie = 'id_token';
		// if (location.pathname!=='/userLogin' && ssoLogin) checkSession();
	}, []);

	// const checkSession = async (): Promise<void> => {
	// 	axios
	// 		.get(`${url}/user/me`)
	// 		.then((response) => {
	// 			if (response.status === 200) {
	// 				const { success } = response.data;
	// 				if (success !== true) {
	// 					console.log("redirecting to sso login from App.tsx")
	// 					window.location.href = '/sso/login';
	// 				}
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error('Login error:', error);
	// 		});
	// };

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// listenToEventStream(); // Start listening to event stream
		if (isAuthenticated) {
			fetchAlerts();
			const apiCallInterval = 10 * 60 * 1000; // 10 minutes in milliseconds
			setInterval(fetchAlerts, apiCallInterval);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated) {
			redirectAfterLogin();
		}
	}, [isAuthenticated])
	
	const [totalObservationCount, setTotalObservationCount] = useState(-1);

	const calculateTotalObservation = (data: any) => {
		const totalCount = data.observations.reduce((count: any, item: any) => count + item.totalObservation, 0);
		setTotalObservationCount(totalCount);
	};

	async function fetchAlerts() {
		const res = await getAlerts();
		if (res.success) {
			const newData = res.data;
			setOriginalRuleEngineData((prevData: any) => [...prevData, newData]);
			calculateTotalObservation(newData);
		}
	}

	// const listenToEventStream = () => {
	// 	const eventSource = new EventSource(`${url}${endpoints.notificationAlerts}`);

	// 	eventSource.onmessage = (event) => {
	// 		const newData = JSON.parse(event.data);
	// 		setOriginalRuleEngineData((prevData: any) => [...prevData, newData]);
	// 		calculateTotalObservation(newData);
	// 	};

	// 	eventSource.onerror = (error) => {
	// 		console.error('EventSource failed:', error);
	// 		eventSource.close();
	// 		listenToEventStream();
	// 	};
	// };

	const togglePanel = () => {
		setPanelOpen(!isPanelOpen);
	};

	const alertIconClass = `${
		location.pathname === '/LandingPage' ? 'landing-common-notification' : 'common-notification'
	} badge_box`;

	const showFullScreen = () => {
		return (
			location.pathname !== '/LandingPage' && (
				<OverflowTooltip customTooltip={fullscreen ? 'Exit Full Screen' : 'Full Screen'}>
					<IconButton
						style={{ paddingLeft: '1px', paddingTop: '14px' }}
						onClick={() => {
							handleFullScreen();
						}}
						className={fullscreen ? 'exitFullScrrenBtn' : 'fullScrrenBtn'}
					>
						{fullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
					</IconButton>
				</OverflowTooltip>
			)
		);
	};

	const showLandingPageNotification = () => {
		return (
			location.pathname !== '/evidently-mlobs-poc' &&
			location.pathname !== '/' &&
			location.pathname !== '/userLogin' &&
			location.pathname === '/LandingPage' && (
				<div className={location.pathname === '/LandingPage' ? 'bell-wrapper' : ''}>
					<Box
						component="span"
						sx={{ p: 2 }}
						onClick={() => {
							totalObservationCount > 0 && togglePanel();
						}}
						className={alertIconClass}
					>
						<Tooltip arrow title="Rule Observations" TransitionComponent={Zoom}>
							<Badge
								badgeContent={totalObservationCount >= 0 ? totalObservationCount : '...'}
								max={9999}
								showZero
								className={Number(totalObservationCount) > 0 ? 'alert_badge' : 'zero_badge'}
							>
								<NotificationBellIcon className="badge_icon" />
								{/* <span className="icon-notification icon-style"></span> */}
							</Badge>
						</Tooltip>
					</Box>
				</div>
			)
		);
	};

	// useEffect(() => {}, [isAuthenticated]);

	const showHeaderAndNav = () => {
		return (
			isAuthenticated &&
			location.pathname !== '/' &&
			location.pathname !== '/userLogin' &&
			location.pathname !== '/LandingPage' && (
				<>
					<Suspense fallback={<div>Loading...</div>}>
						<Sidebar />
					</Suspense>
					<Suspense fallback={<div>Loading...</div>}>
						<Header notificationData={originalRuleEngineData} />
					</Suspense>
				</>
			)
		);
	};	

	return (
		<HeaderContext.Provider value={value}>
				{showFullScreen()}

				{isPanelOpen && <RulesEngine close={togglePanel} ruledata={originalRuleEngineData} />}


				{showHeaderAndNav()}
				
				<SideDrawer/>

				{!isAuthenticated && (
					<Routes>
						<Route path="/" element={<Login />} />

						<Route path="/userLogin" element={<FormLogin />} />
					</Routes>
				)}

				{isAuthenticated && (
					<aside
						className={isAuthenticated ? 'main-content' : ''}
					>
						{showLandingPageNotification()}
						<div className="parent-container">
							<Suspense fallback={<div>Loading...</div>}>
								<AppRoutes/>
							</Suspense>
						</div>
					</aside>
				)}
				{/* <Footer /> */}
		</HeaderContext.Provider>
	);
}

export default App;

import { Box, BoxProps, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';

type CustomDropdownProps = {
	dropDownPlaceholder?: string;
	options: {
		label: string;
		value: string;
	}[];
	value: string;
	handleChange: (event: SelectChangeEvent<string>) => void;
	boxProps?: BoxProps;
};

const CustomDropdown = ({ value, options, handleChange, dropDownPlaceholder, boxProps }: CustomDropdownProps) => {
	const { sx, ...props } = boxProps || {};

	return (
		<Box
			sx={{
				backgroundColor: 'blue.dark',
				borderRadius: '4px',
				fontSize: '15px',
				zIndex: 999,
				minWidth: '90px',
				textAlign: 'left',
				height: 'fit-content',
				...sx
			}}
			{...props}
		>
			<FormControl fullWidth>
				<Select value={value} onChange={handleChange} placeholder={dropDownPlaceholder}>
					{options.map(({ label, value }) => (
						<MenuItem key={`${value}-${label}`} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default CustomDropdown;

import { Box, Typography, Tooltip, Stack, debounce } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import NoPrgramFound from '../../assets/no-program-found.svg';
import LoaderComponent from 'components/Loader';
import { defaultOptions, getBarChartOptions } from './chartWidgetUtility';
import { useEffect, useRef, useState } from 'react';
import { BarChartWidgetProps } from './type';

const BarChartWidget = ({
	isLoading = false,
	isCost = false,
	isOverlayWidget = false,
	isInProgress = false,
	title = '',
	data,
	seriesData,
	minTitleHeight = '50.5px',
	handleEvent,
  legendLabel = '',
}: Readonly<BarChartWidgetProps>) => {
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [gridLeft, setGridLeft] = useState('5%');
	const [minChartHeight, setMinChartHeight] = useState('350px');
	const chartContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// re-render chart to fix alignment when resizing the browser
		const handleResize = debounce(() => {
			if (chartContainerRef.current) {
				const containerWidth = chartContainerRef.current.offsetWidth;
				const calculatedLeft =
					containerWidth < 420
						? '5%'
						: containerWidth < 920
						? '9%'
						: containerWidth < 1260
						? '7%'
						: '5%';
				const calculatedChartHeight =
					containerWidth < 420
						? '680px'
						: containerWidth < 500
						? '600px'
						: containerWidth < 685
						? '550px'
						: containerWidth < 920
						? '450px'
						: '350px';
				setGridLeft(calculatedLeft);
				setMinChartHeight(calculatedChartHeight);
				setWindowSize(window.innerWidth);
			}
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const result = getBarChartOptions(data ?? [], seriesData, isCost, legendLabel);

	const adjustedOption = !data?.length
		? defaultOptions
		: {
				...result,
				grid: {
					...result.grid,
					left: gridLeft
				},
		  };

	return (
		<Box display="flex" flexGrow={1} height="100%" position="relative">
			<Stack
				minHeight="100px"
				p={2}
				bgcolor="primary.main"
				fontFamily="Montserrat"
				borderRadius="5px"
				flexGrow={1}
				{...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
			>
				<Stack direction="row" gap={2} justifyContent="space-between" alignItems="start" minHeight={minTitleHeight}>
					<Stack direction="row" gap={1.5} alignItems="start">
						<Typography variant={isOverlayWidget ? 'overlayWidgetTitle' : 'widgetTitle'}>{title}</Typography>
					</Stack>
				</Stack>
				<Stack minHeight={minChartHeight} flexGrow={1} justifyContent="center" key={windowSize} ref={chartContainerRef}>
					{!isLoading ? (
						<ReactECharts
							option={adjustedOption}
							style={{ width: '100%', height: '100%' }}
							opts={{ renderer: 'canvas', width: 'auto' }}
							notMerge={true}
							lazyUpdate={true}
						/>
					) : (
						<LoaderComponent />
					)}
				</Stack>
				{isInProgress && (
					<Box position="absolute" right={15} bottom={10}>
						<Tooltip title="In Progress/Validation">
							<img
								src={NoPrgramFound}
								style={{
									height: '20px',
									width: '20px'
								}}
							/>
						</Tooltip>
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default BarChartWidget;

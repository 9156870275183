import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl,
	Tooltip,
	Box
} from '@mui/material';
import { dashboardUrl, isEnvTypePROD } from '../../service/config';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector, fetchDECostTabel, fetchDEStorageTrends } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../DataEstate/data-estate.scss';
import { fetchDECostSummary, fetchDECostTrends } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import '../DataEstate/data-estate-dashboard.scss';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import ArrowUpBlackIcon  from '../../assets/up-arrow-black.svg';
import {getPercentageOfTwoNumbers, formatNumberWithCommas} from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import {  ReactComponent as CalendarGreyIcon} from '../../assets/ion_calendar-outline.svg'
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { Column } from 'react-data-grid';
import NoPrgramFound from '../../assets/no-program-found.svg';
import ToolTipIcon from "../../assets/info-icon.svg";
import PageHeader from 'components/Header/Page';
import ConversionUnitDropdown from 'components/Dropdown/ConversionUnit';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import CostIcon from '../../assets/dataestate-cost.svg';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';

const CostSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [DatabricksCost, SetDatabricksCost] = useState('_')
	const [TeraDataCost, SetTeraDataCost] = useState('_')
	const [AzureCost, SetAzureCost] = useState('_')
	const [ReservedInsSaving, SetReservedInsSaving] = useState('_')
	const [DatabricksQueryCost, SetDatabricksQueryCost] = useState('_')
	const [TeradataQueryCost, SetTeradataQueryCost] = useState('_')
	const [PrestoQueryCost, SetPrestoQueryCost] = useState('_')

	const [grafanaDatabricksLoading, setGrafanaDatabricksLoading] = useState(true);
	const [grafanaDatabricksOptions, setGrafanaDatabricksOptions] = useState<any>()
	const [grafanaTeradataLoading, setGrafanaTeradataLoading] = useState(true);
    const [grafanaTeradataOptions, setGrafanaTeradataOptions] = useState<any>()
	const [grafanaAzureLoading, setGrafanaAzureLoading] = useState(true);
    const [grafanaAzureOptions, setGrafanaAzureOptions] = useState<any>()
	const [lastUpdatedDateDatabricks, setLastUpdatedDateDatabricks] = useState('')
	const [lastUpdatedDateTeradata, setLastUpdatedDateTeradata] = useState('')
	const [lastUpdatedDateAzure, setLastUpdatedDateAzure] = useState('')	
	
	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0)
	const [azureBubbleValue, SetAzureBubbleValue] = useState(0)
	const [databricksBubbleValue, SetDatabricksBubbleValue] = useState(0)
	const [customMonth, setCustomMonth] = useState('');
	
	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [controller] = useCacheDataController();
	const { azureCostSummaryLastSyncDate } = controller;
	const [isLoading, setIsLoading] = useState(false);

		// sector filter
		const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
		const [filterMenu, setFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);

		const [dateFilterMenu, setDateFilterMenu] = useState<{
			mouseX: number;
			mouseY: number;
		} | null>(null);
		
		const classes = useStyles();
		const [itemsChecked, setItemsChecked] = useState(false);
		const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
		const [checkedList, setCheckedList] = useState<any[]>([]);
		const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
		const [sector, setCurrSector] = useState<any[]>([]);
		// const [filterMonth, setFilterMonth] = useState("0M");
		const [filterMonth, setFilterMonth] = useState("0Y");
		// const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
		const [filterDefaultMonth, setFilterDefaultMonth] = useState("0Y");
		
		const dataFetchedRef = useRef(false);
		const [customYear, setCustomYear] = useState(moment().format("YYYY"));
        const [isDateOpen, setIsDateOpen] = useState(false);
		const isCustomMonth = useRef(false);
			
	// TODO START
		// table
		const [tableName, setTableName] = useState('');
		const [typeName, setTypeName] = useState('');

		interface DBCLUSTERS {
			workspace: string;
			clusterType: string;
			clusterName: string;
			totalDBU: string;
			totalDBUCost: string;
			totalComputeCost: string;
		}
		interface TERADATA {
			workload: string;
			totalTCoreHours: string;
			tCoreDailyAverage: string;
			totalCost: string;
		}
		interface AZURE {
			subscription: string;
			resourceGroup: string;
			resource: string;
			location: string;
			totalCost: string;
		}
		interface YTD {
			programName: string;
			total_cost: string;
		}
		interface AVERAGECOST {
			workload: string;
			tCoreUsagePerQuery: string;
			totalQueries: string;
			totalCostPerQuery: string;
		}
		interface Row{};
		function getColumns(): Array<Column<Row>> {
			if(typeName === 'DBCLUSTERS') {
				return [
					{
						key: 'workspace',
						name: 'Workspace',
						minWidth: 150
					},
					{
						key: 'clusterType',
						name: 'Cluster type',
						minWidth: 170,
					},
					{
						key: 'clusterName',
						name: 'Cluster name',
						minWidth: 170,
					},
					{
						key: 'totalDBU',
						name: 'Total DBU',
						minWidth: 170,
					},
					{
						key: 'totalDBUCost',
						name: 'Total DBU Cost',
						minWidth: 170,
					},
					{
						key: 'totalComputeCost',
						name: 'Total compute cost',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'TERADATA') {
				return [
					{
						key: 'workload',
						name: 'Workload',
						minWidth: 150
					},
					{
						key: 'totalTCoreHours',
						name: 'Total T-Core hours',
						minWidth: 170,
					},
					{
						key: 'tCoreDailyAverage',
						name: 'T-Core daily average',
						minWidth: 170,
					},
					{
						key: 'totalCost',
						name: 'Total cost',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'AZURE') {
				return [
					{
						key: 'subscription',
						name: 'Subscription',
						minWidth: 150
					},
					{
						key: 'resourceGroup',
						name: 'Resource group',
						minWidth: 170,
					},
					{
						key: 'resource',
						name: 'Resource',
						minWidth: 170,
					},
					{
						key: 'location',
						name: 'Location',
						minWidth: 170,
					},
					{
						key: 'totalCost',
						name: 'Total cost',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'YTD') {
				return [
					{
						key: 'programName',
						name: 'Year',
						minWidth: 150
					},
					{
						key: 'total_cost',
						name: 'YTD Reserved instance savings',
						minWidth: 170,
					}
				];
			}
			else if(typeName === 'AVERAGECOST') {
				return [
					{
						key: 'workload',
						name: 'Workload',
						minWidth: 150
					},
					{
						key: 'tCoreUsagePerQuery',
						name: 'T-Core usage per query',
						minWidth: 170,
					},
					{
						key: 'totalQueries',
						name: 'Total queries',
						minWidth: 170,
					},
					{
						key: 'totalCostPerQuery',
						name: 'Total cost per query',
						minWidth: 170,
					}
				];
			}
			else {
				return [];
			}
		}
		const DatabricksClustersColumnNames = ['Workspace', 'Cluster type', 'Cluster name', 'Total DBU', 'Total DBU Cost', 'Total compute cost'];
		const TeradataColumnNames = ['Workload', 'Total T-Core hours', 'T-Core daily average', 'Total cost'];
		const AzureColumnNames = ['Subscription', 'Resource group', 'Resource', 'Location', 'Total cost'];
		const YTDColumnNames = ['Year', 'YTD Reserved instance savings'];
		const AverageCostColumnNames = ['Workload', 'T-Core usage per query', 'Total queries', 'Total cost per query'];
		const [defaultColumnNames, setDynamicValue] = useState(['']);
		const [tableData, setTableData] = useState<Row[]>([]);
		const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
		const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
		const [page, setPage] = useState(1);
		const [pageSize, setPageSize] = useState(100);
		const [isDetailVisible, setIsDetailVisible] = useState(false);
		const dimension = useRef('');
		const kpiName = useRef('');
		const [loading, setLoading] = useState(false);
		const tableRef = useRef<HTMLDivElement>(null);
		
		const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
			summaryListingFunc(value, pageSize, sortingInfo, kpiName, dimension);
		};

		const handlepageSizeChange = (event: any) => {
			summaryListingFunc(page, event.target.value, sortingInfo, kpiName, dimension);
		};

		async function summaryListingFunc(pageNumber: any, size: any, sortingInfo: any, kpiName: any, dimension: any ) {
			setLoading(true);
			let month = customMonth ? customMonth : filterMonth;
			const res = await fetchDECostTabel(kpiName.current, dimension.current, sector, month);
			if (res.success) {
				setLoading(false);
				setTableData(res.data.summary);
				setPaginationData(res.data.page);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
			}
		}

		const handleSorting = (sortingInfo: any) => {
			summaryListingFunc(page, pageSize, sortingInfo, kpiName, dimension);
		};

		const handleDataEstateOnclick = (value: any) => {
			setDynamicValue(['']);
			setTableData([]);
			setIsDetailVisible(true);
				setTimeout(() => {
      				if (tableRef.current) {
     					tableRef.current.scrollIntoView({ behavior: 'smooth' });
      	 			}
    	 		}, 1000);
			setTypeName(value);
			if(value === 'DBCLUSTERS') {
				setDynamicValue(DatabricksClustersColumnNames);
				type Row = keyof DBCLUSTERS;
				setTableName("Cost of Databricks Clusters");
				kpiName. current = 'platformcost',
				dimension.current = 'databricks'
			}
			else if(value === 'TERADATA') {
				setDynamicValue(TeradataColumnNames);
				type Row = keyof TERADATA;
				setTableName("Cost of Teradata");
				kpiName. current = 'platformcost',
				dimension.current = 'teradata'
			}
			else if(value === 'AZURE') {
				setDynamicValue(AzureColumnNames);
				type Row = keyof AZURE;
				setTableName("Cost of Azure");
				kpiName. current = 'platformcost',
				dimension.current = 'azure'
			}
			else if(value === 'YTD') {
				setDynamicValue(YTDColumnNames);
				type Row = keyof AZURE;
				setTableName("YTD Reserved Instance Savings");
			}
			else if(value === 'AVERAGECOST') {
				setDynamicValue(AverageCostColumnNames);
				type Row = keyof AVERAGECOST;
				setTableName("Cost of a Query on Teradata");
				kpiName. current = 'querycost',
				dimension.current = 'teradata'
			}
			summaryListingFunc(page, pageSize, sortingInfo, kpiName, dimension)	
		
		}
		const clearMetricsSummary = () => {
			setTableData([]);
			setIsDetailVisible(false);
		}

		const dateFilterMenuClick = (event: any) => {
			setDateFilterMenu(
				dateFilterMenu === null
					? {
							mouseX: parseInt(event.clientX) - 260,
							mouseY: parseInt(event.clientY) - 50
					  }
					: null
			);
		};
		const handleDateFilterMenuClose = (event: any) => {
			setDateFilterMenu(null);
		};
		
		const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if(sectorOptionsList.length === (checkedList.length + newlyChecked.length)){
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		clearMetricsSummary();
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector(checkedList);
		getCostSummaryDetails(checkedList, filterMonth);
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
        	setSectorOptionsList(filteredSectorOptions);
		}
	}

	async function getCostSummaryDetails(sector: any, month: any) {
		setIsLoading(true);
		// Trend APIs
		SetDatabricksCost('-')
		SetTeraDataCost('-')
		SetAzureCost('-')
		SetReservedInsSaving('-')
		SetDatabricksQueryCost('-')
		SetTeradataQueryCost('-')
		SetPrestoQueryCost('-')
		// setGrafanaDatabricksLoading(true);
		// DATABRICKS
		Promise.all([
			fetchDEStorageTrends('costSummary', 'databricks', sector, filterMonth),
			fetchDEStorageTrends('costSummary', 'teradata', sector, filterMonth),
			fetchDEStorageTrends('costSummary', 'azure', sector, filterMonth)
		]).then(([databrickRes, teradataRes, azureRes]) => {

			// cost
			databrickTrend(databrickRes.data);
			// Teradata
			teradataTrend(teradataRes.data);
			// Azure
			azureTrend(azureRes.data);
			
		}).catch((error: any) => {
			console.error(error);
		}).finally(() => {
			setIsLoading(false);
		})

		// Summary APIs
		fetchDECostSummary('platformcost', 'databricks', sector, month).then(res => {
			let cost = res?.data?.result[0]?.cost 
			SetDatabricksCost((cost/1000000).toFixed(2).toString());
		}).catch(error => { 
			console.error(error);
		})
		fetchDECostSummary('platformcost', 'teradata', sector, month).then(res => {
			let cost = res?.data?.result[0]?.cost 
			SetTeraDataCost((cost/1000000).toFixed(2).toString());
		}).catch(error => { 
			console.error(error);
		})
		fetchDECostSummary('platformcost', 'azure', sector, month).then(res => {
			let cost = res?.data?.result[0]?.cost 
			SetAzureCost((cost/1000000).toFixed(2).toString());
		}).catch(error => { 
			console.error(error);
		})
		// fetchDECostSummary('platformcost', 'reservedinstancesavings', sector, month).then(res => {
		// 	let cost = res?.data?.result[0]?.reservedinstancesavings 
		// 	SetReservedInsSaving(cost);
		// }).catch(error => { 
		// 	console.error(error);
		// })
		// fetchDECostSummary('querycost', 'teradata', sector, month).then(res => {
		// 	let cost = res?.data?.result[0]?.cost 
		// 	SetTeradataQueryCost(cost?.toFixed(2).toString());
		// }).catch(error => { 
		// 	console.error(error);
		// })
	}

	const azureTrend = (resData: any) => {
		setGrafanaAzureOptions(resData.data);
		setGrafanaAzureLoading(false);
		setLastUpdatedDateAzure(resData.lastUpdatedDate);
	};

	const teradataTrend = (resData: any) => {
		setGrafanaTeradataOptions(resData.data);
		setGrafanaTeradataLoading(false);
		setLastUpdatedDateTeradata(resData.lastUpdatedDate);
	};

	const databrickTrend = (resData: any) => {
		setGrafanaDatabricksOptions(resData.data);
		setGrafanaDatabricksLoading(false);
		setLastUpdatedDateDatabricks(resData.lastUpdatedDate);
	};

	const handleDateChange = (selectedDate: string) => {
		clearMetricsSummary();
		getCostSummaryDetails(sector, selectedDate);
	};

	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}
	useEffect(() => {
		
		if (hasDrillDown) {
			
			const selectedSector = localStorage.getItem('selectedSector')
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : ''
				setCheckedList(selectedSec)
				setCurrSectorFilter(selectedSec)
			}
		} else {
			localStorage.removeItem('selectedSector')
		}
		
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/costSummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getCostSummaryDetails(sector, filterMonth);
	}, []);
	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	return (
		<Box mt="45px" p={4}>
			<Stack direction="row" justifyContent="space-between">
				<PageHeader title="Cost Summary" icon={CostIcon} lastSync={azureCostSummaryLastSyncDate} />
				<Stack direction="row" gap={1} alignItems="center">
					<DateFilterDropdown
						customMonth={customMonth}
						filterMonth={filterMonth}
						setCustomMonth={setCustomMonth}
						setFilterMonth={setFilterMonth}
						handleChange={handleDateChange}
						disableFilter={true}
					/>
				</Stack>
			</Stack>
			<Grid container spacing={2}>
				<Grid container item xs={12} md={6} lg={4}>
					<WidgetCardContainer title="Azure">
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Cost"
								tooltipLabel=""
								number={
									AzureCost && AzureCost != '-' ? '$' + formatNumberWithCommas(AzureCost) : 0
								}
								isLoading={!AzureCost || AzureCost === '-'}
								units="MM"
								handleEvent={!isEnvTypePROD ? () => handleDataEstateOnclick('AZURE') : undefined}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<ChartWidget
								title="Cost Trend"
								data={grafanaAzureOptions}
								isLoading={isLoading}
								conversionUnitProp='cost'
								lastUpdatedDate={lastUpdatedDateAzure}
								restrictToMillions={true}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} md={6} lg={4}>
					<WidgetCardContainer title="Databricks">
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Cost"
								tooltipLabel=""
								number={
									DatabricksCost && DatabricksCost != '-' ? '$' + formatNumberWithCommas(DatabricksCost) : 0
								}
								isLoading={!DatabricksCost || DatabricksCost === '-'}
								units="MM"
								handleEvent={!isEnvTypePROD ? () => handleDataEstateOnclick('DBCLUSTERS') : undefined}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<ChartWidget
								title="Cost Trend"
								data={grafanaDatabricksOptions}
								isLoading={isLoading}
								conversionUnitProp='cost'
								lastUpdatedDate={lastUpdatedDateDatabricks}
								restrictToMillions={true}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} lg={4}>
					<WidgetCardContainer title="Teradata">
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Cost"
								tooltipLabel=""
								number={
									TeraDataCost && TeraDataCost != '-' ? '$' + formatNumberWithCommas(TeraDataCost) : 0
								}
								isLoading={!TeraDataCost || TeraDataCost === '-'}
								units="MM"
								handleEvent={!isEnvTypePROD ? () => handleDataEstateOnclick('TERADATA') : undefined}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<ChartWidget
								title="Cost Trend"
								data={grafanaTeradataOptions}
								isLoading={isLoading}
								conversionUnitProp='cost'
								lastUpdatedDate={lastUpdatedDateTeradata}
								restrictToMillions={true}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				{/* TODO START */}
				{/* detail part */}
				{isDetailVisible && (
					<>
						<h2 className="panel-legend--title mt-35 ">{tableName}</h2>
						<div className=" dataestate_tabledata" ref={tableRef}>
							<Grid>
								{tableData && tableData.length > 0 ? (
									<CustomizedDataGrid
										tableColumns={getColumns()}
										tableData={tableData}
										tableType="sector"
										defaultColumnNames={defaultColumnNames}
										onSorting={handleSorting}
										currSorting={sortingInfo}
										page={page}
										pageSize={pageSize}
										paginationData={paginationData}
										listLength={tableData.length}
										handlepageSizeChange={handlepageSizeChange}
										handlePageChange={handlePageChange}
										isDateRangeFilter={false}
										isDisabled={false}
										isFetching={true}
									/>
								) : tableData ? (
									<Loader />
								) : (
									<span style={{ display: 'flex', justifyContent: 'center' }}>No Records Found</span>
								)}
							</Grid>
						</div>
					</>
				)}
				{/* TODO END */}
			</Grid>
		</Box>
	);
};

export default CostSummary;

import moment from "moment";

export function getPercentageOfTwoNumbers(firstNumber: any, lastNumber: any) {
    // firstNumber indicates to Last month/year count
    // lastNumber indicates to latest month/year count
	let percentage = 0;
    if (lastNumber) {
        percentage = ((lastNumber - firstNumber) / firstNumber) * 100
    }
	return percentage;
}

export function formatByByte(selectByte: string, kpiValue: any) {
    return (selectByte === 'PB') ? kpiValue : (selectByte === 'TB') ? kpiValue*1024 : (kpiValue*1024)*1024; 
}

export function formatNumberWithCommas(number:any, isDecimal: boolean = false) {
    if (number != null && !isNaN(number)) {
        if(isDecimal) {
            const formattedNumber = Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            if (formattedNumber === "-0.00") {
                return "0.00";
            }
            return formattedNumber;
        }
        else
            return Number(number).toLocaleString('en-US');
    } else {
        if(isDecimal)
            return "0.00";
        else 
            return "0";
    }
}

export function getHoursList(timeGap: number) {
    // t = []; p=0; incr = 1, pl=0;
    // for(i = 0; i<=((60/30)*24); i++) {
    //     pl = p+''+i;
    // t.push(p+''+i+':'+(30*incr))
    // incr++;
    //     if (incr == (60/30)) {
    //         incr = 1;
    //     }
    //     if (i > 9) {
    //         p = 1;
    //     }
    // }
    return ['00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30',
        '08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30',
        '16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30'
    ]
}


export function convertShortDateToActualDateFormat(selectedDate: any) {
    let formattedDate = moment().format('YYYY-MM');
    if (selectedDate == '0M') {
        formattedDate = moment().format('YYYY-MM');
    } else if (selectedDate == '1M') {
        formattedDate = moment().subtract(1, 'months').format('YYYY-MM');
    } else if (selectedDate == '3M') {
        formattedDate = moment().subtract(3, 'months').format('YYYY-MM');
    } else if (selectedDate == '6M') {
        formattedDate = moment().subtract(6, 'months').format('YYYY-MM');
    } else if (selectedDate == '9M') {
        formattedDate = moment().subtract(9, 'months').format('YYYY-MM');
    } else if (selectedDate == '1Y') {
        formattedDate = moment().subtract(1, 'years').format('YYYY-MM');
    } else if (selectedDate == '2Y') {
        formattedDate = moment().subtract(2, 'years').format('YYYY-MM');
    } else {
        formattedDate = selectedDate
    }
    return formattedDate;
}
import React, { useEffect, useRef, useState } from 'react';
import {
	Grid,
	Stack,
	Box,
} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import { FetchFinOpsDetailsSummary, fetchFinOpsExecutiveSummary, FetchFinOpsResourceSummary, getDetailSummarySector, FetchFinOpsCostKpi } from '../../service/service';
import '../FineOps/finops.scss';
import ReactECharts from 'echarts-for-react';
import '../DataEstate/data-estate-dashboard.scss';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { CellExpanderFormatter } from '../cell_expander_formatter';
import { Link, useSearchParams } from 'react-router-dom';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import FilterAll, { FilterCheckedListType } from 'components/Dropdown/FilterAll';
import PageHeader from 'components/Header/Page';
import DetailsIcon from '../../assets/finop-detail.svg';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import DoughnutChartWidget from 'components/Widgets/DoughnutWidget';

const DetailsSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [DetailsCloudCost, SetDetailsCloudCost] = useState('-')
    const [grafanaSummaryByResourceType, setGrafanaSummaryByResourceType] = useState<any>()	
	const [grafanaSummaryByResourceTypeLoading, setGrafanaSummaryByResourceTypeLoading] = useState(true);
	const [cloudCost, setCloudCost] = useState('');

	const [groupingInfo, setGroupingInfo] = useState<any>({});

	const [doughnutTotal, setDoughnutTotal] = useState<any>();
	const [doughnutSeriesData, setDoughnutSeriesData] = useState<any>([]);

	const [grafanaAzureCostLoading, setGrafanaAzureCostLoading] = useState(true);
    const [grafanaAzureCostOptions, setGrafanaAzureCostOptions] = useState<any>();

	function formatNumber(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0";
		}
	}

	function formatNumberOne(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
		} else {
			return "0";
		}
	}

	function formaNumberTwo(number:any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0.00";
		}
	}

	const callFetchFinOpsCostKpi = (groupFilters?: any) => {
		SetDetailsCloudCost('-');

		setGrafanaSummaryByResourceTypeLoading(true);
		FetchFinOpsCostKpi(groupFilters)
			.then((res) => {
				let data = res.data.totalCost.YTDCost;
				let totalSum = res.data.totalCost.totalSum;

				// Transform data into ECharts series format
				let seriesData = Object.keys(data).map((team: string) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`
				})).sort((a: any, b: any) => a.name.localeCompare(b.name));

				const others = seriesData.filter((item: { name: string; }) => item.name === 'Others');
				seriesData = seriesData.filter((item: { name: string; }) => item.name !== 'Others');
				seriesData = seriesData.concat(others);

				setDoughnutTotal(totalSum);
				setDoughnutSeriesData(seriesData);
				setGrafanaAzureCostLoading(false);

				const costTrendData = res.data.chartData;
				const arrayData = res?.data?.chartData ? Object.entries(res.data.chartData).map(([key, value]) => ({
					[key]: value
				})) : [];
				// Extract all the months and teams
				const months = Object.keys(costTrendData);
				// let teams = Object.keys(costTrendData[months[0]]);
				let teams = Object.keys(costTrendData[months[0]]).filter(key => key !== 'TotalCost');
				const othersVal = teams.filter(team => team === 'Others');
				teams = teams.filter(team => team !== 'Others');
				teams = teams.concat(othersVal);

				// Prepare series data for each team
				let seriesChartData = teams.map(team => {
					return {
						name: team,
						type: 'bar',
						stack: 'total', 
						data: months.map(month => costTrendData[month][team] || 0),
						barWidth: '50%'
					};
				});

				const option = {
					// backgroundColor: '#053367',
					title: {
						text: months.length === 0 ? 'No data to display' : '',
						textStyle: {
							color: '#f5f5f5',
							fontWeight: 'normal',
							fontSize: 14
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						backgroundColor: '#003771', 
						textStyle: {
							fontFamily: 'Montserrat',
							fontSize: 14,
							color: '#A7ADCB'
						},
						formatter: (params: any[]) => {
							let tooltipContent = `${params[0].axisValue}<br/>`;
							let totalCost = costTrendData[params[0].axisValue]['TotalCost'];
							if (totalCost) {
								let totalCostValue = formaNumberTwo(totalCost / 1000000) + 'MM';
								tooltipContent += `TotalCost: $${totalCostValue}<br/></div>`;
							}
							params.forEach((item: { value: number; marker: any; seriesName: any; }) => {
								let value = formaNumberTwo(item.value / 1000000) + 'MM';
								tooltipContent += `${item.marker} ${item.seriesName}: $${value} <br/>`;
							});
							return tooltipContent;
						}
					},
					xAxis: {
						type: 'category',
						data: months,
						axisLine: {
							lineStyle: {
								color: '#A7ADCB'
							}
						},
						axisLabel: {
							color: '#F5F5F5',
							fontFamily: 'Montserrat',
							fontSize: 11,
							rotate: 30
						}
					},
					yAxis: {
						type: 'value',
						// name: 'Total Cost USD ($ in MM)',
						// nameLocation: 'center',
						// nameTextStyle: {
						// 	color: '#F5F5F5',
						// 	fontFamily: 'Montserrat',
						// 	fontSize: 13
						// },
						// nameGap: 70,
						axisLabel: {
							color: '#F5F5F5',
							formatter: (value: number) => {
								return `$${formatNumberOne(value / 1000000)}MM`;
							}
						},
						splitLine: {
							lineStyle: {
								color: '#094a93'
							}
						}
					},
					series: seriesChartData, 
					grid: {
						left: '3%',
						right: '4%',
						bottom: '18%',
						top: '4%',
						containLabel: true
					},
					graphic: {
						elements: [
							{
								type: 'text',
								left: '8px', 
								bottom: 25, 
								style: {
									text: 'Programs',
									fill: '#F5F5F5',
									fontFamily: "Montserrat",
									fontWeight: 'bold',
								}
							}
						]
					},
					legend: {
						data: teams, 
						textStyle: {
							color: '#F5F5F5' 
						},
						orient: 'horizontal', 
						bottom: 20, 
						left: '80px',
					},
					textStyle: {
						fontFamily: "Montserrat"
					}
				};

				setGrafanaSummaryByResourceType(arrayData);
				setGrafanaSummaryByResourceTypeLoading(false);

			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	async function getDetailsSummary(prgName: string, month: any) {
		let groupInfos:any= {};
		if (searchParams.get('sector')) {
			groupInfos['Sectors'] = [searchParams.get('sector')];
		}
		if (searchParams.get('programName')) {
			groupInfos['Program'] = [searchParams.get('programName')];
		}
		if (searchParams.get('team')) {
			groupInfos['Team'] = [searchParams.get('team')];
		}
		if (searchParams.get('consumedService')) {
			groupInfos['ConsumedService'] = [searchParams.get('consumedService')];
		}
		if (searchParams.get('environmentName')) {
			groupInfos['Environment'] = [searchParams.get('environmentName')];
		}
		if (searchParams.get('environmentName')) {
			groupInfos['Environment'] = [searchParams.get('environmentName')];
		}
		if (searchParams.get('month')) {
			const monthParam = [searchParams.get('month')];
			const monthData = monthParam[0]?.split(',');
			groupInfos['Month'] = monthData;
		}
		callFetchFinOpsCostKpi(groupInfos);

		// FetchFinOpsDetails('ytd')
		// 	.then((res) => {
		// 		let count = res?.data?.result[0]?.total_cost;
		// 		SetDetailsCloudCost((count / 1000000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		setGroupingInfo(groupInfos);
		detailTableFunc(page, pageSize, sortingInfo, groupInfos);
	}
	
// table

interface Row {
	projectName: string;
	totalCost: any,
	isExpanded?: boolean;
	isExpandedChild?: boolean;
	consumedservices:[];
	consumedservice:any,
	children: Row[];
	ischildren?: boolean;
	resource:any;
	year:any;
	2024: string;
	total_cost:any;
}
function getColumns(): Array<Column<Row>> {
	return [
		{
			key: 'projectName',
			name: 'Project',
			minWidth: 180,
			formatter({ row, isCellSelected }) {
				const hasChildren = row.consumedservices;
				const hasChildrenofChild = row.consumedservice;
				return (
					<>
						{hasChildren && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpanded === true}
								onCellExpand={() => {
									toggleSubRow(row.projectName,row.consumedservices);
								}}
							/>
						)}
						{'   '}
						<Link
							className="jobsNameList"
							to=''
							state={row}
							style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
						>
						     {row.projectName}
						</Link>
						{' '}
						{/* {hasChildrenofChild && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpandedChild === true}
								onCellExpand={() => {
									toggleSubOfSubRow(projectName,row.consumedservice);
								}}
							/>
						)}
						{'   '} */}
						<Link
							className="jobsNameList"
							to=''
							state={row}
							style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
						>
						     {row.consumedservice}
						</Link>
						{/* {' '}
						<Link
							className="jobsNameList"
							to=''
							state={row}
							style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
						>
						     {row.resource}
						</Link> */}
					</>
				);
			}
		},
		{
			key: '2024',
			name: '2024',
			minWidth: 170,
			formatter({ row, isCellSelected }) {
				let formattedCost;
				const costValue = parseFloat(row['2024']);
				if (costValue >= 1000000) {
					formattedCost = `${formaNumberTwo((costValue / 1000000))}MM`;
				} else if (costValue >= 1000) {
					formattedCost = `${formaNumberTwo((costValue / 1000))}k`;
				} else {
					formattedCost = formaNumberTwo(costValue);
				}
				return (
					<Box textAlign='right'>${formattedCost}</Box>
				);
			}
		},
		{
			key: 'totalCost',
			name: 'Total',
			minWidth: 170,
			formatter({ row, isCellSelected }) {
				let formattedCost;
				if (row.totalCost >= 1000000) {
					formattedCost = `${formaNumberTwo((row.totalCost / 1000000))}MM`;
				} else if (row.totalCost >= 1000) {
					formattedCost = `${formaNumberTwo((row.totalCost / 1000))}k`;
				} else {
					formattedCost = formaNumberTwo(row.totalCost);
				}
				return (
					<Box textAlign='right'>${formattedCost}</Box>
				);
			}
		},
	];
}
let projectName:any='';
function toggleSubRow(name: string,resourceTypes:any) {
	const rows = projectExpandList.current;
	const rowIndex = rows.findIndex((r: any) => r.projectName === name);
	const row = rows[rowIndex];
	const newRows = [...rows];
	let children: Row[] = [];
	let childRows: Row[] = [];
    projectName = name;
	if (!row.isExpanded) {
		if (resourceTypes.length>0) {
			childRows = resourceTypes;
		}
		children = childRows.map((x: any) => {
			return { ...x, isChildren: true };
		});
		newRows.splice(Number(rowIndex) + 1, 0, ...children);
		newRows[rowIndex] = { ...row, isExpanded: true, children };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		row.isExpanded = true;
	}
	else
	{
		children = row.children;
		newRows.splice(Number(rowIndex) + 1, children?.length);
		newRows[rowIndex] = { ...row, isExpanded: false };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		row.isExpanded = false;
	}
	projectExpandList.current = newRows;
	setProjectList([...newRows]);
}
function toggleSubOfSubRow(name: string,resourceType:string) {
	const rows = projectList;
	const rowIndex = rows.findIndex((r: any) => r.projectName === name);
	const row = rows[rowIndex];
	const newRows = [...rows];
	let children: Row[] = [];
	let childRows: Row[] = [];
	if (!row.isExpandedChild) {
		setLoading(true);
		const resourceList = FetchFinOpsResourceSummary(page, pageSize, name, resourceType);
		resourceList.then((res) => {
			setLoading(false);
			if (res.success) {
			   childRows = res.data.result;
			}
			children = childRows.map((x: any) => {
			 	return {...x,isChildren: true };
			});
			newRows.splice(Number(rowIndex) + 1, 0, ...children);
			newRows[rowIndex] = { ...row, isExpanded: row.isExpandedChild, isExpandedChild: row.isExpandedChild, consumedservice: resourceType, children };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			setProjectList(newRows);
		});
	}
	else
	{
		children = row.children;
		newRows.splice(Number(rowIndex) + 1, children.length);
		newRows[rowIndex] = { ...row, isExpanded: row.isExpandedChild, isExpandedChild: row.isExpandedChild };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		setProjectList(newRows);
	}
	row.isExpandedChild = !row.isExpandedChild;
}
const defaultColumnNames = ['Project','2024','Total'];
const [projectList, setProjectList] = useState<Row[]>([]);
const projectExpandList = useRef<Row[]>([]);
const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
const [page, setPage] = useState(1);
const [pageSize, setPageSize] = useState(100);

const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
	detailTableFunc(value, pageSize, sortingInfo, groupingInfo);
};

const handlepageSizeChange = (event: any) => {
	detailTableFunc(page, event.target.value, sortingInfo, groupingInfo);
};

	const [selectedSite, setSelectedSite] = useState<string[]>([]);
	const [selectedEnvironment, setSelectedEnvironment] = useState<string>('all');
	const [selectedSector, setSelectedSector] = useState<string[]>([]);

	const fetchSectorOptionsData = async (
		filter: string,
		selectedProgram: { Sector: string | string[]; searchQuery: (string | undefined)[] }
	) => {
		if (filter === 'Program') {
			const res = await fetchFinOpsExecutiveSummary('program', selectedProgram);
			return res;
		} else {
			const res = await getDetailSummarySector(filter, selectedProgram);
			return res;
		}
	};

	const handleFilterApply = (
		groupInfos: Record<string, FilterCheckedListType[keyof FilterCheckedListType]>,
		checkedList: FilterCheckedListType
	) => {
		callFetchFinOpsCostKpi(groupInfos);
		setLoading(true);
		FetchFinOpsDetailsSummary('', '', 'detail', groupInfos).then((res) => {
			setLoading(false);
			setProjectList(res.data.result);
			projectExpandList.current = res.data.result;
		});
		setSelectedSector(checkedList.sectors);
	};

	useEffect(() => {
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/details'|| window.location.pathname === '/dataestate/financialDetails') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getDetailsSummary('', '2024-05');
	}, []);

	const handleSorting = (sortingInfo: any) => {
		detailTableFunc(page, pageSize, sortingInfo, groupingInfo);
	};

	async function detailTableFunc(pageNumber: any, size: any, sortingInfo: any, groupInfo?: any) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		setLoading(true);
		const res = await FetchFinOpsDetailsSummary(pageNumber.toString(), size.toString(), 'detail',groupInfo, sortingInfo);
		if (res.success) {
			setLoading(false);
			setProjectList(res.data.result);
			projectExpandList.current = res.data.result;
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	const seriesData = [
		{ name: 'ECC', dataKey: 'ECC', color: '' },
		{ name: 'EDF', dataKey: 'EDF', color: '' },
		{ name: 'FPA', dataKey: 'FPA', color: '' },
		{ name: 'GNRM', dataKey: 'GNRM', color: '' },
		{ name: 'IBP', dataKey: 'IBP', color: '' },
		{ name: 'MDIP', dataKey: 'MDIP', color: '' },
		{ name: 'Modern Reporting and Analytics', dataKey: 'Modern Reporting and Analytics', color: '' },
		{ name: 'Mosaic', dataKey: 'Mosaic', color: '' },
		{ name: 'PepWise', dataKey: 'PepWise', color: '' },
		{ name: 'Shared Storage', dataKey: 'Shared Storage', color: '' },
		{ name: 'Others', dataKey: 'Others', color: '' },
	]
	
	return (
		<Box mt="60px" p={3}>
			{loading && <Loader />}
			<Stack direction="row" justifyContent="space-between" position="sticky" top={80} zIndex={1} bgcolor="#001E3E">
				<PageHeader title="Details" icon={DetailsIcon} lastSync="Today" />
				<Stack direction="row" gap={1} alignItems="center">
					<FilterAll handleFilterApply={handleFilterApply} fetchSectorOptionsData={fetchSectorOptionsData} />
				</Stack>
			</Stack>
				<Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<DoughnutChartWidget
							title="Azure Cloud Cost (YTD)"
							isLoading={grafanaAzureCostLoading}
							seriesData={doughnutSeriesData}
							totalText={`{small|Total Cost}\n{large|$${(doughnutTotal / 1000000).toFixed(2)} MM}`}
						/>
					</Grid>
				<Grid item xs={12} md={8}>
					<BarChartWidget
						title="Cost Summary (MoM)"
						isLoading={grafanaSummaryByResourceTypeLoading}
						data={grafanaSummaryByResourceType}
						seriesData={seriesData}
						isCost
						legendLabel="Programs"
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={projectList}
						tableType="finops-detail"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={projectList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						title="Detailed View"
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DetailsSummary;
function team(value: string, index: number, array: string[]): value is string {
	throw new Error('Function not implemented.');
}


import moment from 'moment';
import { formatNumberWithCommas, getPercentageOfTwoNumbers } from 'utils/common-util';
import { conversionUnitProp } from './type';

function formatByByte(selectByte: string, kpiValue: any) {
	return selectByte === 'PB'
		? kpiValue
		: selectByte === 'TB'
		? kpiValue * 1024
		: selectByte === 'GB'
		? kpiValue * 1024 * 1024
		: kpiValue;
}

function formatNumber(number: any) {
	if (number != null && !isNaN(number)) {
		return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	} else {
		return '0.00';
	}
}

function formatNumberM(number: any) {
	if (number != null && !isNaN(number) && number !== 0) {
		return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
	} else {
		return '0';
	}
}

const formatNumberUnits = (number: number) => {
	if (number >= 1000000000) {
		return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
			number / 1000000000
		)}B`;
	}
	if (number >= 100000) {
		return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
			number / 1000000
		)}MM`;
	}
	if (number >= 1000) {
		return `${formatNumberWithCommas((number / 1000).toFixed(2), true)}K`;
	}
	return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
};

const formatNumberByUnits = (number: number) => {
	if (number >= 1000000) {
		return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
			number / 1000000
		)}MM`;
	}
	if (number >= 1000) {
		return `${formatNumberWithCommas((number / 1000).toFixed(2), true)}K`;
	}
	return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
};

export const defaultOptions = {
	title: {
		show: true,
		textStyle: {
			color: '#bcbcbc',
			fontWeight: 'normal',
			fontSize: 16
		},
		text: 'No data',
		left: 'center',
		top: 'center'
	},
	backgroundColor: '#053367'
};

export const getChartOptions = (
	chartData: any,
	selectedByte: conversionUnitProp,
	conversionUnit: string,
	chartInterval: number | 'auto',
	lastUpdatedDate: string,
	isDatabrick: boolean
) => {
	if (!chartData || Object.keys(chartData).length === 0) {
		return { chartOption: null, isDisplayChart: false };
	}
	let allDataAsArray = Object.entries(chartData);
	// const allMonths = Object.keys(chartData);
	// Get the current date in YYYY-MM format
	const today = new Date();
	const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
	const currentYearMonth = nextMonth.toISOString().slice(0, 7);
	// Filter keys that are greater than or equal to the current month
	// const allMonths = Object.keys(chartData).filter(key => key <= currentYearMonth);

	const allMonths = Object.keys(chartData);

	let badgePercentage = null;
	let chartTooltip = null;

	const currentDate = moment().format('YYYY-MM');
	const firstMonthFromAllMonths = allMonths[0];
	// const lastMonth = moment().subtract(1, 'months').format('MM');
	const lastMonth = moment().format('MM');
	const indexValue = moment().year() + '-' + lastMonth;
	const lastIndex = allMonths.indexOf(indexValue);
	const lastMonthFromAllMonths = allMonths[lastIndex];
	const lastMonthFromForecast = allMonths[allMonths.length - 1];
	let currentMonth = new Date().getMonth();
	if (currentMonth == 0) currentMonth = 12;
	const currentYear = new Date().getFullYear();
	const lastMonthString = currentMonth.toString().padStart(2, '0');
	const filterMonth = `${currentYear - 1}-${lastMonthString}`; //(currentYear-1) +'-12';
	const filterDateEntry = Object.entries(chartData).filter((x) => x[0] === filterMonth);
	if (filterDateEntry.length) {
		let count: any = 0;
		count = filterDateEntry[0][1];
		const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
		const lastMonthEntry = Object.entries(chartData).filter((x) => x[0] === lastMonthDataIndex);
		if (lastMonthEntry.length) {
			const lastmonthvalue = lastMonthEntry[0][1] as number;
			// let lastMonthVal = formatByByte(selectedByte, lastmonthvalue);
			// let lastYearVal = formatByByte(selectedByte, count);
			//  setGrowthCurrent(lastMonthVal);
			//  setGrowthLastYear(lastYearVal);
			if (allMonths.length > 12) {
				badgePercentage = getPercentageOfTwoNumbers(count, lastmonthvalue);
			}
		} else {
			const annualMonth = moment(firstMonthFromAllMonths, 'YYYY-MM').format('MMMM YYYY');
			chartTooltip = `Data available from ${annualMonth}`;
		}
	}

	// const lineData = allMonths.length > 12 ? allDataAsArray : allDataAsArray.filter(([date]) => date < currentDate);
	const lineData = allDataAsArray.filter(([date]) => date <= currentDate);
	const currentMonthIndex = allMonths.findIndex((month: string) => month === currentDate);
	const formattedCurrentDate = moment().format('YYYY-MM-DD');

	const chartOption = {
		title: {
			show: Object.keys(allDataAsArray).length === 0,
			textStyle: {
				color: '#bcbcbc',
				fontWeight: 'normal',
				fontSize: 16
			},
			text: 'No data for the selected filters',
			left: 'center',
			top: 'center'
		},
		backgroundColor: '#053367',
		grid: {
			top: '13%',
			left: '15%',
			right: '2%',
			bottom: '12%',
			backgroundColor: 'transparent'
		},
		xAxis: {
			type: 'category',
			splitLine: {
				show: true,
				lineStyle: {
					type: 'dashed',
					color: '#004477'
				}
			},
			data: allMonths,
			boundaryGap: false,
			axisLabel: {
				interval: chartInterval,
				rotate: 30,
				color: '#A7ADCB',
				formatter: function (value: any) {
					return `${moment(value).format('MMM-YY')}`;
				}
			}
		},
		yAxis: {
			type: 'value',
			// name: selectedByte === 'cost' ? '$Cost' : '',  // Y-axis title
			// nameLocation: 'end', // 'start', 'middle', 'end'
			// nameTextStyle: {
			// 	color: '#A7ADCB', // Color of the Y-axis title
			// 	fontSize: 14,
			// 	padding: [0, 0, 4, 0]  // Padding to position the title
			// },
			splitLine: {
				show: true,
				lineStyle: {
					type: 'dashed',
					color: '#004477'
				}
			},
			axisLabel: {
				color: '#A7ADCB',
				rotate: 0,
				formatter: function (value: any) {
					if (selectedByte === 'byte') {
						let optValue = formatByByte(conversionUnit, value);
						return formatNumberUnits(optValue);
					}
					if (selectedByte === 'cost' || isDatabrick == true) {
						return `$${formatNumberUnits(value)}`;
					}
					return formatNumberUnits(value);
				}
			}
		},
		series: [
			{
				name: 'Current Growth',
				type: 'line',
				smooth: false,
				data: lineData,
				itemStyle: {
					color: '#009BD8' // Line color
				},
				animation: false,
				markPoint: {
					symbol: 'circle',
					data: [
						currentMonthIndex !== -1 && {
							name: 'Current Month',
							coord: [allMonths[currentMonthIndex], lineData[currentMonthIndex][1]], // Position for the current month
							value: lineData[currentMonthIndex][1],
							itemStyle: {
								color: '#FFA500' // Change color for the current month point
							},
							label: {
								show: true,
								position: 'top',
								offset: [selectedByte === 'byte' ? -36 : -28, 10],
								color: '#fff',
								fontWeight: 'bold',
								formatter: (params: any) => {
									if (selectedByte === 'byte') {
										const number = formatNumberUnits(formatByByte(conversionUnit, params.value));
										return `${number} ${conversionUnit}`;
									}
									if (selectedByte === 'cost') {
										return `$${formatNumberUnits(params.value)}`;
									}
									return formatNumberUnits(params.value);
								}
							},
							symbolSize: 8
						}
					].filter(Boolean)
				},
				markLine: currentMonthIndex === allMonths.length - 1 && {
					silent: true,
					symbol: 'none',
					lineStyle: {
						type: 'dotted',
						color: '#FFA500',
						width: 1
					},
					data: [
						{
							xAxis: allMonths[currentMonthIndex] // Vertical line at the current month
						}
					],
					label: {
						formatter: () => `Last Update Date: ${lastUpdatedDate != '' && lastUpdatedDate != undefined && lastUpdatedDate != null ? lastUpdatedDate : formattedCurrentDate}`,
						position: 'end',
						color: '#fff',
						fontWeight: 'normal',
						align: 'right'
					}
				}
			}
		],
		tooltip: {
			trigger: 'axis',
			formatter: (params: any) => {
				const { value } = params[0];
				const dateLabel = moment(value[0]).format('MMM-YY');
				if (selectedByte === 'byte') {
					const optValue = formatByByte(conversionUnit, value[1]);
					return `${dateLabel}: ${formatNumberUnits(optValue)} ${conversionUnit}`;
				}
				if (selectedByte === 'cost') {
					return `${dateLabel}: $${formatNumberUnits(value[1])}`;
				}
				return `${dateLabel}: ${formatNumberUnits(value[1])}`;
			},
			backgroundColor: '#053367',
			textStyle: {
				color: '#A7ADCB'
			}
		}
	};

	// if (allMonths.length > 12) {
	// 	chartOption.series[0].markArea.data.push([
	// 		{
	// 			name: 'Forecast',
	// 			xAxis: currentDate
	// 		},
	// 		{
	// 			xAxis: lastMonthFromForecast
	// 		}
	// 	]);
	// }

	return { chartOption, badgePercentage, chartTooltip, isDisplayChart: true };
};

export const getBarChartOptions = (
	reportData: any[],
	seriesData: { name: string; dataKey: string; color: string }[],
	isCost: boolean = false,
	legendLabel: string
) => {
	let totalCostData: any = {};
	let xAxisArray: string[] = [];
	let seriesOptions: any[] = [];

	reportData.forEach((res: any, i: number) => {
		const key = Object.keys(res)[0];
		xAxisArray.push(key);
	});

	seriesData.forEach((series) => {
		const dataArray: any[] = reportData.map((res: any, i: number) => {
			const key = Object.keys(res)[0];

			if (isCost && res[key]?.TotalCost) {
				totalCostData[key] = res[key].TotalCost;
			}
			return res[key][series.dataKey];
		});

		seriesOptions.push({
			silent: true,
			animation: false,
			name: series.name,
			type: 'bar',
			stack: 'total',
			data: dataArray,
			itemStyle: {
				color: series.color
			}
		});
	});

	const option = {
		backgroundColor: '#003771',
		grid: {
			top: '4%',
			left: '3%',
			right: '3%',
			bottom: '18%',
			containLabel: true
		},
		xAxis: {
			type: 'category',
			data: xAxisArray,
			axisLabel: {
				color: '#fff'
			}
		},
		yAxis: {
			type: 'value',
			axisLabel: {
				color: '#fff',
				formatter: (value: number) => (isCost ? `$${formatNumberUnits(value)}` : value)
			}
		},
		series: seriesOptions,
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			},
			position: function (point:any, params:any, dom:any, rect:any, size:any) {
				const [x, y] = point;
				const [contentWidth, contentHeight] = size.contentSize;
				const [viewWidth, viewHeight] = size.viewSize;
			
				const xOffset = 10;
				const yOffset = 10;
			
				let posX = x + xOffset;
				let posY = y - contentHeight - yOffset;
			
				// Ensure the tooltip stays within the viewport horizontally
				if (posX + contentWidth > viewWidth) {
					// If tooltip goes beyond the right edge, move it to the left
					posX = x - contentWidth - xOffset;
				}
			
				if (posX < 0) {
					// If tooltip goes beyond the left edge, place it on the right of the cursor
					posX = x + xOffset;
				}
			
				// Ensure the tooltip stays within the viewport vertically
				if (posY < 0) {
					// If tooltip goes beyond the top, position it below the cursor
					posY = y + yOffset;
				}
			
				// Ensure tooltip doesn’t go beyond the bottom of the viewport
				if (posY + contentHeight > viewHeight) {
					posY = viewHeight - contentHeight - yOffset;
				}
			
				return [posX, posY];
			},
			backgroundColor: '#003771',
			textStyle: {
				fontFamily: 'Montserrat',
				fontSize: 14,
				color: '#A7ADCB'
			},
			formatter: (params: any[]) => {
				let tooltipContent = `${params[0].axisValue}<br/>`;
				if (isCost && Object.keys(totalCostData).length > 0) {
					const value = totalCostData[params[0].axisValue];
					tooltipContent += `TotalCost: $${formatNumberUnits(value)}<br/></div>`;
				}
				params.forEach((item) =>
					isCost
						? (tooltipContent += `${item.marker} ${item.seriesName}: $${formatNumberUnits(item.value)} <br/>`)
						: (tooltipContent += `${item.marker} ${item.seriesName}: ${item.value} <br/>`)
				);
				return tooltipContent;
			}
		},
		graphic: {
			elements: [
				{
					type: 'text',
					left: '10px',
					bottom: '20px', 
					style: {
						text: legendLabel,
						fill: '#F5F5F5',
						fontFamily: "Montserrat",
						fontWeight: 'bold',
					}
				}
			]
		},
		legend: {
			show: true,
			left: '80px',
			bottom: '5px',
			data: seriesData.map((series) => series.name),
			textStyle: {
				color: '#fff'
			}
		}
	};

  return option;
};

export const getDoughnutChartOptions = (
	totalSum: any, 
	seriesData: { name: string, value: any, percentage: string, formattedName: string }[]
  ) => {
	const option = {
		backgroundColor: '#003771',
		tooltip: {
			backgroundColor: '#003771', 
			textStyle: {
			color: '#A9ADCB', 
			},
			formatter: function (params: any) {
			let value = params.value;
			let formattedValue = formatNumberByUnits(value);
			return `${params.name} : ${params.percent}%  ($${formattedValue})`;
			},
			position: 'inside'
		},
		series: [
			{
			type: 'pie',
			radius: ['70%', '50%'],
			center: ['50%', '50%'],
			label: {
				show: false
			},
			data: seriesData.map((item: any) => ({
				name: item.name,
				value: item.value
			})),
			emphasis: {
				itemStyle: {
				shadowBlur: 10,
				shadowOffsetX: 0,
				shadowColor: 'rgba(0, 0, 0, 0.5)'
				}
			}
			}
		],
		graphic: {
			type: 'text',
			left: 'center',
			top: 'center',
			bottom: 'center',
			right: 'center',
			style: {
			text: totalSum, 
			textAlign: 'center',
			fill: '#fff',
			rich: {
				small: {
				  fontSize: 12, 
				  fontWeight: 'normal',
				  lineHeight: 20
				},
				large: {								
				  fontSize: 24, 
				  fontStyle: 'normal',
				  fontWeight:700,
				  lineHeight: 29,
				  fontfamily: 'Montserrat',
				  color: "#FFFFFF",
				  display: "flex",
				  alignitems: "flex-end"
				}
			},
			}
		},
		textStyle: {
			fontFamily: "Montserrat"
		}
	};
  
	return option;
  };

export const getPieChartOptions = (
	seriesData: any, 
	) => {
	const option = {
		tooltip: {
			backgroundColor: '#003771',
			textStyle: {
				color: '#A9ADCB'
			},
			formatter: function (params: any) {
				let value = params.value;
				let formattedValue = formatNumberByUnits(value);
				return `${params.name} : ${params.percent}%  ($${formattedValue})`;
			},
			position: 'inside'
		},
		legend: {
			bottom: 4,
			left: 'center',
			data: seriesData.map((item: any) => item && item.name ? item.name : ''),
			textStyle: {
				color: '#A7ADCB',
				fontSize: 12
			}
		},
		series: [
			{
				type: 'pie',
				radius: '55%',
				center: ['50%', '40%'],
				selectedMode: 'single',
				label: {
					show: true,
					position: 'inside',
					textStyle: {
						align: 'center',
						verticalAlign: 'middle'
					},
					formatter: function (params: any) {
						return `${params.percent.toFixed(2)}%`;
					},
					rich: {
						a: {
							color: '#E5E5E5',
							fontSize: 12,
							lineHeight: 20
						},
						b: {
							color: '#E5E5E5',
							fontSize: 12
						}
					},
					color: '#F5F5F5'
				},
				data: seriesData.map((item: any) => ({
					name: item.name,
					value: item.value,
					label: {
						formatter: function (params: any) {
							if (params.percent < 8) {
								return '';
							}
							return `${item.formattedName}`;
						}
					}
				})),
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}
		],
		textStyle: {
			fontFamily: 'Montserrat'
		}
	};

	return option;
};

export const getProgressChartOptions = (
	data: any, 
	envNames: any,
	seriesData: any, 
	) => {
		const option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				},
				backgroundColor: '#003771',
				textStyle: {
					color: '#A9ADCB'
				},
				confine: true,
				formatter: (params: any) => {
					const fullProgramName = data[data.length - 1 - params[0].dataIndex].programname;
					let tooltipText = `${fullProgramName}<br/>`;
					params.forEach((param: any) => {
						if (param.seriesName !== 'TOTAL_COST') {
							let value = param.value;
							value = formatNumberByUnits(value);
							tooltipText += `${param.marker} ${param.seriesName}: $${value}<br/>`;
						}
					});

					// Add total_cost to the tooltip
					const costIdx = data.length - 1 - params[0].dataIndex;
					const totalCost = data[costIdx].total_cost;
					let formattedTotalCost = formatNumberByUnits(totalCost);
					tooltipText += `Total Cost: $${formattedTotalCost}`;

					return tooltipText;
				}
			},
			legend: {
				data: envNames.map((env: string) => env.toUpperCase()),
				textStyle: { color: '#A7ADCB' },
				bottom: '0%',
				left: '0%'
			},
			grid: {
				left: '1%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			xAxis: {
				type: 'value',
				show: false,
				axisLabel: {
					color: '#A7ADCB',
					formatter: (value: number) => {
						return formatNumberByUnits(value);
					}
				},
				splitLine: {
					show: true,
					lineStyle: {
						type: 'dashed',
						color: '#004477'
					}
				}
			},
			yAxis: {
				type: 'category',
				data: data.map((item: any) => {
					const programname = item.programname;
					return programname.length > 10 ? programname.substring(0, 10) + '...' : programname;
				}).reverse(),
				axisLabel: { color: '#A7ADCB' }
			},
			series: seriesData,
			textStyle: {
				fontFamily: 'Montserrat'
			}
		};

	return option;
};

import { createTheme, ThemeOptions } from '@mui/material/styles';
import colors from 'assets/theme/base/colors';
import { pepWiseTypography } from './typographyUtil';

// Create a new theme by extending the default theme
const themeOptions: ThemeOptions = {
	palette: { ...colors },
	typography: { ...pepWiseTypography },
	breakpoints: {
		values: {
			xs: 0,
			sm: 800,
			md: 1075,
			lg: 1400,
			xl: 1550
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					textTransform: 'none'
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				caption: {
					color: '#e5e5e5',
					fontSize: '11px',
					lineHeight: 'normal'
				}
			}
		},
		MuiCalendarOrClockPicker: {
			styleOverrides: {
				root: {
					color: '#E5E5E5'
				}
			}
		},
		MuiClockNumber: {
			styleOverrides: {
				root: {
					color: '#E5E5E5'
				}
			}
		},
		PrivatePickersToolbarText: {
			styleOverrides: {
				root: {
					color: '#E5E5E5'
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '12px',
					color: '#E5E5E5',
					backgroundColor: '#1B1E35 !important'
				},
				arrow: {
					color: '#1B1E35  !important'
				}
			}
		},
		MuiChip: {
			variants: [
				{
					props: { variant: 'chartBadge' },
					style: {
						'& .MuiChip-label': {
							fontSize: '12px',
							fontWeight: 600,
							color: '#001E3E !important',
						},
						'& .MuiChip-icon': {
							color: '#001E3E !important',
							fontSize: '18px',
							marginRight: '-8px',
						},
						'& .MuiChip-root': {
							backgroundColor: '#009BD8 !important',
							width: 'fit-content !important',
						},
					}
				},
			]
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					margin: '0px !important'
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: 'white'
				},
				select: {
					padding: '10px 14px',
					'&:hover': {
						borderColor: 'transparent'
					},
					'&:focus': {
						borderColor: 'transparent', // Make border transparent when focused
						outline: 'none' // Optionally, remove the outline
					}
				},
				disabled: {
					color: 'white !important',
					opacity: '1 !important',
					WebkitTextFillColor: 'gray !important',
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				elevation: {
					ul: {
						height: 'min-content !important',
						maxheight: '130px !important'
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent'
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent'
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent'
					}
				}
			}
		},
	}
};

const theme = createTheme(themeOptions);

export default theme;

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ClickAwayListener, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Select, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DataPipelineModal } from './modal-component';
import ExpandMoreIcon from '../../assets/down-arrow.svg'
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg'
import { ReactComponent as ViewIcon } from '../../assets/view-detail-img.svg'
import { ReactComponent as ViewHighlightIcon } from '../../assets/icon-view-highlight.svg'
import ExportIcon from '../../assets/export-img.svg'
import NoPrgramFound from '../../assets/no-program-found.svg'
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import { getProgramList, getProjectListByProgram } from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { CellRendererProps } from 'react-data-grid';
import { Link } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  // const onCellClick = (e: any) => {
  //   console.log("EEEEEEEEEEEE", e)
  // }

const ProgramList = () => {

  const [projAnchorEl, setProjAnchorEl] = React.useState<null | HTMLElement>(null);
  const projectOpen = Boolean(projAnchorEl);
  const [accordionActiveProjId, setAccordionActiveProjId] = useState<any>('1');
  const [tableActiveProjId, setTableActiveProjId] = useState<string>('99999'); // Use state here
  const [tableProjAnchorEle, setTableProjAnchorEle] = useState<null | HTMLElement>(null); // Use state here
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
  const [controller] = usePepwiseAuthController();
  const { role } = controller;


    const classes = useStyles();

    const [programList, setProgramList] = useState<any>([])
    const [projectList, setProjectList] = useState<any>([])
    const [openModal, setOpenModal] = useState<any>({isOpen: false})
    const [loading, setLoading] = useState(false);
    const [filterInfo, setFilterInfo] = useState([]);
    const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
    const [filterData, setFilterData] = useState<any[]>([]);
    const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
    const [searchDetails, setFilterSearchInfo] = useState([]);
    const [currentProgram, setCurrentProgram] = useState<any>({});

    useEffect(() => {
        const activeId = document.getElementById('root');
        console.log(window.location.pathname)
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		getProgramData()

	}, [openModal]);

    const getProgramData = async () => {
        setLoading(true)
        let data: any = await getProgramList()
        setLoading(false)
        setProgramList(data.data.programData)
    }

    const handleEditClose = useCallback ((data: any) => {
        console.log("CLOSEE", data)
        setOpenModal({
            isOpen: false,
        })
    }, [])

    const handleProjectModal =  (action: any, e: any, props: any) => {
      e.preventDefault()
      if (props.row) {
        const updatedRow = { ...props.row, activeRow: '99999' };
        setProjectList((prevRows: any) => prevRows.map((prevRow: any) => prevRow.Project_Id === updatedRow.Project_Id ? updatedRow : prevRow));
      }
      console.log("PROOO", props)
      setOpenModal({
          isOpen: true,
          type: 'Project',
          action,
          data: props.row
      })
  }


    const onCreateProgramClick = useCallback (() => {
        setOpenModal({
            isOpen: true,
            type: 'Program',
            action:'CREATE',
            data: ''
        })
    }, [])

    const handleAction = (type: string, data: any, e:any) => {
      console.log(data)
      handleThreeDotsCloseMenu(e)
        e.preventDefault()
        e.stopPropagation();
        switch (type) {
            case 'edit':
                setOpenModal({
                    isOpen: true,
                    type: 'Program',
                    action: 'EDIT',
                    data
                })
                break;
                case 'delete':
                    setOpenModal({
                        isOpen: true,
                        type: 'Program',
                        action: 'DELETE',
                        data
                    })
                    break;
                 case 'view':
                 
                    window.location.href = `/data-pipeline/programs/${data.Program_ID}/${encodeURIComponent(data.program_Name)}`
                    break;
        
            default:
                break;
        }
    }

    const getProjectsByProgram =  async(data: any, e: any, page: any = 1) => {
      console.log("INNNNNNNNNNNNNNN")
        if (e) {
          e?.preventDefault()
        e?.stopPropagation();
        }
        setLoading(true)
        setCurrentProgram(data)
        let result = await getProjectListByProgram(data.Program_ID, page, pageSize)
        setTimeout(() => {
            let table = document.getElementById(`project_list_details${data.Program_ID}`)
            if (table) {
                const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
                    if (rdgElement) {
                        let jobsLength = result.data.projectList.length;
                        let height = 80;
                        if (jobsLength === 1) height = jobsLength * 80;
                        else if (jobsLength > 1 && jobsLength <=3) height = jobsLength * 65;
                        else if (jobsLength > 3 && jobsLength <=5) height = jobsLength * 55;
                        else if (jobsLength > 5 && jobsLength <=10) height = jobsLength * 50;
                        else if (jobsLength > 10 && jobsLength <=15) height = jobsLength * 40;
                        else height = jobsLength * 30;
                    rdgElement.style.height = `${height}px`
                    console.log("HEREE", height)
                }
            }
        }, 100);
        setPaginationData(result.data.page);
        setLoading(false)
        const projData = result.data.projectList.map((list: any) => { list['activeRow'] = 0; return list;})
        setProjectList(projData)
    }

    const defaultColumnNames = [
        'Project', 'Project Owner', 'Description', 'Action'
    ]

    const handleTableAction = (params: any, type: string) => {
        switch (type) {
            case 'edit':
                
                break;
            case 'view':
                
            break;
            case 'delete':
                
            break;
            default:
                break;
        }
    }
  
    let newTableActiveProjId = ''
    const handleThreeDotsClickMenu = (
      event: React.MouseEvent<HTMLElement>,
      index: number,
      prgId?: any,
      prjId?: any,
      row?: any,
      props: any = {}
    ) => {
      event.preventDefault();
      event.stopPropagation();
      // if (props.row) {
      //   setCurrentProgram(props.row)
      // }
      if (index === 99999) {
         newTableActiveProjId = `${prgId}${prjId}`;
        console.log("EVVV2222", newTableActiveProjId, prgId, prjId);
        console.log(row)
        const updatedRow = { ...row, activeRow: newTableActiveProjId, currentTarget: event.currentTarget };
        setProjectList((prevRows: any) => prevRows.map((prevRow: any) => prevRow.Project_Id === updatedRow.Project_Id ? updatedRow : prevRow));
        console.log(projectList)
        setTableActiveProjId(newTableActiveProjId); // Update state
        setTableProjAnchorEle(event.currentTarget); // Update state
        setAccordionActiveProjId(newTableActiveProjId); // Update accordion
        setProjAnchorEl(event.currentTarget); 
      } else {
        setAccordionActiveId(index);
        setAnchorEl(event.currentTarget);
      }
    };

    const gettableActiveProjId = () => {
      return tableActiveProjId;
    };
    
    const handleThreeDotsCloseMenu = (event: any, props: any = {}) => {
      console.log("PROOOOOOO", props)
      event.preventDefault();
      event.stopPropagation();
        newTableActiveProjId = '99999'
      if (props.row) {
        const updatedRow = { ...props.row, activeRow: newTableActiveProjId, currentTarget: event.currentTarget };
        setProjectList((prevRows: any) => prevRows.map((prevRow: any) => prevRow.Project_Id === updatedRow.Project_Id ? updatedRow : prevRow));
      }
    
      setAccordionActiveId(null);
      setAnchorEl(null);
      setAccordionActiveProjId(null);
      setProjAnchorEl(null);
      setTableActiveProjId('99999'); // Reset state
      setTableProjAnchorEle(null); // Reset state
    };

    useEffect(() => {
      console.log("DEBUGGGG", currentProgram, openModal, pageSize)
      if (currentProgram.Program_ID && !openModal.isOpen) {
        getProjectsByProgram(currentProgram, false, page || 1);
      }
    }, [openModal])

    const getColumns = () => {
      return [
        {
          key: 'Project_Name',
          name: 'Project',
          formatter(props: any) {
            return (
              <div className="has-ellipsis" title={props.row.Project_Name}>
                {props.row.Project_Name ? props.row.Project_Name : '--'}
              </div>
            );
          },
          minWidth: 240,
          maxWidth: 450,
        },
        {
          key: 'Project_Owner',
          name: 'Project Owner',
          formatter(props: any) {
            return (
              <div className="has-ellipsis" title={props.row.Project_Owner}>
                {props.row.Project_Owner
                  ? props.row.Project_Owner.includes('null')
                    ? props.row.Project_Owner.replace('(null)', '')
                    : props.row.Project_Owner
                  : '--'}
              </div>
            );
          },
          minWidth: 240,
        },
        {
          key: 'Project_Description',
          name: 'Description',
          formatter(props: any) {
            return (
              <div className="has-ellipsis" title={props.row.Project_Description}>
                {props.row.Project_Description ? props.row.Project_Description : '--'}
              </div>
            );
          },
          minWidth: 350,
          maxWidth: 700,
        },
        {
          key: 'action',
          name: 'Action',
          resizable: false,
          width: 100,
          formatter: (props: any) => {
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }} className='actions-item'>
          
                  <Link
                  to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(
                    selectedProgramName
                  )}/${encodeURIComponent(props.row.Project_Name)}`}
                  className="titleLink"
                >
                  <ViewIcon title="Click to view" className="mr-5 table-icon" />
                </Link>
                
                <Button
                  aria-controls={projectOpen ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={projectOpen ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={(e) =>
                    handleThreeDotsClickMenu(e, 99999, props.row.Program_ID, props.row.Project_Id, props.row)
                  }
                  className="actions-btn actions-item-list actions-item-list-dots"
                >
                  <MoreIcon />
                </Button>

                   
                {/* <ClickAwayListener onClickAway={onMenuClickAway}> */}
                <StyledMenu
                  className="menu-list-wrapper"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={props.row.currentTarget}
                  open={props.row.activeRow == `${props.row.Program_ID}${props.row.Project_Id}`}
                  onClose={(e) => handleThreeDotsCloseMenu(e, props)}
                >
                  <MenuItem
                    title="Click to edit"
                    onClick={(e) => handleProjectModal('EDIT', e, props)}
                  >
                    <EditIcon className="mr-5 icon icon-actions" /> <span className="text">Edit</span>
                  </MenuItem>
                  <MenuItem
                    title="Click to delete"
                    onClick={(e) => handleProjectModal('DELETE', e, props)}
                  >
                    <DeleteIcon className="mr-5 icon icon-actions" />{' '}
                    <span className="text">Delete</span>
                  </MenuItem>
                </StyledMenu>
{/* </ClickAwayListener> */}

               
              </div>
            );
          },
        },
      ];
    };
    

    const handleFilter = (e: any) => {
        console.log("FILTER", e)
    }

    const handleSearch = (e: any) => {
        console.log("FILTER", e)
    }

    const handleSorting = (e: any) => {
        console.log("FILTER", e)
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectListByProgram(currentProgram.Program_ID, value, pageSize);
	};

    const handlepageSizeChange = (e: any) => {
        console.log("FILTER", e)
    }

    const [expanded, setExpanded] = useState<any>(false);
    const [selectedProgramName, setSelectedProgramName] = useState<string>('');

    const handleChange = (panel: any, programName: string) => (event: any, isExpanded: any) => {
        
        event.preventDefault()
        event.stopPropagation();
        setSelectedProgramName(programName);
      setExpanded(isExpanded ? panel : false);
      console.log('change')
    };

return (
 <div className="dashboard-panel-dataestate job-schedules-wrap">    
    {(role === 'Data Operations Admin' || role === 'PepWise System User') && (
			<div className="service-sector-filter w-190">
				{/* Code for sector filter */}
				<Stack className="sector-filter-dataestate">
					<>
						<span className="sector-cta">
                        <Button variant="contained" onClick={onCreateProgramClick} className={classes.createBtn}>
							CREATE PROGRAM
						</Button>
						</span>
					</>
					
				</Stack>
		  </div>
    )}
        <Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md" className="edit-job-modal">
				<DataPipelineModal handleEditClose={handleEditClose} programData={openModal}/>
		</Dialog>
       {
        programList.length ?
        <div className='d-flex-main m-7'>
          <div className='w-40 fw700'>Programs</div>
          <div className='w-60 fw700'>Total Projects</div>
        </div> : ''
       }
       
      <div className='program-list-container'>
      {
            
            programList.length ? 
                 programList.map((program: any, index: number) => {
                    return  <div key={index} className='my-10'>
                    <Accordion key={index} className='accordion-list-wrapper' expanded={expanded === index} onChange={handleChange(index, program.program_Name)}>
                       <AccordionSummary
                           className='acc-summ'
                           expandIcon={<img className='icon' src={ExpandMoreIcon} />}
                           aria-controls="panel1-content"
                           onClick={(e) => getProjectsByProgram(program, e)}
                           id={`panel${index}-header`}
                        >
                             <div className='d-flex-main'>
                                <div className='w-40 panel-typo'>
                                  <Typography>{program.program_Name}</Typography>
                                </div>
                                <div className='w-60'>
                                 <div className='justify-btw-items-center'>
                                  <div className='count-box fw500'>
                                      <span>{program.ProjectCount}</span>
                                  </div>
                                  <div className='actions-item'>
                                    {/* <EditIcon title='Click to edit' onClick={(e) => handleAction('edit', program, e)} className='mr-5 icon icon-actions' />
                                    <DeleteIcon title='Click to delete' onClick={(e) => handleAction('delete', program, e)} className='mr-5 icon icon-actions' /> */}
                                    <Link to={`/data-pipeline/programs/${program.Program_ID}/${encodeURIComponent(program.program_Name)}`} className="titleLink">
                                        <ViewHighlightIcon title='Click to View' className='mr-5 icon view-icon' />
                                    </Link>
                                    {(role === 'Data Operations Admin' || role === 'PepWise System User') && (<>
                                    <Button
                                        aria-controls={open ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="contained"
                                        disableElevation
                                        onClick={ (e) => handleThreeDotsClickMenu(e, index) }
                                        className='actions-btn actions-item-list'
                                    >
                                        <MoreIcon />
                                    </Button>
                                    <StyledMenu
                                        className="menu-list-wrapper"
                                        MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={accordionActiveId == index}
                                        onClose={handleThreeDotsCloseMenu}
                                    >
                                        <MenuItem title='Click to edit' onClick={(e) => handleAction('edit', program, e)}>
                                        <EditIcon className='mr-5 icon icon-actions' /> <span className="text">Edit</span>
                                        </MenuItem>
                                        <MenuItem title='Click to delete' onClick={(e) => handleAction('delete', program, e)}>
                                        <DeleteIcon className='mr-5 icon icon-actions' /> <span className="text">Delete</span>
                                        </MenuItem>
                                    </StyledMenu>
                                    </>
                                    )}
                                      {/* <span style={{margin: '0px 20px'}}> */}
                                      {/* <img onClick={(e) => handleAction('export', program, e)} className='icon' src={ExportIcon} alt="" /> */}
                                      {/* </span> */}
                                  </div>
                                  </div>
                                </div>
                             </div>
                       </AccordionSummary>
                       {
                        program.ProjectCount > 0 ? <AccordionDetails className='proj-table'>
                        <div className="programlist_tabledata mt20 padl-10" id={`project_list_details${program.Program_ID}`}>
				<Grid>
					{!loading ? (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={projectList}
							tableType="project-list"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							filterDataOptions={filterData}
							searchDataValues={searchDetails}
							onFilterCheck={handleFilter}
							currFilter={filterInfo}
							onFilterSearch={handleSearch}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={projectList.length}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isFetching={loading}
							showExport={false}
							fileName={`Projects.xlsx`}
							isDateRangeFilter={false}
							isDisabled={false}
						/>
					) : (
						<Loader />
					)}
				</Grid>
			</div>
                     </AccordionDetails>: ''
                       }
                    </Accordion>
                  </div>
                 })
             : !loading ?  <div className='flex-center h-80'>
                 <img className='image' src={NoPrgramFound} alt="" />
                 <p className='no-program-txt'>Create Program</p>
             </div> :  <div className='flex-center h-80'>
                
                 <p className='no-program-txt'>Loading...</p>
             </div>
         }
      </div>
      
       
  </div>
)
}

export default ProgramList
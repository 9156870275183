import { Chip, ChipProps } from '@mui/material';

const StatusChip: React.FC<{ status: string } & ChipProps> = ({ status, ...props }) => {
	let bgColor = '#299BFF26';
	let color = '#fff';

	switch (status.toUpperCase()) {
		case 'SUCCESS':
		case 'SUCCESSFUL':
		case 'YES':
		case 'COMPLETED':
		case 'CLOSED':
			bgColor = '#17372e';
			color = '#01c008';
			break;

		case 'SLA MET':
		case 'MET':
		case 'OPEN':
			bgColor = '#299bff26';
			color = '#299bff';
			break;

		case 'FAILURE':
		case 'TERMINATED':
		case 'SUSPENDED':
		case 'NO':
		case 'FAILED':
			bgColor = '#3d2033';
			color = '#cd2429';
			break;

		case 'SLA MISSED':
			bgColor = '#fd783b26';
			color = '#fd783b';
			break;

		case 'NOT STARTED':
			bgColor = '#1B1E35CC';
			color = '#71758F';
			break;

		case 'N/A':
		case 'NA':
			bgColor = '#1B1E35CC';
			color = '#A9ADCB';
			break;

		case 'SCHEDULED':
			bgColor = '#ffa20033';
			color = '#ffa200';
			break;

		case 'RUNNING':
		case 'ACTIVATED':
		case 'IN PROGRESS':
		case 'HOLD':
			bgColor = '#299BFF26';
			color = '#299BFF';
			break;

		default:
			bgColor = '#299BFF26';
			color = '#fff';
			break;
	}

	const chipProps: ChipProps = {
		label: status,
		sx: {
			'&.MuiChip-filled': {
				width: 'fit-content'
			},
			'&.MuiChip-filled, & .MuiChip-label': {
				margin: 0,
				fontFamily: 'Montserrat',
				fontWeight: 600,
				textTransform: 'uppercase'
			},
			// !important is given, becoz mui chip style is being overridden by other css files, remove !important once css files are removed
			bgcolor: `${bgColor} !important`,
			color: `${color} !important`
		},
		...props
	};

	return <Chip {...chipProps} />;
};

export default StatusChip;

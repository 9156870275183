import { TypesColor } from "types/theme";

const commonColor = {
  sidenav: {
    main: "#0A67F3",
    state: "#00B9FF",
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },

  transparent: {
    main: "rgba(0, 0, 0, 0)",
    // main: "transparent",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#00B9FF",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#0A67F3",
      state: "#00B9FF",
    },

    secondary: {
      main: "#343a40",
      state: "#adb5bd",
    },
    success: {
      main: "#ABE098",
      state: "#43CC14",
    },
    error: {
      main: "#F25555",
      state: "#F21818",
    },
    sidenav: {
      main: "#0A67F3",
      state: "#00B9FF",
    },
    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },
  },
  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#aecef7",
      text: "#095bc6",
    },

    success: {
      background: "#bce2be",
      text: "#00a556",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#FE2626",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },
};

export const colorsDark: TypesColor = {

  blue: {
    light: '#4F89D6',
    main: '#0052A3',
    dark: '#00377133',
    darker: '#001e3e'
  },

  transparent: {
    main: commonColor.transparent.main,
  },
  black: {
    light: commonColor.black.light,
    main: commonColor.black.main,
    focus: commonColor.black.focus,
  },

  grey: commonColor.grey,
  coloredShadows: commonColor.coloredShadows,
  inputBorderColor: commonColor.inputBorderColor,
  tabs: commonColor.tabs,
  sidenav: commonColor.sidenav,
  badgeColors: commonColor.badgeColors,

  background: {
    default: "#121212",
    sidenav: "#404040",
    sidenavDark: "#121212",
    card: "#292929",
    paper: "#121212",
  },

  white: {
    main: "#ffffff",
    button: "#ffffff",
    srch: "#000000",
    focus: "#00B9FF",
  },
  text: {
    main: "#E5E5E5",
    focus: "#00B9FF",
    dark: "#121212",
  },
  primary: {
    main: "#003771",
    focus: "#0079A6",
    dark: '#122950',
  },

  secondary: {
    main: "#00B9FF",
    focus: "#990F0F",
  },

  info: {
    main: "#00B9FF",
    focus: "#32990F",
  },

  success: {
    light: '#00d08d',
    main: "#00a556",
    focus: "#00B9FF",
    state: "#43CC14",
  },

  warning: {
    main: "#fd7b33",
    focus: "#fd7b33",
  },

  error: {
    main: "#FE2626",
    focus: "#F21818",
  },

  light: {
    main: "#8c8c8c",
    diff: "#BCBCBC",
    focus: "#00B9FF",
    unselected: "#8c8c8c",
  },

  dark: {
    main: "#8C8C8C",
    focus: "#00B9FF",
    unselected: "#8c8c8c",
    helpBorder: "#fff",
    background: "#121212",
    text: "#404040",
  },
  gradients: {
    primary: commonColor.gradients.primary,
    secondary: commonColor.gradients.secondary,
    success: commonColor.gradients.success,
    sidenav: commonColor.gradients.sidenav,
    error: commonColor.gradients.error,
    info: {
      main: "#0A68F3",
      state: "#00B9FF",
    },
    warning: {
      main: "#8ce0ff",
      state: "#8ce0ff",
    },
    light: {
      main: "#fdc4a4",
      state: "#f0ae89",
    },

    dark: {
      main: "#8c8c8c4d",
      state: "#2929294d",
    },
  },
};

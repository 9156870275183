import React from 'react';
import { Box, Grid, Stack, Typography, TypographyProps } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type WidgetCardContainerProps = {
	title: string | Array<string>;
	children: React.ReactNode;
};

type TitleTypographyProps = TypographyProps & {
	title: string;
};

const TitleTypography: React.FC<TitleTypographyProps> = ({ title, ...rest }) => (
	<Typography variant="h3" fontFamily="Montserrat" fontWeight="500" fontSize="16px" lineHeight="normal" {...rest}>
		{title}
	</Typography>
);

const WidgetCardContainer: React.FC<WidgetCardContainerProps> = ({ title, children }) => {
	return (
		<Box
			display="flex"
			flexGrow={1}
			height="100%"
		>
			<Stack p={2} bgcolor="blue.dark" borderRadius="5px" flexGrow={1}>
				{typeof title === 'string' ? (
					<TitleTypography title={title} mb={1.5} />
				) : (
					<Box display="inline-flex" flexDirection="row" mb={1}>
						{title.map((t, idx) => (
							<React.Fragment key={`${t}-${idx}`}>
								<TitleTypography title={t} />
								{idx < title.length - 1 && <ChevronRightIcon />}
							</React.Fragment>
						))}
					</Box>
				)}
				<Grid container spacing={1} flexGrow={1}>
					{children}
				</Grid>
			</Stack>
		</Box>
	);
};

export default WidgetCardContainer;

import { useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MoreHoriz } from '@mui/icons-material';

type MenuItemType =
	| {
			key: string;
			label: string;
			icon: JSX.Element;
			onClick: (event?: any) => void;
			disabled?: boolean;
			isExportButton?: false;
	  }
	| {
			key: string;
			label: string;
			icon: JSX.Element;
			onClick: () => Promise<unknown>;
			disabled?: boolean;
			isExportButton: true;
	  };

type MoreOptionDropdownProps = {
	menuItems: MenuItemType[];
	isDisabled?: boolean;
};

function ExportButton({ onExport, children }: { onExport: () => Promise<unknown>; children: string }) {
	const [exporting, setExporting] = useState(false);
	return (
		<LoadingButton
			size="small"
			className="exportButton"
			loading={exporting}
			loadingPosition="start"
			loadingIndicator="Exporting…"
			disabled={exporting}
			onClick={async () => {
				setExporting(true);
				await onExport();
				setExporting(false);
			}}
		>
			{children}
		</LoadingButton>
	);
}

const MoreOptionDropdown: React.FC<MoreOptionDropdownProps> = ({ menuItems, isDisabled = false }) => {
	const [moreEl, setMoreEl] = useState<null | HTMLElement>(null);

	const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreEl(event.currentTarget);
	};
	const handleMoreClose = () => {
		setMoreEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				id="more-button"
				aria-controls={!!moreEl ? 'more-menu' : undefined}
				aria-expanded={!!moreEl ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleMoreClick}
				sx={{
					padding: '4px 2px',
					borderRadius: 0,
					width: '42px',
					height: '45px',
					color: 'white.main'
					// display:
					//   tableType === "ML_ModelFeatureDrifts" ||
					//   tableType == "incident-details-ver" ||
					//   tableType == "add-schedules" ||
					//   tableType == "job-schedules-1"
					//     ? "none"
					//     : "block",
				}}
				disabled={isDisabled}
			>
				<MoreHoriz/>
			</IconButton>
			<Menu
				id="more-menu"
				MenuListProps={{
					'aria-labelledby': 'more-button'
				}}
				anchorEl={moreEl}
				open={!!moreEl}
				onClose={handleMoreClose}
				PaperProps={{
					style: {
						width: '13ch'
					}
				}}
			>
				{menuItems.map(({ key, label, icon, onClick, disabled, isExportButton }) => (
					<MenuItem key={key} onClick={onClick} disabled={disabled}>
						<ListItemIcon>{icon}</ListItemIcon>
						{isExportButton ? (
							<ExportButton onExport={onClick}>{label}</ExportButton>
						) : (
							<span className="dots_menu_text">{label}</span>
						)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default MoreOptionDropdown;

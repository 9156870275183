import React, { useEffect, useState, useRef } from 'react';
import {Button, ButtonGroup, Checkbox, FormControlLabel, Grid, MenuItem, Popover, Select, Stack} from '@mui/material';
import { powerBICapacityMetricsData } from '../../service/service';
import { powerBICapacityDetailData } from '../../service/service';
import { fetchSectors } from '../../service/service';
import { powerBICapacityStatus } from '../../service/service';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import UnderDevelopment from '../../assets/under-construction.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import '../PowerBIObservability/powerBIObs.scss';
import useStyles from '../jobs/jobs_styles';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { Link, useSearchParams } from 'react-router-dom';
import { getDecodedToken, getSector , powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import { ReactComponent as NotificationBellIcon } from '../../assets/notification.svg';

const CapacityObservability = () => {

	interface Row {
		capacityName: string,
		capacityOwner: string,
		capacityStatus: string,
		workspaceName: string,
		sector: string,
		workspaceOwner: string,
		itemKind: string,
		itemName: string,
		itemStatus: string,
		itemOwner: string,
		operation: string,
		CU_Percentage: string,
		duration: string,
		throttled: string,
		frozen: string,
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'capacityName',
				name: 'Capacity',
				formatter(props) {
					return <div title={props.row.capacityName} className='has-ellipsis'>{props.row.capacityName ? props.row.capacityName : '--'}</div>
				},
				width: 230,
				minWidth: 230
			},
			{
				key: 'capacityOwner',
				name: 'Capacity Owner',
				width: 190,
				minWidth: 190,
			},
			{
				key: 'capacityStatus',
				name: 'Capacity Status',
				width: 150,
				minWidth: 150,
				cellClass: 'center-text',
				formatter: ({ row }) => {
					let color = ''; let backgroundColor = '';
					if (row.capacityStatus === 'HEALTHY') { color = '#01C008'; backgroundColor = '#01C00826'; }
					if (row.capacityStatus === 'THROTTLED') { color = '#FE2626'; backgroundColor = '#FE262626'; }
					if (row.capacityStatus === 'FROZEN') { color = '#299BFF'; backgroundColor = '#299BFF26'; }
					return (
					  <span style={{ color, backgroundColor, borderRadius: '50px', padding: '2px 10px', width: '100px', display: 'inline-block', lineHeight: '20px', fontWeight: 'bold'}}>
						{row.capacityStatus}
					  </span>
					);
				}
			},
			{
				key: 'workspaceName',
				name: 'Workspace',
				formatter(props) {
					return <div title={props.row.workspaceName} className='has-ellipsis'>{props.row.workspaceName ? props.row.workspaceName : '--'}</div>
				},
				width: 230,
				minWidth: 230,
			},
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 230,
			},
			{
				key: 'workspaceOwner',
				name: 'Workspace Owner',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'itemKind',
				name: 'Item Kind',
				formatter(props) {
					return <div title={props.row.itemKind} className='has-ellipsis'>{props.row.itemKind ? props.row.itemKind : '--'}</div>
				},
				minWidth: 230,
			},
			{
				key: 'itemName',
				name: 'Item Name',
				formatter(props) {
					return <div title={props.row.itemName} className='has-ellipsis'>{props.row.itemName ? props.row.itemName : '--'}</div>
				},
				minWidth: 230,
			},
			{
				key: 'itemStatus',
				name: 'Item Status',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'itemOwner',
				name: 'Item Owner',
				width: 190,
				minWidth: 190
			},
			{
				key: 'operation',
				name: 'Operation',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'CU_Percentage',
				name: 'CU %',
				minWidth: 125,
				width: 125
			},
			{
				key: 'duration',
				name: 'Duration',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'throttled',
				name: 'Throttled',
				minWidth: 100,
				width: 100,
			},
			{
				key: 'frozen',
				name: 'Frozen',
				minWidth: 100,
				width: 100,
			}
		];
	}

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const classes = useStyles();
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [metricsData, setMetricsData] = useState<any>({
		healthyCount: 0,
		throttledCount: 0,
		frozenCount: 0,
		riskCount: 0,
		frozenRecoveredCount:  0,
		totalCount:  0
	});
	const [chartOption, setChartOption] = useState({});
	const [capacityStatusFilter, setCapacityStatusFilter] = useState<string | null>(null);

	const defaultColumnNames = [
		'Capacity',
		'Capacity Owner',
		'Capacity Status',
		'Workspace',
		'Sector',
		'Workspace Owner',
		'Item Kind',
		'Item Name',
		'Item Status',
		'Item Owner',
		'Operation',
		'CU %',
		'Duration',
		'Throttled',
		'Frozen',
	];

	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	console.log(userSector,'userSector');

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
		let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
            ? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
            : null;
		if(storedSectorFilter) setCheckedList(storedSectorFilter) 
		else setCheckedList([]);
		if(storedSectorFilter && (storedSectorFilter.length == sectorOptionsList.length)) setItemsChecked(true)
		else setItemsChecked(false)
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};
	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(checked);
		}
	};

	async function fetchSectorOptionsData() {
		// const sectorRes = await getSector('1', '100', '');
		const sectorRes = await fetchSectors();
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.data);
		}
	}

	const handleOkClick = (event: any) => {
		// Create a new list with "S&T" replaced by "SNT"
		const updatedCheckedList = checkedList.map((value: string) => {
			if (value.includes('S&T')) {
				return value.replace('S&T', 'SNT');
			}
			return value;
		});
		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
		} else {
			setCurrSectorFilter([]);
		}
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		localStorage.setItem('ml-summary-sector-filter', JSON.stringify(updatedCheckedList));
		fetchData();
		capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		//fetchCapacityData();
		handleClose(event);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		if(capacityStatusFilter){
			capacityDetailFunc(value, pageSize, sortingInfo, { capacityStatus: capacityStatusFilter });
		}
		else{
			capacityDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
		}
	};

	const handlepageSizeChange = (event: any) => {
		if(capacityStatusFilter){
			capacityDetailFunc('1', event.target.value, sortingInfo, { capacityStatus: capacityStatusFilter });
		}
		else{
			capacityDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
		}
		
	};
	const handleSorting = (sortingInfo: any) => {
		if(capacityStatusFilter){
			capacityDetailFunc(page, pageSize, sortingInfo, { capacityStatus: capacityStatusFilter });
		}
		else{
			capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		if(capacityStatusFilter){
			capacityDetailFunc(1, pageSize, sortingInfo, { capacityStatus: capacityStatusFilter });
		}
		else{
			capacityDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
		}
	};
	// const handleSearch = (searchDetails: any, columnKey: any) => {
	// 	// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	// };

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

async function capacityDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
	console.log(jobFilterInfo,"jobFilterInfo")
    // Reset page number if page size has changed
    if (pageSize !== size) {
        pageNumber = 1;
    }

    try {
        // Retrieve stored sector filter from localStorage
        let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
            ? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
            : null;

			setTableLoading(true);
        
        let response;
        
        // Check if a status is selected in jobFilterInfo
        if (jobFilterInfo?.capacityStatus) {
            // Call the powerBICapacityStatus API to get filtered data by status
			response = await powerBICapacityStatus(pageNumber.toString(), size.toString(),storedSectorFilter,jobFilterInfo?.capacityStatus,sortingInfo);
        } else {
            // Otherwise, call the general capacity detail API
            response = await powerBICapacityDetailData(pageNumber.toString(), size.toString(),storedSectorFilter,sortingInfo,jobFilterInfo?jobFilterInfo:null);
        }

        console.log("Fetched Capacity Data:", response);

        // Check if response contains the necessary data
        const capacityData = response?.data?.result || []; // Assuming 'result' contains the relevant data
console.info("capacityData",capacityData)
        // Transform the fetched data into the desired structure
        const incidentData = capacityData.map((item: any) => ({
            incidentId: item.CapacityId, // Using CapacityId as incidentId for uniqueness
            capacityName: item.CapacityName,
            capacityOwner: 'Manisha.M.Contractor@pepsico.com', // Update as needed
            capacityStatus: item.CapacityStatus,
            workspaceName: item.WorkspaceName,
            sector: item.Sector,
            workspaceOwner: 'Manisha.M.Contractor@pepsico.com', // Update as needed
            itemKind: item.ItemKind,
            itemName: item.ItemName,
            itemStatus: item.ItemStatus,
            itemOwner: 'Manisha.M.Contractor@pepsico.com', // Update as needed
            operation: item.Operation,
            CU_Percentage: item.CU_Percentage,
            duration: '-', // Update as needed
            throttled: item.Throttled,
            frozen: item.Frozen,
        }));

        setIncidentDetails(incidentData);

        // Update pagination data based on the response
        const paginationData = response.data.page;
        setPaginationData(paginationData); // Set pagination data directly from response

        // Update state for current page and size
		if(pageNumber > response.data.page.totalPages){
			setPage(response.data.page.currentPage);
		}
		else{
			setPage(pageNumber);
		}
        setPageSize(size);
        setSortingInfo(sortingInfo);
		setFilterData(response?.data?.filterOptions);

    } catch (error) {
        console.error("Error fetching capacity data:", error);
    } finally {
        setTableLoading(false);
    }
}

async function powerBICapacitySearchFunc(searchDetails: any, columnKey: any) {
	const res = await powerBICapacitySearch(searchDetails, columnKey,  parseFilter(filterInfo), checkedList, drillDownStatusfilterInfo);
	if (res.success) {
		setFilterSearchInfo(res.data);
	}
}
const handleSearch = (searchDetails: any, columnKey: any) => {
	console.log(searchDetails, columnKey)
	if(searchDetails?.length >= 3)
		powerBICapacitySearchFunc(searchDetails, columnKey);
};
	const handleCapacityClick = (status: string) => {
		setCapacityStatusFilter(status); 
		// Fetch data and filter by status
		capacityDetailFunc('1', pageSize, sortingInfo, { capacityStatus: status });
	};
	
	
	async function fetchData() {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
			: null;
			setLoading(true);
			const response  = await powerBICapacityMetricsData(storedSectorFilter);
	
			// Transform the data to match the expected structure
			if (response && response.data) {
				const transformedData = {
					healthyCount: response.data.data.CapacitiesHealthy || 0,
					throttledCount: response.data.data.CapacitiesThrottled || 0,
					frozenCount: response.data.data.CapacitiesFrozen || 0,
					riskCount: response.data.data.CapacitiesAtRiskOfGettingThrottled || 0,
					frozenRecoveredCount: response.data.data.CapacitiesOutOfFrozen || 0,
					totalCount: response.data.data.latestRecords.length || 0
				};
				setMetricsData(transformedData);
			} 
			else {
				console.error("No data returned from API");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		finally {
			setLoading(false);
		}
	};

	const fetchChartData = async () => {
		try {
		  // Simulate fetching chart data
		//   const fetchedData = await fetchDataFromAPI(); // Replace with actual API call
		const fetchedData = {
			xAxisData: ['1 Jan 2024', '3 Jan 2024', '6 Jan 2024', '9 Jan 2024', '12 Jan 2024', '15 Jan 2024', '18 Jan 2024', '21 Jan 2024', '24 Jan 2024', '27 Jan 2024', '30 Jan 2024', '5 Feb 2024'],
			databricksData: [20, 40, 60, 80, 90, 50, 60, 30, 40, 20, 20, 50],  
			api_management: [50, 30, 80, 60, 40, 70, 50, 40, 30, 20, 10, 5],  
			microsoft_automation: [30, 50, 40, 70, 80, 60, 40, 30, 40, 20, 10, 5],  
			microsoft_activedirectory: [20, 40, 60, 80, 90, 60, 50, 40, 30, 20, 10, 5],  
		  };
	
		  if (fetchedData) {
			setChartOption({
			  // Set your chart option here
			  tooltip: {
				trigger: 'axis',
				backgroundColor: '#003771',
				textStyle: {
				  fontFamily: 'Montserrat',
				  fontSize: 14,
				  color: '#A7ADCB'
				},
				axisPointer: {
				  type: 'shadow'
				},
				formatter: (params: any) => {
				  let tooltipContent = `${params[0].axisValue}<br/>`;
				  params.forEach((item: any) => {
					tooltipContent += `${item.seriesName}: ${item.value}%<br/>`;
				  });
				  return tooltipContent;
				}
			  },
			  legend: {
				data: ['Databricks', 'api management', 'microsoft.automation','microsoft.activedirectory'],
				bottom: '0%',
				textStyle: {
				  color: '#f5f5f5'
				},
				itemGap: 20, 
			  },
			  xAxis: {
				type: 'category',
				data: fetchedData.xAxisData,
				axisLine: {
				  lineStyle: {
					color: '#A7ADCB'
				  }
				},
				axisLabel: {
				  color: '#F5F5F5',
				  fontFamily: 'Montserrat',
				  fontSize: 11,
				  rotate: 30
				}
			  },
			  yAxis: {
				type: 'value',
				min: 0,
				max: 100,
				interval: 50,
				axisLabel: {
				  formatter: '{value}%',
				  color: '#F5F5F5'
				},
				splitLine: {
				  lineStyle: {
					color: '#094a93'
				  }
				}
			  },
			  series: [
				{
				  name: 'Databricks',
				  type: 'bar',
				  data: fetchedData.databricksData,
				  barWidth: '30%',
				  itemStyle: {
					color: '#299bff' 
				  }
				},
				{
				  name: 'api management',
				  type: 'bar',
				  data: fetchedData.api_management,
				  barWidth: '30%',
				  itemStyle: {
					// color: '#01c008' 
					color: '#299bff'  
				  }
				},
				{
				  name: 'microsoft.automation',
				  type: 'bar',
				  data: fetchedData.microsoft_automation,
				  barWidth: '30%',
				  itemStyle: {
					color: '#fe2626' 
				  }
				},
				{
					name: 'microsoft.activedirectory',
					type: 'bar',
					data: fetchedData.microsoft_activedirectory,
					barWidth: '30%',
					itemStyle: {
					  color: '#00d08d' 
					}
				  }
			  ],
			  grid: {
				left: '6%',
				right: '4%',
				bottom: '12%',
				top: '12%',
				containLabel: true
			  }
			  
			});
		  } else {
			console.warn('No data returned from API');
			setChartOption({});
		  }
		} catch (error) {
		  console.error('Error fetching chart data:', error);
		  setChartOption({});
		}
	  };

	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}
	

    useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
		if (userSector === 'all') fetchSectorOptionsData();
		// let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
		// 	: null;
		// 	console.log("Fetched Data1:", storedSectorFilter );
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
		if(capacityStatusFilter){
			capacityDetailFunc(page, pageSize, sortingInfo, { capacityStatus: capacityStatusFilter });
		}
		else{
			capacityDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		}
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/capacityObservability/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
		} 
	}
	    fetchData();
		fetchChartData();
    }, []);


	return (
		<div className="dashboard-panel-powerBIObs job-schedules-wrap">
			<div className="powerBI-notification-icon">
				<NotificationBellIcon />
			</div>
					<div className="powerBI-sector-filter-cost powerBI-sector-filter">
						<Stack className="sector-filter-dataestate">
							<><span className='sector-cta'>
							   <span className='sector-label'>All Sectors</span> 
							   <span>
								{userSector === 'all' ? (
									<FilterIcon onClick={filterClick} />
								) : (
								<div className="sector-filter-text-ml">Sector: {getDecodedToken()?.sector?.name}</div>
								)}
								<Popover
								open={filterMenu !== null}
				                onClose={handleClose}
				                className="filterContainer-powerBI"
				                anchorReference="anchorPosition"
				                anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
								>
									<div className="text-filter-row">
										<div className="selectAll-sec-powerBI">
											<MenuItem className="selectAll">
											<FormControlLabel
											className={classes.checkbox}
								            label="All"
								            control={
											<Checkbox
											color="success"
										    value="all"
										    checked={itemsChecked}
										    onChange={(e) => {
											selectAllCheck(e);
										}}
										/>
									}
									/>
									        </MenuItem>
											<div className="checkbox-sec">
												{sectorOptionsList && sectorOptionsList.map((filter: any) => (
													<MenuItem key={filter}>
														<FormControlLabel
														className={classes.checkbox}
											            label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
											            control={
														<Checkbox
														color="success"
													    value={filter}
										                checked={
															(filter === true && checkedList.includes('true')) ||
														    (filter === false && checkedList.includes('false')) ||
														    checkedList.includes(filter)
														}
														onChange={(e) => {
														handleChange(e);
													}}
												/>
											}
											/>
											</MenuItem>
										))}
										    </div>
										</div>
										<div className="buttonSec">
											<Button variant="contained" className={classes.cancelBtnsectorPBI} onClick={handleClose}>
												cancel
											</Button>
											<Button variant="contained" className={classes.okBtnsectorPBI} onClick={handleOkClick}>
							                    ok
						                    </Button>
											</div>
									</div>
							</Popover>
							   </span>
							  </span></>
						</Stack>
			        </div>
					<div className="page-table jobs-tabledata page-table-ibp-monitor">
						<div className="ibp-dashboard-wrapper">
							<Grid container spacing={0} className="headerIPB">
								<Grid item xs={12} className="headerIPB-title subheaderIPB-title no-ml custom-grid-data-powerBIObs">
									<Grid container spacing={1}>
										{!loading ? (
											<Grid item xs={3} className="powerBIObs-metrics-title" onClick={() => handleCapacityClick('HEALTHY')}>
											<div className="monitor-head">Capacities Healthy</div>
										    <div className="powerBIObs-subheader">{metricsData.healthyCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										):
										(
											<Grid item xs={3} className="powerBIObs-metrics-title">
											<div className="monitor-head">Capacities Healthy</div>
										    <div className="loading">Loading...</div>
									    </Grid>
										)
									}
									{!loading ? (
											<Grid item xs={3} className="powerBIObs-metrics-title"  onClick={() => handleCapacityClick('THROTTLED')}>
											<div className="monitor-head">Capacities Throttled</div>
										    <div className="powerBIObs-subheader">{metricsData.throttledCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										):
										(
											<Grid item xs={3} className="powerBIObs-metrics-title">
											<div className="monitor-head">Capacities Throttled</div>
										    <div className="loading">Loading...</div>
									    </Grid>
										)
									}
									{!loading ? (
											<Grid item xs={3} className="powerBIObs-metrics-title"  onClick={() => handleCapacityClick('FROZEN')}>
											<div className="monitor-head">Capacities Frozen</div>
										    <div className="powerBIObs-subheader">{metricsData.frozenCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										):
										(
											<Grid item xs={3} className="powerBIObs-metrics-title">
											<div className="monitor-head">Capacities Frozen</div>
										    <div className="loading">Loading...</div>
									    </Grid>
										)
									}
									{!loading ? (
											<Grid item xs={3} className="powerBIObs-metrics-title"  onClick={() => handleCapacityClick('RISK')}>
											<div className="monitor-head">Capacities at risk of Getting Throttled</div>
										    <div className="powerBIObs-subheader">{metricsData.riskCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										):
										(
											<Grid item xs={3} className="powerBIObs-metrics-title" >
											<div className="monitor-head">Capacities at risk of Getting Throttled</div>
										    <div className="loading">Loading...</div>
									    </Grid>
										)
									}
									{!loading ? (
											<Grid item xs={3} className="powerBIObs-metrics-title"  onClick={() => handleCapacityClick('FROZEN 24')}>
											<div className="monitor-head">Capacities out of Frozen (In Last 24 hours)</div>
										    <div className="powerBIObs-subheader">{metricsData.frozenRecoveredCount}<span className="powerBIObs-subheader-span">/{metricsData.totalCount}</span></div>
									    </Grid>
										):
										(
											<Grid item xs={3} className="powerBIObs-metrics-title">
											<div className="monitor-head">Capacities out of Frozen (In Last 24 hours)</div>
										    <div className="loading">Loading...</div>
									    </Grid>
										)
									}
								    </Grid>	
							    </Grid>
							</Grid>	
							<div className="capacityDetails-title">
							Details
						    </div>
						    <div className="capacityDetails_tabledata">
								{!tableLoading ? 
								(
								<>
								<CustomizedDataGrid 
								tableColumns={getColumns()}
								tableData={incidentDetails}
								tableType="incident-details-ver"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								searchDataValues={searchDetails}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={incidentDetails.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={false}
								isDisabled={false}
								isFetching={tableLoading}
								/>
								</>
								)	:
								(
									<div className="details-loader">
											<Loader/>
									</div>
							)
							}
						    </div>
						
						<Grid container gap={1} mt={2}>
							<Grid item xs={12} md={12} p={2} className="powerBIObs-metrics-title m-0 chart-widget-query mheight">
								CU% Utilization Over Time
								<ReactECharts option={chartOption} />
								<Grid container item sx={{ position: 'absolute', top: 10, right: 50, zIndex: 1 }} spacing={1} alignItems="center" justifyContent="flex-end">
										<Grid item>
											<Select variant="outlined" defaultValue="Jan 2024" sx={{ fontSize: '10px', height: '30px' }}>
												<MenuItem value="Jan 2024">Jan 2024</MenuItem>
											</Select>
										</Grid>
										<Grid item>
											<Select variant="outlined" defaultValue="Daily" sx={{ fontSize: '10px', height: '30px'}}>
												 <MenuItem value="Daily">Daily</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item>
											<Select variant="outlined" defaultValue="Capacity" sx={{ fontSize: '10px', height: '30px' }}>
												<MenuItem value="Capacity">Capacity</MenuItem>
                                            </Select>
                                        </Grid>
                                        {/* <Grid item>
											<ButtonGroup>
												<Button variant="contained" sx={{ fontSize: '10px', height: '30px'}}>Linear</Button>
												<Button variant="contained" sx={{ fontSize: '10px', height: '30px'}}>Logarithmic</Button>
											</ButtonGroup>
										</Grid> */}
                                </Grid>
							</Grid>	
						</Grid>
					</div>	
				</div>	
				{/* // <div style={{ position: 'absolute', left: '45%', top: '50%', color: 'darkgray' }}>
				// 	<Grid item xs={12} sm={4} className="full-width no-padl">
				// 	<div className="panel-box-summary mb-10">
				// 		<div className='tooltip-panel'>
				// 			<h2 className="panel-box--title">Capacities Healthy</h2>
				// 	    </div>
					
				//     </div>
				// 	</Grid>
				// 	<img src={UnderDevelopment} />
				// 	<p style={{ position: 'relative', right: '25%', margin: '0' }}>Dashboard under development!</p>
				// </div> */}
		</div>
	);
};


export default CapacityObservability;

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Collapse, Dialog, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, MenuProps, Select, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { DataPipelineModal } from './modal-component';
import FilterIcon from '../../assets/filter-projects.svg'
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg'
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg'
import ExpandMoreIcon from '../../assets/down-arrow.svg'
import { ReactComponent as ViewIcon } from '../../assets/view-detail-img.svg'
import ExportIcon from '../../assets/export-img.svg'
import CalenderDateIcon from '../../assets/calender-date.svg'
import NoPrgramFound from '../../assets/no-program-found.svg'
import useStyles from './data-pipeline-styles';
import '../DataPipeline/dataPipeline.scss';
import { getJobPerformanceChartData, getMappedJobScheduleList, getProgramById, getProjectListByProgram, getDpProjectKpi, getScheduleList, updateReorderSchedule } from '../../service/service';
import { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import moment from 'moment';
import { Link,  useParams, useNavigate } from 'react-router-dom';
import { ReactComponent as CalendarIcon} from '../../assets/calendar-outline_white.svg';
import { ReactComponent as CalendarGreyIcon} from '../../assets/icon-calendar-grey.svg';
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import { ReactComponent as InfoGreyIcon } from '../../assets/icon-grey-info.svg';
import { ReactComponent as ViewHighlightIcon } from '../../assets/icon-view-highlight.svg'
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef, RowSelectionMode, RowSelectionOptions } from 'ag-grid-community';
import textWithTooltip from './textWithTooltip';
import { ReactComponent as ReorderFourIcon } from '../../assets/reorder-four.svg'

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  
const ProgramDetails = () => {
    let agGridRef = useRef<any>();
    const gridRef = useRef<AgGridReact<any>>(null);
     // Row Data: The data to be displayed.
 const [rowData, setRowData] = useState([]);
 const rowSelection = useMemo<
    RowSelectionOptions | "single" | "multiple"
    >(() => {
    return {
    mode: "multiRow",
    };
    }, []);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState<ColDef[]>([
    {
        field: "Job_Name",
        headerName: "Schedules",
        cellRenderer: (props: any) => {
            return <div title={props.data.Job_Name} className={`has-ellipsis ${props.data.successor >0? 'has-successor' : ''}`}>{props.data.Job_Name ? props.data.Job_Name : '--'}</div>
        },
        minWidth: 250,
        width: 250
     },
    {
        field: "Platform_Id",
        headerName: "Platform",
        cellRenderer: (props: any) => {
            return <div>{props.data.Platform_Id == 1 ? 'Teradata' : ''}</div>
        },
        width: 150 
    },
    {
        field: "Start_Time",
        headerName: "Start Time (CST)",
        cellRenderer(props: any) {
        const startDate = props.data.Start_Time;
        return <div style={{textTransform: 'capitalize'}}>{startDate ? getDays(props.data.Days) +', ' + (startDate < 10 ? `000${startDate}`.replace(/(.{2})$/,':$1') : startDate < 100 ? `00${startDate}`.replace(/(.{2})$/,':$1') : startDate.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
        },
        width: 170 
    },
    {
        field: "Normal_Completion_Time",
        headerName: "Normal Completion Time (CST)",
        cellRenderer(props: any) {
            const normalTime = props.data.Normal_Completion_Time;
            return <div style={{textTransform: 'capitalize'}}>{normalTime ? (normalTime < 10 ? `000${normalTime}`.replace(/(.{2})$/,':$1') : normalTime < 100 ? `00${normalTime}`.replace(/(.{2})$/,':$1') : normalTime.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
        },
        minWidth: 220
     },
    {
        field: "Scheduler_Name",
        headerName: "Scheduler Name",
        minWidth: 150
     },
     {
         field: "Actions",
         rowDrag: true,
         cellRenderer(props: any) {
            return <div>
                <Button className='action-cta-link action-cta-link-delete' onClick={() =>handleTableAction(props.data, 'delete')} title='Click to Delete'>
                <DeleteIcon className='icon-actions icon-actions-delete' />
                </Button>
            </div>
         },
         width: 100
     }
  ]);
    interface Row {
        id: number;
        Job_Name: string;
        Platform_Id: number;
        Start_Time: string;
        Normal_Completion_Time: number;
        slaStatus: number;
        jobStatus: string;
        estimatedTime: string;
        Days: number;
        actualStartTime: string;
        actualEndTime: string;
        scheduleStartTime: number;
        Date: string;
        successor: number;
    };
    const [controller] = usePepwiseAuthController();
    const { role } = controller;
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [isOrderChanged, setIsOrderChanged] = useState(false);
    const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorElDetails);
    const handleThreeDotsClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        event.preventDefault()
        event.stopPropagation();
        setAccordionActiveId(index);
      setAnchorEl(event.currentTarget);
    };
    const handleThreeDotsClose = (event: any) => {
      event.preventDefault()
      event.stopPropagation();
      setAnchorEl(null);
      setAccordionActiveId(null);
    };

    const { id, name } = useParams();

    const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation();
      setAnchorElDetails(event.currentTarget);
    };
    const handleDetailsThreeDotsClose = (event: any) => {
      event.preventDefault()
      event.stopPropagation();
      setAnchorElDetails(null);
    };

    const onHandleReorderSchedules = () => {
        const temprowData: any = rowData;
        const reorderedRowData: any = [];
        temprowData.filter((d: any, i: number) => {
            reorderedRowData.push({Project_Schedule_ID: d.Project_Schedule_ID, Schedule_Index: i})
        });
        updateReorderedScheduleData(reorderedRowData)
    }

    const updateReorderedScheduleData = async(reorderedRowData: any) => {
        await updateReorderSchedule(reorderedRowData)
        setIsOrderChanged(false);
    }

    const onHandleDragCancel = (isUpdate: boolean) => {
        setIsOrderChanged(isUpdate)
        const temprowData = rowData;
        setSelectedRows([]);
        // Deselect all rows        
        agGridRef.current && agGridRef.current.deselectAll();
        setTableLoading(true);
        setTimeout(() => {
            setRowData(jobSchedulesList);
            setTableLoading(false);
        }, 300);
    }

    const onHandleRowDragEnd = (event: any) => {
        agGridRef.current = event.api;
        // Deselect all rows        
        agGridRef.current && agGridRef.current.deselectAll();
        const updatedRowData: any = [...rowData];
        updatedRowData.map((data: any) => {
            if (data.Project_Schedule_ID == event.node.data.Project_Schedule_ID) {
                data['reorderIndex'] = event.overIndex;
            }
        })
        const fromIndex = event.node.id;
        const toIndex = event.node.rowIndex;
        const [movedRow] = updatedRowData.splice(fromIndex, 1)
        updatedRowData.splice(toIndex, 0, movedRow);

        setRowData(updatedRowData);
        setSelectedRows([]);
        setIsOrderChanged(true)
    }

    const onGridReady = (params: any) => {
        agGridRef.current = params.api;
    };

    const onSelectionChangeEvent = (event: any) => {
        agGridRef.current = event.api;
        setSelectedRows(event.api.getSelectedRows());
        console.log(agGridRef.current.getSelectedRows())
    }

    const classes = useStyles();

	const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD HH:mm'));
	const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm'));
    const [projectList, setProjectList] = useState<any>([])
    const [openModal, setOpenModal] = useState<any>({isOpen: false, message: ''})
    const [programDetail, setProgramDetail] = useState<any>({})
    const [loading, setLoading] = useState(false);
    const [projectId, setProjectId] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [jobSchedulesList, setjobSchedulesList] = useState([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');
	const [pageSize, setPageSize] = useState('100');
    const defaultColumnNames = ['Job','Platform', 'SLA Status', 'Job Status', 'Act. Start Time (CST)', 'Act. End Time (CST)', 'Schedule Start Time (CST)', 'Normal Completion Time (CST)', 'Estimated Completion (CST)', 'Date', 'Actions'];
    const [expanded, setExpanded] = useState<any>(false);
    const { prId, prName } = useParams();
    const [nameState, setNameState] = useState(prName);
    const [toast, setToast] = useState<any>({
        open: false,
        message: ''
    });
    
    const navigate = useNavigate();

	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);

    const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
			case 'YES':
			case 'Completed':
				return <Chip label={status} color="success" />;
			case 'SLA Met':
				return <Chip label={status} style={{ backgroundColor: 'rgba(41, 155, 255, 0.15)', color: 'rgba(41, 155, 255, 1)' }} />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
			case 'NO':
				return <Chip label={status} color="secondary" />;
			case 'SLA Missed':
				return <Chip label={status} style={{ backgroundColor: 'rgba(253, 120, 59, 0.15)', color: 'rgba(253, 120, 59, 1)' }} />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'N/A':
            case 'Not Run':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#A9ADCB' }} />;
			case 'Scheduled':
				return <Chip label={status} style={{ backgroundColor: 'rgba(255, 162, 0, 0.2)', color: 'rgba(255, 162, 0, 1)' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
            case '--':
                return '--';
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};


    function getColumns(): Array<Column<Row>> {
        return [
            {
                key: 'Job_Name',
                name: 'Job',
                formatter(props) {
					return <div title={props.row.Job_Name} className={`has-ellipsis ${props.row.successor >0? 'has-successor' : ''}`}>{props.row.Job_Name ? props.row.Job_Name : '--'}</div>
				},
                minWidth: 170
            },
            {
                key: 'Platform_Id',
                name: 'Platform',
                formatter(props) {
					return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.Platform_Id == 1 ? 'Teradata' : ''}</div>
				},
                minWidth: 110
            },
    
            {
                key: 'slaStatus',
                name: 'SLA Status',
                formatter(props) {
                    const status = props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'Not Run';
					return renderStatus(status);
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.slaStatus == 1 ? 'Met' : props.row.slaStatus == 0 ? 'Not Met' : 'NA'}</div>
				},
                minWidth: 120
            },
            
            {
                key: 'Normal_Completion_Time',
                name: 'Normal Completion Time (CST)',
                formatter(props) {
					return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.Normal_Completion_Time ? getDays(props.row.Days) +', ' + (props.row.Normal_Completion_Time < 10 ? `000${props.row.Normal_Completion_Time}`.replace(/(.{2})$/,':$1') : props.row.Normal_Completion_Time < 100 ? `00${props.row.Normal_Completion_Time}`.replace(/(.{2})$/,':$1') : props.row.Normal_Completion_Time.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
				},
                minWidth: 150
            },
            {
                key: 'actualStartTime',
                name: 'Act. Start Time (CST)',
                formatter(props) {
					const startDate = props.row.actualStartTime;
					// if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate && startDate != null ? moment(props.row.actualStartTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss')  : '--'} </span>
					// }
					// return '';
				},
                minWidth: 150
            },
            {
                key: 'actualEndTime',
                name: 'Act. End Time (CST)',
                formatter(props) {
					const startDate = props.row.actualEndTime;
					// if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ? moment(props.row.actualEndTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss') : '--'} </span>
					// }
					// return '';
				},
                minWidth: 150
            },
            {
                key: 'scheduleStartTime',
                name: 'Schedule Start Time (CST)',
                formatter(props) {
					return <div className={`${props.row.successor >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.scheduleStartTime ? getDays(props.row.Days) + ', ' +(props.row.scheduleStartTime < 10 ? `000${props.row.scheduleStartTime}`.replace(/(.{2})$/,':$1') : props.row.scheduleStartTime < 100 ? `00${props.row.scheduleStartTime}`.replace(/(.{2})$/,':$1') : props.row.scheduleStartTime.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
				},
                minWidth: 150
            },
            {
                key: 'jobStatus',
                name: 'Job Status',
                formatter(props) {
                    const status = props.row.slaStatus == 2 ?'Not Run' : props.row.jobStatus
					return renderStatus(status);
					// return <div className={`${props.row.successor >0? 'has-successor' : ''}`}>{props.row.jobStatus ? props.row.jobStatus : '--'}</div>
				},
                minWidth: 120
            },
            {
                key: 'estimatedTime',
                name: 'Estimated Completion (CST)',
                formatter(props) {
					const startDate = props.row.estimatedTime;
					if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ?  moment(props.row.estimatedTime).tz('America/New_York').format('MM-DD-YY HH:mm:ss') : '--'}  </span>
					}
					return '';
				},
                minWidth: 150
            },
            {
                key: 'Date',
                name: 'Date',
                formatter(props) {
					const startDate = props.row.Date;
					if (startDate) {
						return <span className={`${props.row.successor >0? 'has-successor' : ''}`}> {startDate ? moment(startDate).utc().format('MM-DD-YY') : '--'} </span>
					}
					return '';
				},
                minWidth: 100
            },
            {
                key: 'action',
                name: 'Actions',
                minWidth: 100,
                resizable: false,
                formatter: (props: any) => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>                      
                      <Button className='action-cta-link' onClick={() =>handleTableAction(props.row, 'delete')} title='Click to Delete'>
                        <DeleteIcon className='icon-actions' />
                      </Button>                      
                    </div>
                  ),
            },
        ];
	}

    const handleBulkDeleteAction = () => {
        setOpenModal({
            isOpen: true,
            type: 'Schedule',
            action: 'DELETE',
            from: 'delete',
            data: selectedRows
        })
    }

    const handleTableAction = (props: any, type: string) => {
        if (type == 'delete') {
            setOpenModal({
                isOpen: true,
                type: 'Schedule',
                action: 'DELETE',
                from: 'delete',
                data: [props]
            })
        }
    }
    
    
    const getDays = (day: number) => {
        let days;
        if (day == 0) {
            days = 'Sun'
        } else if (day == 1) {
            days = 'Mon'
        } else if (day == 2) {
            days = 'Tue'
        } else if (day == 3) {
            days = 'Wed'
        } else if (day == 4) {
            days = 'Thur'
        } else if (day == 5) {
            days = 'Fri'
        } else if (day == 6) {
            days = 'Sat'
        }
        return days;
    } 

    
	const jobPerformanceBarChartData = async () => {
        setGrafanaChartLoading(true)
        // let result = await getJobPerformanceChartData(id)
		// 	if (result) {
				const sd = moment('2024-08-15');
				const ed = moment('2024-08-19');
				const duration = moment.duration(ed.diff(sd));
				const hours = duration.asHours();
                let perfByDay = false;
				if (hours >= 48) {
                    perfByDay = true;
					setPerformanceByDay(true)
				}
				let option = {};
				let xAxisArray:any =[];
				let sla_met:any =[];
				let sla_failed:any=[];
				let sla_total:any=[];
				let data: any = []
				if (false) {
					data =  [
						{
							"00:00": {
								"sla_met": 0,
								"sla_missed": 3,
								"sla_pending": 0
							}
						},
						{
							"01:00": {
								"sla_met": 0,
								"sla_missed": 1,
								"sla_pending": 0
							}
						},
						{
							"02:00": {
								"sla_met": 1,
								"sla_missed": 2,
								"sla_pending": 0
							}
						},
						{
							"03:00": {
								"sla_met": 1,
								"sla_missed": 1,
								"sla_pending": 0
							}
						},
						{
							"04:00": {
								"sla_met": 1,
								"sla_missed": 2,
								"sla_pending": 0
							}
						},
						{
							"05:00": {
								"sla_met": 2,
								"sla_missed": 4,
								"sla_pending": 0
							}
						},
						{
							"06:00": {
								"sla_met": 1,
								"sla_missed": 1,
								"sla_pending": 0
							}
						},
						{
							"07:00": {
								"sla_met": 0,
								"sla_missed": 2,
								"sla_pending": 1
							}
						},
						{
							"08:00": {
								"sla_met": 0,
								"sla_missed": 3,
								"sla_pending": 0
							}
						},
						{
							"09:00": {
								"sla_met": 0,
								"sla_missed": 0,
								"sla_pending": 0
							}
						},
						{
							"10:00": {
								"sla_met": 0,
								"sla_missed": 3,
								"sla_pending": 0
							}
						},
						{
							"11:00": {
								"sla_met": 0,
								"sla_missed": 0,
								"sla_pending": 0
							}
						},
						{
							"12:00": {
								"sla_met": 0,
								"sla_missed": 1,
								"sla_pending": 0
							}
						},
						{
							"13:00": {
								"sla_met": 0,
								"sla_missed": 2,
								"sla_pending": 0
							}
						},
						{
							"14:00": {
								"sla_met": 1,
								"sla_missed": 1,
								"sla_pending": 0
							}
						},
						{
							"15:00": {
								"sla_met": 0,
								"sla_missed": 3,
								"sla_pending": 0
							}
						},
						{
							"16:00": {
								"sla_met": 0,
								"sla_missed": 3,
								"sla_pending": 0
							}
						},
						{
							"17:00": {
								"sla_met": 0,
								"sla_missed": 2,
								"sla_pending": 0
							}
						},
						{
							"18:00": {
								"sla_met": 0,
								"sla_missed": 0,
								"sla_pending": 0
							}
						},
						{
							"19:00": {
								"sla_met": 0,
								"sla_missed": 1,
								"sla_pending": 0
							}
						},
						{
							"20:00": {
								"sla_met": 0,
								"sla_missed": 0,
								"sla_pending": 0
							}
						},
						{
							"21:00": {
								"sla_met": 0,
								"sla_missed": 0,
								"sla_pending": 0
							}
						},
						{
							"22:00": {
								"sla_met": 0,
								"sla_missed": 3,
								"sla_pending": 0
							}
						},
						{
							"23:00": {
								"sla_met": 0,
								"sla_missed": 37,
								"sla_pending": 0
							}
						}
					]
				} else {
					data = [
						{
					'2024-05-30': {
						'sla_met': 0,
						'sla_missed': 752,
						'sla_pending': 0,
					}
					},
						{
					'2024-05-31': {
						'sla_met': 0,
						'sla_missed': 242,
						'sla_pending': 0,
						}  
					},
					{
						'2024-06-01': {
							'sla_met': 0,
							'sla_missed': 482,
							'sla_pending': 0,
						}  
					},
					{
						'2024-06-02': {
							'sla_met': 0,
							'sla_missed': 752,
							'sla_pending': 0,
						} 
					},
					{ 
						'2024-06-03': {
						'sla_met': 0,
						'sla_missed': 242,
						'sla_pending': 0,
						} 
					},
					{ 
						'2024-06-04': {
							'sla_met': 0,
							'sla_missed': 482,
							'sla_pending': 0,
						} 
					},
					{
					'2024-06-05': {
						'sla_met': 0,
						'sla_missed': 752,
						'sla_pending': 0 
						} 
					},
					{
					'2024-06-06': {
						'sla_met': 0,
						'sla_missed': 242,
						'sla_pending': 0 
						} 
					}
				];
				}
				data.map((res:any,i:number)=>{
					xAxisArray.push(Object.keys(res)[0])
                    sla_met.push(res[xAxisArray[i]].sla_met)
                    sla_total.push(res[xAxisArray[i]].sla_pending)
					sla_failed.push(res[xAxisArray[i]].sla_missed)
				})
				option = {
                    title: {
                        text: 'Job Performance',
                        textStyle: {
                            color: '#fff',
                            fontWeight: 600,
                            fontSize: '16px'
                        },
                        top: '15px',
                        left: '15px'
                    },
					backgroundColor: '#053367',
					grid: {
						top: '20%',
						left: '5%',
						right: '2%',
						bottom: '30%'
					},
					xAxis: {
						type: 'category',
						data: xAxisArray,
						axisLabel: {
							color: '#fff'
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							color: '#fff'
						}
					},
					series: [
						{
							silent: true,
							name: 'SLA MET',
							type: 'bar',
							stack: 'total',
							data: sla_met,
							itemStyle: {
								color: 'rgba(41, 155, 255, 1)'
							}
						},
						{
							silent: true,
							name: 'SLA MISSED',
							type: 'bar',
							stack: 'total',
							data: sla_failed,
							itemStyle: {
								color: '#ff7f00'
							}
						},
						{
							silent: true,
							name: 'N/A',
							type: 'bar',
							stack: 'total',
							data: sla_total,
							itemStyle: {
								color: '#A9ADCB'
							}
						}
					],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						show: true,
						left: '35px',
						bottom: '15px',
						data: ['SLA MET', 'SLA MISSED', 'N/A'],
						textStyle: {
							color: '#fff'
						}
					},
				};
				setGrafanaChartOptions(option);
				setGrafanaChartLoading(false);
			// 	}
		}

    useEffect(() => {
        const activeId = document.getElementById('root');
        if (window.location.pathname.includes('/data-pipeline/programs')) {
            if (activeId) {
                document.head.classList.add('theme-bg-blue');
                document.body.classList.add('theme-bg-blue');
            }
        }
       if (!openModal.isOpen) {
        getProgramDetails()
        getProjectsByProgram()
        // if (openModal.message) {
        //     setToast(true)
        // }
        console.log("TOASTTT", openModal)
       } 
       
      
	}, [openModal]);

    const onOpenProjectAccordion = (panel: any, projectId: string, jobCount: number) => (event: any, isExpanded: any) => {
        event.preventDefault()
        event.stopPropagation();
        setProjectId(projectId)
        setExpanded(isExpanded ? panel : false);
        setIsOrderChanged(false)
    };

    const getProjectDetails = (e: any, projectId: string, jobCount: any) => {
        
        e.preventDefault()
        e.stopPropagation();
        if (jobCount) {
            projectMappedJobScheduleFunc(projectId, '1', '100');
        }
    }

    const passInfoToParent = (data: any) => {
        projectMappedJobScheduleFunc(projectId, '1', '100');
    }

    const getProgramDetails = async () => {
        setLoading(true)
        let result = await getProgramById(id)
        setLoading(false)
        setProgramDetail(result.data?.programData ? result.data.programData[0] : '')
        // if (window.location.pathname.split('/')[4] !== programDetail?.program_Name) {
        //     window.location.href = `/data-pipeline/programs/${programDetail.Program_ID}/${programDetail.program_Name}`
        // }
        jobPerformanceBarChartData();
        navigate(`/data-pipeline/programs/${id}/${encodeURIComponent(result.data.programData[0].program_Name)}`);
    }

    const getProjectsByProgram =  async() => {
        setLoading(true)
        let result = await getProjectListByProgram(id, 1, 100)
        let kpiCountData = await getDpProjectKpi(id)
        let reqData: any = []
        console.log("HERERRERERE", kpiCountData)
        if (result.data.projectList.length > 0) {
            result.data.projectList.map((data: any, index: number) => {
                let kpiCount = kpiCountData.data.length ? kpiCountData.data.filter((obj: any) => obj.Project_Id === data.Project_Id) : []
                if (kpiCount.length) {
                    reqData.push({
                        ...data,
                        completed: JSON.parse(kpiCount[0].Schedule_Execution_Metrics)?.completed || 0,
                        failed: JSON.parse(kpiCount[0].Schedule_Execution_Metrics)?.failed || 0,
                        sla_met: JSON.parse(kpiCount[0].Sla_Metrics)?.sla_met || 0,
                        sla_missed: JSON.parse(kpiCount[0].Sla_Metrics)?.sla_missed || 0,
                        sla_pending: JSON.parse(kpiCount[0].Sla_Metrics)?.sla_pending || 0,
                    })
                } else {
                    reqData.push({
                        ...data,
                        completed: 0,
                        failed:  0,
                        sla_met: 0,
                        sla_missed: 0,
                        sla_pending: 0,
                    })
                }
            })

        }
      
        setLoading(false)
        setProjectList(reqData)
        // setProjectList(result.data.projectList)
    }
    
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
        projectMappedJobScheduleFunc(projectId, value, pageSize);
	};
	const handlepageSizeChange = (event: any) => {
		projectMappedJobScheduleFunc(projectId, page, event.target.value);
	};

    async function projectMappedJobScheduleFunc(projectId: string, pageNumber: string, pageSize: string) {
        setTableLoading(true);
		const res = await getScheduleList(projectId);
		if (res.success) {
            setTableLoading(false);
            let jobArr = res.data.scheduleList.filter((obj: any) => !obj.successor || obj.successor === null)
            // let depJobArr = res.data.scheduleList.filter((obj: any) => obj.successor && obj.successor !== null)
            let reqData = jobArr;
            reqData.map((data: any) => {
                data['reorderIndex'] = data.Schedule_Index;
              
            //     // depJobArr.map((dep: any) => {
            //     //     let depJobExe = reqData.filter((data: any) => data.id === dep.id)
            //     //     if (dep.successor === data.projectScheduleId && moment(dep.Date).format("YYYY-MM-DD") === moment(data.Date).format("YYYY-MM-DD") && !depJobExe.length && dep.Days === data.Days) {
            //     //         reqData.push(dep)
                      
            //     //     }
            //     // })
            //     reqData.push(data);
            } )

            reqData.sort(function(a: any, b: any) {
                // Convert the date strings to Date objects and then to numbers using getTime()
                // This ensures TypeScript understands that you're subtracting numbers
                return new Date(b.Date).getTime() - new Date(a.Date).getTime();
            });
            setRowData(reqData);
            setjobSchedulesList(reqData)
            // setjobSchedulesList(res.data.scheduleList)
            // setPaginationData(res.data.page);
            // setPage(res.data.page.currentPage);
            // setPageSize(res.data.page.size);

            // setTimeout(() => {
            //     let table = document.getElementById(`job_list_details${projectId}`)
            //     if (table) {
            //         const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
            //             if (rdgElement) {
            //                 let jobsLength = res.data.scheduleList.length;
            //                 let height = 80;
            //                 if (jobsLength === 1) height = jobsLength * 80;
            //                 else if (jobsLength > 1 && jobsLength <=3) height = jobsLength * 65;
            //                 else if (jobsLength > 3 && jobsLength <=5) height = jobsLength * 55;
            //                 else if (jobsLength > 5 && jobsLength <=10) height = jobsLength * 50;
            //                 else if (jobsLength > 10 && jobsLength <=15) height = jobsLength * 40;
            //                 else height = jobsLength * 30;
            //             rdgElement.style.height = `${height}px`
            //             console.log("HEREE", height)
            //         }
            //     }
            // }, 100);

            const pagination = {"size":100,"totalElements": res.data.scheduleList.length,"totalPages": 1,"currentPage":1};
            setPaginationData(pagination);
            setPage('1');
            setPageSize('100');
		}
	}

    const handleEditClose = (data: any) => { 
        setOpenModal({
            ...openModal,
            isOpen: false,
        })
        setSelectedRows([])
    }

    const onCreateProgramClick = (event: any) => {
        setOpenModal({
            ...openModal,
            isOpen: true,
            type: 'Project',
            action:'CREATE'
        })
    }

    const formatDate = (isoString: string) => {
        const date = new Date(isoString);

        const padTo2Digits = (num: any) => String(num).padStart(2, '0');
      
        const month = padTo2Digits(date.getUTCMonth() + 1); // getUTCMonth() returns months from 0-11
        const day = padTo2Digits(date.getUTCDate());
        const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year
        const hours = padTo2Digits(date.getUTCHours());
        const minutes = padTo2Digits(date.getUTCMinutes());
        const seconds = padTo2Digits(date.getUTCSeconds());
      
        return `${month}-${day}-${year}`;
      }

      const handleAction = (module: string, type: string, data: any, e:any) => {
        // debugger
        e.preventDefault()
        e.stopPropagation();
        handleThreeDotsClose(e);
        handleDetailsThreeDotsClose(e);
        data = {
            ...data,
            ProgramName: programDetail.program_Name,
            ProgramID: programDetail.Program_ID
        }

        console.log('DATTA', data)
        
        switch (type) {
            case 'edit':
                setOpenModal({
                    ...openModal,
                    isOpen: true,
                    type: module,
                    action: 'EDIT',           
                    data: module === 'Program' ? programDetail : data
                })
                break;
                case 'delete':
                    setOpenModal({
                        ...openModal,
                        isOpen: true,
                        type: module,
                        action: 'DELETE',
                        data: module === 'Program' ? programDetail : data
                    })
                    break;
                case 'view':

                    window.location.href = `/data-pipeline/projects/${data.Program_ID}/${data.Project_Id}/${encodeURIComponent(data.ProgramName)}/${encodeURIComponent(data.Project_Name)}`
                    break;
            default:
                break;
        }
    }

    const handleClose = () => {
        setToast({
            ...toast,
            open: false
        })
    }

    const getToastData = (data: any) => {
    
        setToast({
            ...toast,
            message: data.data.message
        })
        console.log("OPENNN", data, toast)
        console.log("OPENNN22", data, toast)
    }
  
    // const getToastMessage = () => {

    // }


return (
 <div className="dashboard-panel-dataestate job-schedules-wrap data-pipeline-wrapper">  
    {/* <div className="calendar-icon-style"><span className='date-label'>Today</span> <CalendarIcon /></div> */}
        {role !== "Data Operations User" && (
        <div  className="service-sector-filter w-205">
				{/* Code for sector filter */}
				<Stack className="sector-filter-dataestate">
					<>
						<span className="sector-cta d-flex">
                        {/* <img className='icon cursor' src={ExportIcon} alt="" /> */}
                        <Button variant="contained" onClick={onCreateProgramClick} className={classes.createProjBtn}>
							ADD PROJECT
						</Button>
						</span>
					</>
					
				</Stack>
		</div>
        )}
        <Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md" className="edit-job-modal">
				<DataPipelineModal handleEditClose={handleEditClose} programData={openModal} getToastData={getToastData} passInfoToParent={passInfoToParent} />
		</Dialog>
            
        {/* <div className='d-flex-main'>
            <div className='program-data-card'>
                 <div className='justify-btw-items-center'>
                    <p className='tile-txt'>Details</p>
                    <div>
                        <img onClick={(e) => handleAction('Program', 'edit', {}, e)}  src={EditIcon} className='mr-5 cursor' alt="" />
                        <img onClick={(e) => handleAction('Program', 'delete', {}, e)} src={DeleteIcon} className='cursor' alt="" />
                    </div>
                 </div>
              
                 <div className='d-flex-main'>
                   <div className='w-40'>
                      <p className='tile-sub-header-txt'>Program Name: <span className='tile-detail-txt'>{programDetail?.program_Name ? programDetail.program_Name : ''}</span></p>
                      <p className='tile-sub-header-txt'>Sector: <span className='tile-detail-txt'>{programDetail?.Sector ? programDetail.Sector : ''}</span></p>
                   </div>
                   <div className='w-50'>
                      <p className='tile-sub-header-txt'>Date Created: <span className='tile-detail-txt'>{programDetail?.Created_Dt ? formatDate(programDetail.Created_Dt) : 'NA'}<img src={CalenderDateIcon} className='m-5' alt="" /></span></p>
                      <p className='tile-sub-header-txt'>Last Updated: <span className='tile-detail-txt'>{programDetail?.Updated_Dt ? formatDate(programDetail.Updated_Dt) : 'NA'}<img src={CalenderDateIcon} className='m-5' alt="" /></span></p>
                   </div>
                 </div>
            </div>
            <div className='program-data-card'>
            <p className='tile-txt'>Total Projects</p>
            <p className='count-box-txt'>{programDetail?.ProjectCount ? programDetail.ProjectCount : 0}</p>
            </div>
        </div> */}

                <Grid container item xs={12} className='mb-20'>
                    <Grid xs={7}>
                    <div className='program-data-card'>
                      <div className='justify-btw-items-center'>
                         {/* <p className='tile-txt'>Details</p> */}
                         <div className='actions-item actions-item-details'>
                            <span className="icon-info">

                            <Tooltip arrow title={<div className='info-tooltip-wrapper'>
                                <div className="info-date">
                                    <label className='title'>Date Created</label>
                                    <div className='value'>
                                    {programDetail?.Created_Dt ? <>{ formatDate(programDetail.Created_Dt) } <CalendarGreyIcon className='img' /> </> : 'NA'}
                                    </div>
                                </div>
                                <div className="info-date">
                                    <label className='title'>Last Updated</label>
                                    <div className='value'>
                                    {programDetail?.Updated_Dt ? <>{ formatDate(programDetail.Updated_Dt) } <CalendarGreyIcon className='img' /> </> : 'NA'}
                                    </div>
                                </div>
                                </div>}>
                                <IconButton
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <InfoGreyIcon />
                                </IconButton>
                            </Tooltip>

                            </span>
                            {role !== 'Data Operations User' && 
                            <Button
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleDetailsThreeDotsClick}
                                className='actions-btn'
                            >
                                <MoreIcon />
                            </Button>
                            }
                            <StyledMenu
                                className="menu-list-wrapper"
                                MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorElDetails}
                                open={open}
                                onClose={handleDetailsThreeDotsClose}
                            >
                                <MenuItem title='Click to edit' onClick={(e) => handleAction('Program', 'edit', {}, e)}>
                                <EditIcon className='mr-5 icon icon-actions' /> <span className="text">Edit</span>
                                </MenuItem>
                                <MenuItem title='Click to delete' onClick={(e) => handleAction('Program', 'delete', {}, e)}>
                                <DeleteIcon className='mr-5 icon icon-actions' /> <span className="text">Delete</span>
                                </MenuItem>
                            </StyledMenu>
                         </div>
                      </div>

                      <Grid className='d-flex-main' xs={12}>
                            <Grid xs={12}>
                            <div style={{display: 'flex'}}>
                           <div style={{width: '75%'}} className='tile-sub-header-txt'>
                                <div className="label-txt">Program Name</div>
                                <div className='tile-detail-txt'>{programDetail?.program_Name ? programDetail.program_Name : 'Loading...'}</div></div>
                            <div style={{width: '25%'}} className='tile-sub-header-txt'>
                                <div className="label-txt w-20">Sector</div>
                                <div className='tile-detail-txt'>{programDetail?.Sector ? programDetail.Sector : 'Loading...'}</div></div>
                           </div>
                            </Grid>
                            {/* <Grid xs={5}> */}
                            {/* <p className='tile-sub-header-txt'>
                                <span className="label-txt">Date Created</span>
                                <span className='tile-detail-txt small'>{programDetail?.Created_Dt ? <>{ formatDate(programDetail.Created_Dt) } <CalendarIcon className='img' /> </> : 'NA'}</span></p>
                            <p className='tile-sub-header-txt'>
                                <span className="label-txt">Last Updated</span>
                                <span className='tile-detail-txt small'>{programDetail?.Updated_Dt ? <>{ formatDate(programDetail.Updated_Dt) } <CalendarIcon className='img' /> </> : 'NA'}</span></p> */}

                            {/* </Grid> */}
                        </Grid>
              
                        {/* <div className='d-flex-main'>
                          <div style={{width: '50% !important'}}>
                            <p className='tile-sub-header-txt'>Program Name: <span className='tile-detail-txt'>{programDetail?.program_Name ? programDetail.program_Name : ''}</span></p>
                            <p className='tile-sub-header-txt'>Sector: <span className='tile-detail-txt'>{programDetail?.Sector ? programDetail.Sector : ''}</span></p>
                          </div>
                          <div className='w-50'>
                            <p className='tile-sub-header-txt'>Date Created: <span className='tile-detail-txt'>{programDetail?.Created_Dt ? formatDate(programDetail.Created_Dt) : 'NA'}<img src={CalenderDateIcon} className='m-5' alt="" /></span></p>
                            <p className='tile-sub-header-txt'>Last Updated: <span className='tile-detail-txt'>{programDetail?.Updated_Dt ? formatDate(programDetail.Updated_Dt) : 'NA'}<img src={CalenderDateIcon} className='m-5' alt="" /></span></p>
                          </div>
                        </div> */}
                     </div>
                    </Grid>
                    <Grid xs={5}>
                    <div className='program-data-card'>
                        <p className='tile-txt'>Total Projects</p>
                        <p className='count-box-txt-poj' style={{margin: '1.12em 0em !important'}}>{programDetail?.ProjectCount ? programDetail.ProjectCount : 0}</p>
                    </div>
                    </Grid>
                 </Grid>

        {
            programDetail?.ProjectCount > 0 ?          
            <div className='d-flex-main m-7'>
            <div className='project-name-header'>
              <p className='mr-5 fs16 fw700'>Projects</p> 
              {/* <img src={FilterIcon} className='' alt="" /> */}
            </div>
            <div className='status-header'>
              <div className='d-flex-main m-7'>
                   <div className='status-group'>
                      <div className='status-box box-total-jobs'>
                          Jobs
                      </div>
                      {/* <div className='status-box box-completed'>
                          Success
                      </div>
                      <div className='status-box box-failed'>
                          Failed
                      </div>
                      <div className='status-box box-run'>
                          Scheduled
                      </div>
                      <div className='status-box box-met'>
                          SLA Met
                      </div>
                      <div className='status-box box-missed'>
                          SLA Missed
                      </div> */}
                     
                   </div>
              </div>
            </div>
          </div> 


                
               
            : loading ? <div className='flex-center h-50'>
            
            <p className='no-program-txt'>Loading ...</p>
            </div> :  <div className='flex-center h-50'>
                <img className='image' src={NoPrgramFound} alt="" />
                <p className='no-program-txt'>Add Project</p>
            </div>
        }
         <div className='project-list-container'>
        {
          programDetail?.ProjectCount > 0 ?
          loading ? <div className='flex-center h-40'>
                
          <p className='no-program-txt'>Loading...</p>
      </div> :
          projectList.map((project: any, index: number) => {
            return(  <div key={index} className='my-10'>
                <Accordion className='accordion-list-wrapper' expanded={expanded === index} onChange={onOpenProjectAccordion(index, project.Project_Id, project.jobCount)}>
                <AccordionSummary
                       className='acc-summ'
                       expandIcon={<img className='icon' src={ExpandMoreIcon} />}
                       onClick={(e) => getProjectDetails(e, project.Project_Id, project.jobCount)}
                       aria-controls="panel1-content"
                       id={`panel-${index}-header`}
                    >
                         <div className='d-flex-main'>
                            <div style={{width: '59.7%', wordWrap: 'break-word'}} className='panel-typo'>
                              <Typography>{project.Project_Name ? project.Project_Name : ''}</Typography>
                            </div>
                            <div style={{width: '38%'}}>
                             <div className='justify-btw-items-center status-count-wrap'>
                              <div className='status-group'>
                              <div className='status-box'>
                                  <div className='count-box'>
                                  <span>{project.jobCount ? project.jobCount : 0}</span>
                                  </div>
                              </div>
                              {/* <div className='status-box box-completed'>
                                 <div className='count-box-project sla-comp'>
                                 <span>{project.completed ? project.completed : 0}</span>
                                 </div>
                              </div>
                              <div className='status-box box-failed'>
                              <div className='count-box-project sla-fail'>
                                 <span>{project.failed ? project.failed : 0}</span>
                                 </div>
                              </div>
                              <div className='status-box box-run'>
                              <div className='count-box-project sla-na'>
                                 <span>{project.sla_pending ? project.sla_pending  : 0}</span>
                                 </div>
                              </div>  
                              <div className='status-box box-met'>
                              <div className='count-box-project sla-met'>
                                 <span>{project.sla_met ? project.sla_met : 0}</span>
                                 </div>
                              </div>
                              <div className='status-box box-missed'>
                              <div className='count-box-project sla-miss'>
                                 <span>{project.sla_missed ? project.sla_missed : 0}</span>
                                 </div>
                              </div>   */}
                              </div>
                              <div className='justify-end'>
                                  <div className='actions-item'>
                                  {/* <EditIcon onClick={(e) => handleAction('Project','edit', project, e)} title='Click to edit' className='icon-actions mr-5 icon' />
                                  <DeleteIcon onClick={(e) => handleAction('Project','delete', project, e)} title='Click to delete' className='icon-actions mr-5 icon' /> */}
                                <Link to={`/data-pipeline/projects/${project.Program_ID}/${project.Project_Id}/${encodeURIComponent(programDetail.program_Name)}/${encodeURIComponent(project.Project_Name)}`} className="titleLink">
                                    <ViewHighlightIcon title='Click to view' className='mr-5 icon mt-3' />
                                </Link>
                                {role !== 'Data Operations User' && 
                                <Button
                                        aria-controls={open ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        variant="contained"
                                        disableElevation
                                        onClick={(e) => handleThreeDotsClick(e, index)}
                                        className='actions-btn'
                                    >
                                        <MoreIcon />
                                    </Button>
                                }   
                                    <StyledMenu
                                        className="menu-list-wrapper"
                                        MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={accordionActiveId == index}
                                        onClose={handleThreeDotsClose}
                                    >
                                        { !project.Clarity_Project_Id && <MenuItem title='Click to edit'  onClick={(e) => handleAction('Project','edit', project, e)}>
                                        <EditIcon className='mr-5 icon icon-actions' /> <span className="text">Edit</span>
                                        </MenuItem>
                                        }
                                        <MenuItem title='Click to delete' onClick={(e) => handleAction('Project','delete', project, e)}>
                                        <DeleteIcon className='mr-5 icon icon-actions' /> <span className="text">Delete</span>
                                        </MenuItem>
                                    </StyledMenu>

                                  {/* <span style={{margin: '0px 20px'}}>
                                  <img onClick={(e) => handleAction('Project','export', project, e)} className='icon' src={ExportIcon} alt="" />
                                  </span> */}
                                  </div>
                              </div>
                              </div>
                            </div>
                         </div>
                   </AccordionSummary>
                   <AccordionDetails>
                    
                        <div className='schedules-list-wrapper schedules-list-wrapper-program' id={`job_list_details${project.Project_Id}`}>
                        {/* <div className="schedules-graph">
                            {
                            grafanaChartLoading ? 'Loading...' : <ReactECharts
                                option={grafanaChartOptions}
                                theme={{
                                    backgroundColor: '#1b1e35'
                                }}
                            />}
                            </div> */}
                        <Grid>
                            {project.jobCount && !tableLoading && jobSchedulesList.length ? ( <>
                                <div className="table-action-items">
                                    <span className='action-item-title'>All Schedules</span>
                                    { selectedRows.length > 1 && !isOrderChanged && <span className="action-item-delete" onClick={handleBulkDeleteAction}>DELETE ALL SELECTED</span>
                                    }
                                    { isOrderChanged && <div className='action-item-reorder'>
                                        <span className='action-item-reorder-cancel' onClick={(e) => onHandleDragCancel(false)}>CANCEL</span>
                                        <span className="action-item-reorder-confirm"
                                            onClick={onHandleReorderSchedules}
                                        >SAVE & CONFIRM</span>
                                        </div>
                                    }
                                </div>
                                <div
                                className="ag-theme-quartz ag-custom-theme-quartz" // applying the Data Grid theme
                                style={{ height: 300 }} // the Data Grid will fill the size of the parent container
                                >
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={colDefs}
                                    rowDragManaged={true}
                                    onRowDragEnd={(e) => onHandleRowDragEnd(e)}
                                    rowSelection={rowSelection}
                                    onSelectionChanged={onSelectionChangeEvent}
                                    onGridReady={onGridReady}
                                />
                                </div>
                                {rowData && rowData.length && <div className="custom-ag-pagination">Showing 1 - {rowData.length} of {rowData.length} results</div>}
                                {/* <CustomizedDataGrid
                                    tableColumns={getColumns()}
                                    tableData={jobSchedulesList}
                                    tableType={`job-schedules-`+ index}
                                    defaultColumnNames={defaultColumnNames}
                                    page={page}
                                    pageSize={pageSize}
                                    paginationData={paginationData}
                                    listLength={jobSchedulesList.length}
                                    handlepageSizeChange={handlepageSizeChange}
                                    handlePageChange={handlePageChange}
                                    isDateRangeFilter={false}
                                    isDisabled={false}
                                    isFetching={true}
                                    /> */}
                                    </>
                                ) : project.jobCount && !tableLoading && jobSchedulesList.length == 0 ? <p className="text-center">No result found</p> : tableLoading ? ( <Loader /> ) : ''
                            }
                            { !project.jobCount && <div className='flex-center h-50'>
                                <img className='image' src={NoPrgramFound} alt="" />
                                <p className='no-program-txt'>Add Schedules</p>
                            </div>}
                        </Grid>
                        </div>
                   </AccordionDetails>
                </Accordion>
              </div>)
          }) : ''
        }
        </div>
        <Snackbar
               open={toast && openModal.message}
               anchorOrigin={{ vertical:'top', horizontal:'right' }}
               autoHideDuration={6000}
               onClick={handleClose}
               message={openModal.message}
             />
  </div>
)
}

export default ProgramDetails
import { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	CheckboxProps,
	FormControlLabel,
	Grid,
	InputAdornment,
	ListItemText,
	MenuItem,
	Popover,
	Stack,
	TextField,
	TextFieldProps,
	Typography
} from '@mui/material';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import searchIcon from '../../assets/akar-icons_search-1.svg';
import { getDecodedToken } from 'service/service';
import { useSearchParams } from 'react-router-dom';
import useStyles from '../jobs/jobs_styles';
import SearchIcon from '@mui/icons-material/Search';

export type FilterCheckedListType = {
	sectors: string[];
	program: string[];
	team: string[];
	consumedService: string[];
	resourceType: string[];
	resourceGroup: string[];
	resource: string[];
	project: string[];
	year: string[];
	month: string[];
};

type FilterAllPropsType = {
	handleFilterApply: (
		groupInfos: Record<string, FilterCheckedListType[keyof FilterCheckedListType]>,
		checkedList: FilterCheckedListType
	) => void;
	fetchSectorOptionsData: (
		filter: string,
		selectedProgram: {
			Sector: string | string[];
			searchQuery: (string | undefined)[];
		}
	) => Promise<any>;
};

const SearchField: React.FC<TextFieldProps> = ({ ...props }) => {
	return (
		<Box
			sx={{
				position: 'sticky',
				top: -16,
				zIndex: 1
			}}
		>
			<TextField
				fullWidth
				variant="outlined"
				placeholder="Search..."
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon color="info" />
						</InputAdornment>
					)
				}}
				sx={{
					backgroundColor: '#002349',
					backdropFilter: 'blur(10px)',
					borderRadius: '4px',
					color: 'white.main',
					input: {
						py: 1,
						px: 0,
						'&::placeholder': {
							fontSize: '12px'
						}
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: 'none'
						},
						'&:hover fieldset': {
							border: 'none'
						},
						'&.Mui-focused fieldset': {
							border: 'none'
						},
						'&.Mui-focused': {
							borderBottom: '2px solid green'
						}
					}
				}}
				{...props}
			/>
		</Box>
	);
};

const CustomCheckbox: React.FC<CheckboxProps> = ({ checked, onChange, ...props }) => {
	return (
		<Checkbox
			checked={checked}
			onChange={onChange}
			sx={{
				'&.Mui-checked': {
					color: '#35c6ff !important'
				}
			}}
			{...props}
		/>
	);
};

const FilterAll = ({ fetchSectorOptionsData, handleFilterApply }: FilterAllPropsType) => {
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [searchParams, setSearchParams] = useSearchParams();
	const dataFetchedRef = useRef(false);
	const [filterListLoader, setFilterListLoader] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<string[]>([]);
	const [programOptionsList, setProgramOptionsList] = useState<string[]>([]);
	const [teamOptionsList, setteamOptionsList] = useState<string[]>([]);
	const [csOptionsList, setCSOptionsList] = useState<string[]>([]);
	const [rtOptionsList, setrtOptionsList] = useState<string[]>([]);
	const [rgOptionsList, setrgOptionsList] = useState<string[]>([]);
	const [resourceOptionsList, setresourceOptionsList] = useState<string[]>([]);
	const [projectOptionsList, setProjectOptionsList] = useState<string[]>([]);
	const [yearOptionsList, setYearOptionsList] = useState<string[]>([]);
	const [monthOptionsList, setMonthOptionsList] = useState<string[]>([]);
	const [itemsChecked, setItemsChecked] = useState(false);
	const [checkedList, setCheckedList] = useState<FilterCheckedListType>({
		sectors: [],
		program: [],
		team: [],
		consumedService: [],
		resourceType: [],
		resourceGroup: [],
		resource: [],
		project: [],
		year: [],
		month: []
	});
	const [programInputText, setProgramInputText] = useState('');
	const [consumedServiceInputText, setConsumedServiceInputText] = useState('');
	const [resourceGroupInputText, setResourceGroupInputText] = useState('');
	const [resourceInputText, setResourceInputText] = useState('');
	const [projectInputText, setProjectInputText] = useState('');
	const [selectedFilter, setSelectedFilter] = useState<string>('Sectors');
	const [filterMenu, setFilterMenu] = useState<any | null>(null);

	async function handleSectorOptionsData(filter: string, searchQuery?: string) {
		clearFilter();
		if (filter === 'Consumed Service') {
			filter = 'ConsumedService';
		}
		if (filter === 'Resource Group') {
			filter = 'ResourceGroup';
		}
		if (filter === 'Resource Type') {
			filter = 'ResourceType';
		}
		const sectorFilter = checkedList.sectors ? checkedList.sectors : 'all';
		const selectedProgram: { Sector: string | string[]; searchQuery: (string | undefined)[] } = {
			Sector: sectorFilter,
			searchQuery: [searchQuery]
		};
		const sectorRes = await fetchSectorOptionsData(filter, selectedProgram);
		if (sectorRes.success) {
			if (filter === 'Sectors') {
				setSectorOptionsList(
					sectorRes.data.result.Sectors.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'Program') {
				setProgramOptionsList(
					sectorRes.data.result.sort().map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'Team') {
				setteamOptionsList(
					sectorRes.data.result.Team.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'ConsumedService') {
				setCSOptionsList(
					sectorRes.data.result.ConsumedService.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'ResourceType') {
				setrtOptionsList(
					sectorRes.data.result.ResourceType.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'Resource') {
				setresourceOptionsList(
					sectorRes.data.result.Resource.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'ResourceGroup') {
				setrgOptionsList(
					sectorRes.data.result.ResourceGroup.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'Project') {
				setProjectOptionsList(sectorRes.data.result.Project.filter((x: any) => x));
			}
			if (filter === 'Year') {
				setYearOptionsList(
					sectorRes.data.result.Year.map((x: any) => {
						return x;
					})
				);
			}
			if (filter === 'Month') {
				setMonthOptionsList(
					sectorRes.data.result.Month.map((x: any) => {
						return x;
					})
				);
			}
		}
	}

	useEffect(() => {
		if (searchParams.get('programName')) {
			const param: any = searchParams.get('programName');
			setCheckedList((prevState) => ({
				...prevState,
				program: [param]
			}));
		} else if (searchParams.get('team')) {
			const param: any = searchParams.get('team');
			setCheckedList((prevState) => ({
				...prevState,
				team: [param]
			}));
		} else if (searchParams.get('consumedService')) {
			const param: any = searchParams.get('consumedService');
			setCheckedList((prevState) => ({
				...prevState,
				consumedService: [param]
			}));
		}
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') handleSectorOptionsData('Sectors');
	}, []);

	const filterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(filterMenu === null ? event : null);
	};

	const handleClose = () => {
		localStorage.removeItem('presto-summary-sector-filter');
		setCheckedList({
			sectors: [],
			program: [],
			team: [],
			consumedService: [],
			resourceType: [],
			resourceGroup: [],
			resource: [],
			project: [],
			year: [],
			month: []
		});
		setItemsChecked(false);
	};

	const clearFilter = () => {
		if (selectedFilter === 'Program') setProgramOptionsList([]);
		else if (selectedFilter === 'Consumed Service') setCSOptionsList([]);
		else if (selectedFilter === 'Resource Group') setrgOptionsList([]);
		else if (selectedFilter === 'Resource') setresourceOptionsList([]);
		else if (selectedFilter === 'Project') setProjectOptionsList([]);
	};

	const fetchMenuItems = async (filter: string) => {
		setSelectedFilter(filter);
		let searchQuery =
			filter === 'Program'
				? programInputText
				: filter === 'Consumed Service'
				? consumedServiceInputText
				: filter === 'Resource Group'
				? resourceGroupInputText
				: filter === 'Resource'
				? resourceInputText
				: filter === 'Project'
				? projectInputText
				: '';
		await handleSectorOptionsData(filter, searchQuery);
		if (filter === 'Sectors') {
			setItemsChecked(false);
		}
	};

	const handleFilterSearch = (e: any) => {
		e.stopPropagation();
		const searchKeyword = (e.target as HTMLInputElement).value.toLowerCase();
		if (selectedFilter === 'Program') setProgramInputText(searchKeyword);
		else if (selectedFilter === 'Consumed Service') setConsumedServiceInputText(searchKeyword);
		else if (selectedFilter === 'Resource Group') setResourceGroupInputText(searchKeyword);
		else if (selectedFilter === 'Resource') setResourceInputText(searchKeyword);
		else if (selectedFilter === 'Project') setProjectInputText(searchKeyword);
		if (searchKeyword?.length >= 3 || !searchKeyword) {
			handleSectorOptionsData(selectedFilter, searchKeyword);
		}
	};

	const handleMenuItemClick = async (filter: string) => {
		setFilterListLoader(true);
		await fetchMenuItems(filter);
		setFilterListLoader(false);
	};

	const handleViewClose = () => {
		setFilterMenu(null);
	};

	const handleOkClick = () => {
		let groupInfos: Record<string, FilterCheckedListType[keyof FilterCheckedListType]> = {};
		[
			'sectors',
			'program',
			'team',
			'consumedService',
			'resourceType',
			'resourceGroup',
			'resource',
			'project',
			'year',
			'month'
		].forEach((key) => {
			let keyName = key[0].toUpperCase() + key.slice(1);
			if (key == 'sectors' && checkedList.sectors.length > 0) {
				groupInfos[keyName] = checkedList.sectors;
			}
			if (key == 'program' && checkedList.program.length > 0) {
				groupInfos[keyName] = checkedList.program;
			}
			if (key == 'team' && checkedList.team.length > 0) {
				groupInfos[keyName] = checkedList.team;
			}
			if (key == 'consumedService' && checkedList.consumedService.length > 0) {
				groupInfos[keyName] = checkedList.consumedService;
			}
			if (key == 'resourceType' && checkedList.resourceType.length > 0) {
				groupInfos[keyName] = checkedList.resourceType;
			}
			if (key == 'resourceGroup' && checkedList.resourceGroup.length > 0) {
				groupInfos[keyName] = checkedList.resourceGroup;
			}
			if (key == 'resource' && checkedList.resource.length > 0) {
				groupInfos[keyName] = checkedList.resource;
			}
			if (key == 'project' && checkedList.project.length > 0) {
				groupInfos[keyName] = checkedList.project;
			}
			if (key == 'year' && checkedList.year.length > 0) {
				groupInfos[keyName] = checkedList.year;
			}
			if (key == 'month' && checkedList.month.length > 0) {
				groupInfos[keyName] = checkedList.month;
			}
		});
		handleFilterApply(groupInfos, checkedList);
		handleViewClose();
	};

	const handleSectorChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			sectors: updatedSelection
		}));
	};

	const handleProgramChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			program: updatedSelection
		}));
	};

	const handleTeamChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			team: updatedSelection
		}));
	};

	const handleCSChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			consumedService: updatedSelection
		}));
	};

	const handleRTChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			resourceType: updatedSelection
		}));
	};
	const handleRGChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			resourceGroup: updatedSelection
		}));
	};
	const handleResourceChange = (updatedSelection: string[]) => {
		setCheckedList((prevState) => ({
			...prevState,
			resource: updatedSelection
		}));
	};
	const handleProjectChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			project: updatedSelection
		}));
	};
	const handleYearChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			year: updatedSelection
		}));
	};
	const handleMonthChange = (updatedSelection: string[]) => {
		setItemsChecked(false);
		setCheckedList((prevState) => ({
			...prevState,
			month: updatedSelection
		}));
	};

	const selectAllCheck = (checked: boolean) => {
		if (selectedFilter === 'Program') {
			setCheckedList((prevState) => ({
				...prevState,
				program: checked ? programOptionsList : []
			}));
		} else if (selectedFilter === 'Team') {
			setCheckedList((prevState) => ({
				...prevState,
				team: checked ? teamOptionsList : []
			}));
		} else if (selectedFilter === 'Consumed Service') {
			setCheckedList((prevState) => ({
				...prevState,
				consumedService: checked ? csOptionsList : []
			}));
		} else if (selectedFilter === 'Resource Type') {
			setCheckedList((prevState) => ({
				...prevState,
				resourceType: checked ? rtOptionsList : []
			}));
		} else if (selectedFilter === 'Resource Group') {
			setCheckedList((prevState) => ({
				...prevState,
				resourceGroup: checked ? rgOptionsList : []
			}));
		} else if (selectedFilter === 'Project') {
			setCheckedList((prevState) => ({
				...prevState,
				project: checked ? projectOptionsList : []
			}));
		} else if (selectedFilter === 'Resource') {
			setCheckedList((prevState) => ({
				...prevState,
				resource: checked ? resourceOptionsList : []
			}));
		} else if (selectedFilter === 'Year') {
			setCheckedList((prevState) => ({
				...prevState,
				year: checked ? yearOptionsList : []
			}));
		} else if (selectedFilter === 'Month') {
			setCheckedList((prevState) => ({
				...prevState,
				month: checked ? monthOptionsList : []
			}));
		} else {
			setCheckedList((prevState) => ({
				...prevState,
				sectors: checked ? sectorOptionsList : []
			}));
		}
		setItemsChecked(checked);
	};

	const leftFilters = ['Sectors', 'Program', 'Team', 'Consumed Service', 'Project', 'Year', 'Month'];

	return (
		<>
			<Box sx={{ cursor: 'pointer', width: '50px' }}>
				<FilterIcon onClick={filterClick} />
			</Box>
			<Popover
				open={filterMenu !== null}
				onClose={handleViewClose}
				anchorEl={filterMenu?.target}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				sx={{
					maxHeight: '420px',
					minHeight: '375px',
					mt: 3,
					ml: -6
				}}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
					<Typography variant="pageTitle">Filters</Typography>
					<Stack direction="row" gap={0.5}>
						<Button variant="contained" onClick={handleClose}>
							CLEAR
						</Button>
						<Button variant="contained" onClick={handleOkClick}>
							APPLY
						</Button>
					</Stack>
				</Stack>
				<Box sx={{ padding: 2, width: '500px' }}>
					<Grid container spacing={2}>
						{/* Left side filters */}
						<Grid
							item
							xs={5}
							sx={{
								maxHeight: '300px',
								overflowY: 'auto',
								overflowX: 'hidden'
							}}
						>
							<Stack spacing={1}>
								{leftFilters.map((filter: string) => (
									<MenuItem
										key={filter}
										onClick={() => handleMenuItemClick(filter)}
										sx={{
											backgroundColor: filter === selectedFilter ? '#0070BD !important' : ''
										}}
									>
										<ListItemText sx={{ px: 2 }}>{filter}</ListItemText>
									</MenuItem>
								))}
							</Stack>
						</Grid>

						{/* Right side sector checkboxes */}
						<Grid
							item
							xs={7}
							sx={{
								maxHeight: '300px',
								overflowY: 'auto',
								overflowX: 'hidden',
								borderLeft: '1.5px solid #545b5e45',
							}}
						>
							<Stack spacing={1}>
								{filterListLoader ? (
									<Box>Loading...</Box>
								) : selectedFilter === 'Sectors' ? (
									sectorOptionsList && (
										<>
											<MenuItem>
												<FormControlLabel
													label="All"
													control={
														<CustomCheckbox
															color="success"
															value="all"
															checked={sectorOptionsList.length === checkedList.sectors.length}
															onChange={(e) => {
																selectAllCheck(sectorOptionsList.length !== checkedList.sectors.length);
															}}
														/>
													}
												/>
											</MenuItem>
											{sectorOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														label={filter}
														control={
															<CustomCheckbox
																color="success"
																value={filter}
																checked={checkedList.sectors.includes(filter)}
																onChange={() => {
																	handleSectorChange(filter);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
										</>
									)
								) : selectedFilter === 'Program' ? (
									<>
										<SearchField
											id="searchField"
											autoComplete="off"
											variant="standard"
											placeholder="Search Program"
											value={programInputText}
											onChange={(e: any) => {
												handleFilterSearch(e);
											}}
										/>
										<MenuItem>
											<FormControlLabel
												label="All"
												control={
													<CustomCheckbox
														color="success"
														value="all"
														checked={programOptionsList.length === checkedList.program.length}
														onChange={(e) => {
															selectAllCheck(programOptionsList.length !== checkedList.program.length);
														}}
													/>
												}
											/>
										</MenuItem>
										{programOptionsList &&
											programOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														label={filter}
														title={filter}
														control={
															<CustomCheckbox
																color="success"
																value={filter}
																checked={checkedList.program.includes(filter)}
																onChange={() => {
																	handleProgramChange(filter);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</>
								) : selectedFilter === 'Team' ? (
									<>
										<MenuItem>
											<FormControlLabel
												label="All"
												control={
													<CustomCheckbox
														color="success"
														value="all"
														checked={teamOptionsList.length === checkedList.team.length}
														onChange={(e) => {
															selectAllCheck(teamOptionsList.length !== checkedList.team.length);
														}}
													/>
												}
											/>
										</MenuItem>
										{teamOptionsList &&
											teamOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														label={filter}
														title={filter}
														control={
															<CustomCheckbox
																color="success"
																value={filter}
																checked={checkedList.team.includes(filter)}
																onChange={() => {
																	handleTeamChange(filter);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</>
								) : selectedFilter === 'Consumed Service' ? (
									<>
										<SearchField
											id="searchField"
											autoComplete="off"
											variant="standard"
											placeholder="Search Consumed Service"
											value={consumedServiceInputText}
											onChange={(e: any) => {
												handleFilterSearch(e);
											}}
										/>
										<MenuItem>
											<FormControlLabel
												label="All"
												control={
													<CustomCheckbox
														color="success"
														value="all"
														checked={csOptionsList.length === checkedList.consumedService.length}
														onChange={(e) => {
															selectAllCheck(csOptionsList.length !== checkedList.consumedService.length);
														}}
													/>
												}
											/>
										</MenuItem>
										{csOptionsList &&
											csOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														label={filter}
														title={filter}
														control={
															<CustomCheckbox
																color="success"
																value={filter}
																checked={checkedList.consumedService.includes(filter)}
																onChange={() => {
																	handleCSChange(filter);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</>
								) : selectedFilter === 'Project' ? (
									<>
										<SearchField
											id="searchField"
											autoComplete="off"
											variant="standard"
											placeholder="Search Project"
											value={projectInputText}
											onChange={(e: any) => {
												handleFilterSearch(e);
											}}
										/>
										<MenuItem>
											<FormControlLabel
												label="All"
												control={
													<CustomCheckbox
														color="success"
														value="all"
														checked={projectOptionsList.length === checkedList.project.length}
														onChange={(e) => {
															selectAllCheck(projectOptionsList.length !== checkedList.project.length);
														}}
													/>
												}
											/>
										</MenuItem>
										{projectOptionsList &&
											projectOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														title={filter}
														label={filter}
														control={
															<CustomCheckbox
																color="success"
																value={filter}
																checked={checkedList.project.includes(filter)}
																onChange={() => {
																	handleProjectChange(filter);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</>
								) : selectedFilter === 'Year' ? (
									<>
										<MenuItem>
											<FormControlLabel
												label="All"
												control={
													<CustomCheckbox
														color="success"
														value="all"
														checked={yearOptionsList.length === checkedList.year.length}
														onChange={(e) => {
															selectAllCheck(yearOptionsList.length !== checkedList.year.length);
														}}
													/>
												}
											/>
										</MenuItem>
										{yearOptionsList &&
											yearOptionsList.map((filter: any) => (
												<MenuItem key="2024">
													<FormControlLabel
														label="2024"
														control={
															<CustomCheckbox
																color="success"
																value="2024"
																checked={checkedList.year.includes(filter)}
																onChange={() => {
																	const updatedSelection = checkedList.year.includes(filter)
																		? checkedList.year.filter((item) => item !== filter)
																		: [...checkedList.year, filter];
																	handleYearChange(updatedSelection);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</>
								) : selectedFilter === 'Month' ? (
									<>
										<MenuItem>
											<FormControlLabel
												label="All"
												control={
													<CustomCheckbox
														color="success"
														value="all"
														checked={monthOptionsList.length === checkedList.month.length}
														onChange={(e) => {
															selectAllCheck(monthOptionsList.length !== checkedList.month.length);
														}}
													/>
												}
											/>
										</MenuItem>
										{monthOptionsList &&
											monthOptionsList.map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														label={filter}
														control={
															<CustomCheckbox
																color="success"
																value={filter}
																checked={checkedList.month.includes(filter)}
																onChange={() => {
																	handleMonthChange(filter);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</>
								) : (
									<></>
								)}
							</Stack>
						</Grid>
					</Grid>
				</Box>
			</Popover>
		</>
	);
};

export default FilterAll;

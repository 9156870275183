import { useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	FormControl,
	Box,
	Grid,
	GridProps,
	InputLabel,
	MenuItem,
	Popover,
	Select,
	Stack,
	Tooltip,
	ListItemText,
	FormControlLabel,
	Radio,
	TextField,
	InputAdornment
} from '@mui/material';
import searchIcon from '../../assets/akar-icons_search-1.svg';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { getDecodedToken, fetchFinOpsExecutiveSummary, getDetailSummarySector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import '../FineOps/finops.scss';
import ReactECharts from 'echarts-for-react';
import ArrowUpBlackIcon from '../../assets/up-arrow-black.svg';
import '../DataEstate/data-estate-dashboard.scss';
import ArrowIcon from '../../assets/Arrow 253.svg';
import ArrowIconRed from '../../assets/growth arrow down.svg';
import { getPercentageOfTwoNumbers } from '../../utils/common-util';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import ToolTipIcon from '../../assets/info-icon.svg';
import { useNavigate } from 'react-router-dom';
import { ECElementEvent } from 'echarts';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';
import PieWidget from 'components/Widgets/PieWidget';
import ProgressChartWidget from 'components/Widgets/ProgressChart';

const ExecutiveSummary = () => {
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(true);

	const [programName, setProgramName] = useState('all');
	const [sectorName, setSectorName] = useState('all');

	const [CloudCostYTD, SetCloudCostYTD] = useState('-');
	const [CloudCostMTD, SetCloudCostMTD] = useState('-');
	const [CostChange, SetCostChange] = useState('-');
	const [costGrowth, SetCostGrowth] = useState('-');
	const [chartTitle, setChartTitle] = useState('Top 10 Programs by Cost (YTD) by Environment');
	const [pieTitle, setPieTitle] = useState('Cost % Distribution Across Teams (YTD)');

	const [grafanaCloudCostTrendLoading, setGrafanaCloudCostTrendLoading] = useState(true);
	const [grafanaCloudCostTrendOptions, setGrafanaCloudCostTrendOptions] = useState<any>();
	const [grafanaProgramByCostByEnvironmentLoading, setProgramByCostByEnvironmentLoading] = useState(true);
	const [grafanaProgramByCostByEnvironmentOptions, setProgramByCostByEnvironmentOptions] = useState<any>();
	const [grafanaDATeamsLoading, setGrafanaDATeamsLoading] = useState(true);
	const [grafanaDATeamsOptions, setGrafanaDATeamsOptions] = useState<any>();
	const [grafanaServicesByCostLoading, setGrafanaServicesByCostLoading] = useState(true);
	const [grafanaServicesByCostOptions, setGrafanaServicesByCostOptions] = useState<any>();

	const [cloudCostTrendBubbleValue, SetCloudCostTrendBubbleValue] = useState(0);
	const [cloudCostTrendLastDec, SetCloudCostTrendLastDec] = useState(0);
	const [cloudCostTrendCurrent, SetCloudCostTrendCurrent] = useState(0);
	const [cloudCostTrendStartMonth, setCloudCostTrendStartMonth] = useState('');
	const [isNoAnnualCloudCostTrend, setIsNoAnnualCloudCostTrend] = useState(false);

	const [programByCostByEnvData, setProgramByCostByEnvData] = useState<any>([]);
	const [programByCostByEnvNames, setProgramByCostByEnvNames] = useState<any>([]);
	const [programByCostByEnvSeriesData, setProgramByCostByEnvSeriesData] = useState<any>([]);
	const [serviceByCostSeriesData, setServiceByCostSeriesData] = useState<any>([]);
	const [DATeamsSeriesData, setDATeamsSeriesData] = useState<any>([]);

	const [ytdUnit, setYtdUnit] = useState('');
	const [mtdUnit, setMtdUnit] = useState('');

	const navigate = useNavigate();

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [sectorOptionsList, setSectorOptionsList] = useState<string[]>([]);
	const [programOptionsList, setProgramOptionsList] = useState<string[]>([]);
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};
	const handleViewClose = () => {
		setFilterMenu(null);
	};

	const handleSectorClose = (event: any) => {
		setFilterMenu(null);
	};

	async function fetchSectorOptionsData(filter: string, searchQuery?: string, filterBy?: any) {
		if (filter === 'Sectors') {
			setItemsChecked(true);
			const sectorRes = await getDetailSummarySector(filter, searchQuery);
			if (sectorRes.success) {
				if (filter === 'Sectors') {
					setSectorOptionsList(
						sectorRes.data.result.Sectors.map((x: any) => {
							return x;
						})
					);
				}
			}
		} else {
			const programRes = await fetchFinOpsExecutiveSummary('program', filterBy);
			if (programRes.success) {
					setProgramOptionsList(
						programRes.data.result.sort().map((x: any) => {
							return x;
						})
					);
				}
		}
	}

	const selectAllSectorCheck = (e: any) => {
		const { checked } = e.target;
		setItemsChecked(checked);
		setSectorName('all');
	};

	const handleSectorChange = (e: any) => {
		const { value, checked } = e.target;
		setItemsChecked(false);
		if(checked) {
			setSectorName(value);
		}
		else {
			setSectorName('all');
		}
	};

	const handleSectorOkClick = (event: any) => {
		setChartTitle('Top 10 Programs by Cost (YTD) by Environment');
		getExecutiveDashboardDetails(programName, sectorName);
		handleSectorClose(event);
	};

	const handleNavigateTeamDetails = (e: any) => {
		let programFilter = programName;
		let sectorFilter = sectorName;
		if (programFilter == 'EDF') return;

		let path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter + '&team=' + e.name;
		navigate(path);
	};

	const handleNavigateServiceDetails = (e: any) => {
		let programFilter = programName;
		let sectorFilter = sectorName;
		let path =
			'/finops/details?programName=' + programFilter + '&sector=' + sectorFilter + '&consumedService=' + e.name;
		navigate(path);
	};

	const handleNavigateDetails = (param: any) => {
		const envName = param.seriesName;
		let sectorFilter = sectorName;
		let environment = 'QA';
		if (envName == 'DEV') environment = 'Development';
		else if (envName == 'PROD') environment = 'Production';
		else if (envName == 'PREPROD') environment = 'Pre Production';
		else if (envName == 'SANDBOX') environment = 'Sandbox';
		if (envName == 'DEV' || envName == 'QA' || envName == 'PROD' || envName == 'PREPROD' || envName == 'SANDBOX')
			navigate(
				'/finops/details?programName=' + param.name + '&environmentName=' + environment + '&sector=' + sectorFilter
			);
		else navigate('/finops/details?programName=' + param.name + '&sector=' + sectorFilter);
	};

	const handleDefault = () => {
		let path = '';
		let programFilter = programName;
		let sectorFilter = sectorName;

		path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter;
		navigate(path);
	};

	const handleNavigatePrev = (isPrev: any) => {
		const months = getPreviousMonths(isPrev ? 1 : 2);
		let path = '';
		let programFilter = programName;
		let sectorFilter = sectorName;

		path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter + '&month=' + months;
		navigate(path);
	};

	function formatNumber(number: any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0.00';
		}
	}

	function formatAbsNumber(number: any) {
		if (number != null && !isNaN(number) && number != 0) {
			return Number(Math.abs(number)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0';
		}
	}

	function formatNumberM(number: any) {
		if (number != null && !isNaN(number) && number != 0) {
			if (Math.abs(number) < 1 && number > 0) {
				return '0';
			}
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0';
		}
	}

	const getPreviousMonths = (numMonths: number) => {
		const currentDate = new Date();
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		const months = [];
		for (let i = 1; i <= numMonths; i++) {
			let date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
			if (numMonths == 1) date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			const month = date.getMonth();
			const year = date.getFullYear();
			//   months.push(`${monthNames[month]} ${year}`);
			months.push(monthNames[month]);
		}
		return months;
	};

	async function getExecutiveDashboardDetails(prgName: any, sector: any) {
		SetCloudCostYTD('-');
		SetCloudCostMTD('-');
		SetCostChange('-');
		SetCostGrowth('-');

		const selectedProgram = {
			Program: [prgName],
			Sector: [sector]
		};

		fetchFinOpsExecutiveSummary('program', selectedProgram)
			.then((res) => {
				setProgramOptionsList(
					res.data.result.sort().map((x: any) => {
						return x;
					})
				);
			})
			.catch((error) => {
				console.error(error);
			});

		fetchFinOpsExecutiveSummary('ytd', selectedProgram)
			.then((res) => {
				let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
				if (count >= 1000000) {
					SetCloudCostYTD((count / 1000000).toString());
					setYtdUnit('MM');
				} else {
					SetCloudCostYTD((count / 1000).toString());
					setYtdUnit('K');
				}
			})
			.catch((error) => {
				console.error(error);
			});
		fetchFinOpsExecutiveSummary('mtd', selectedProgram)
			.then((res) => {
				let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
				if (count >= 1000000) {
					SetCloudCostMTD((count / 1000000).toString());
					setMtdUnit('MM');
				} else {
					SetCloudCostMTD((count / 1000).toString());
					setMtdUnit('K');
				}
			})
			.catch((error) => {
				console.error(error);
			});
		fetchFinOpsExecutiveSummary('mom', selectedProgram)
			.then((res) => {
				SetCostGrowth('');
				if (res.data.result) {
					let countCG = res?.data?.result[0]?.KPI_VALUE ?? 0;
					SetCostGrowth(countCG.toString());
				}
			})
			.catch((error) => {
				console.error(error);
			});
		fetchFinOpsExecutiveSummary('lmcg', selectedProgram)
			.then((res) => {
				SetCostChange('');
				if (res.data.result) {
					let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
					SetCostChange(count.toString());
				}
			})
			.catch((error) => {
				console.error(error);
			});

		fetchFinOpsExecutiveSummary('trend', selectedProgram)
			.then((res) => {
				if (res.data) {
					const currentIndexValue = moment().format('YYYY-MM');
					res.data.result = Object.fromEntries(
						Object.entries(res.data.result).filter(([month, _]) => month <= currentIndexValue)
					);

					let allDataAsArray = Object.entries(res.data.result);
					const allMonths = Object.keys(res.data.result);

					const firstMonthFromAllMonths = allMonths[0];
					const lastMonth = moment().subtract(1, 'months').format('MM');
					const indexValue = moment().year() + '-' + lastMonth;
					const lastIndex = allMonths.indexOf(indexValue);
					const lastMonthFromAllMonths = allMonths[lastIndex];
					const lastMonthFromForecast = allMonths[allMonths.length - 1];

					let currentMonth = new Date().getMonth();
					if (currentMonth == 0) currentMonth = 12;
					const currentYear = new Date().getFullYear();
					const lastMonthString = currentMonth.toString().padStart(2, '0');
					const filterMonth = `${currentYear - 1}-${lastMonthString}`;
					const filterDateEntry = Object.entries(res.data.result).filter((x) => x[0] === filterMonth);
					if (filterDateEntry.length) {
						let count: any = 0;
						count = filterDateEntry[0][1];
						const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
						const lastMonthEntry = Object.entries(res.data.result).filter((x) => x[0] === lastMonthDataIndex);
						if (lastMonthEntry.length) {
							setIsNoAnnualCloudCostTrend(false);
							const lastmonthvalue = lastMonthEntry[0][1] as number;
							SetCloudCostTrendCurrent(lastmonthvalue as number);
							SetCloudCostTrendLastDec(count);
							SetCloudCostTrendBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
						}
					}
					setGrafanaCloudCostTrendOptions(res.data.result);
					setGrafanaCloudCostTrendLoading(false);
				}
			})
			.catch((error) => {
				console.error(error);
			});

		fetchFinOpsExecutiveSummary('programcost', selectedProgram)
			.then((res) => {
				let data = res.data.result;

				// Extract unique environment names from the data
				const envNames = Object.keys(data[0]).filter((key) => key !== 'programname' && key !== 'total_cost');

				// Create a series for the environment data
				const envSeriesData = envNames.map((env, index) => ({
					name: env.toUpperCase(),
					type: 'bar',
					stack: 'total',
					showBackground: true,
					backgroundStyle: {
						color: '#174377'
					},
					label: {
						show: index === envNames.length - 1,
						position: 'right',
						color: '#F5F5F5',
						clip: false,
						formatter: (params: any) => {
							const costIdx = data.length - 1 - params.dataIndex;
							const totalCost = data[costIdx].total_cost;
							let formattedValue;
							if (totalCost >= 1000000) {
								formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
							} else {
								formattedValue = `${formatNumber(totalCost / 1000)}K`;
							}
							return `$${formattedValue}`;
						}
					},
					data: data.map((item: any) => item[env]).reverse()
				}));

				const totalCostSeries = {
					name: 'TOTAL_COST', // Use a recognizable name
					type: 'bar',
					stack: 'total',
					label: {
						show: true,
						position: 'right',
						color: '#F5F5F5',
						formatter: (params: any) => {
							const costIdx = data.length - 1 - params.dataIndex;
							const totalCost = data[costIdx].total_cost;
							let formattedValue;
							if (totalCost >= 1000000) {
								formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
							} else {
								formattedValue = `${formatNumber(totalCost / 1000)}K`;
							}
							return `$${formattedValue}`;
						}
					},
					// Make the bar invisible but clickable
					itemStyle: {
						color: 'transparent' // Invisible bar
					},
					emphasis: {
						itemStyle: {
							color: 'transparent'
						}
					},
					data: data.map((item: any) => 0) // No actual bar, just a label
				};
				
				setProgramByCostByEnvNames(envNames);
				setProgramByCostByEnvData(data);
				setProgramByCostByEnvSeriesData([... envSeriesData, totalCostSeries]);
				setProgramByCostByEnvironmentLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});

		fetchFinOpsExecutiveSummary('servicecost', selectedProgram)
			.then((res) => {
				let data = res.data.result;
				// Transform data into ECharts series format
				let seriesData = data.map((item: any) => ({
					name: item.consumedservice,
					value: item.total_cost,
					formattedName: `${item.percentage ? item.percentage : 0}%`
				}));
				const others = seriesData.filter((item: { name: string }) => item.name === 'Others');
				seriesData = seriesData.filter((item: { name: string }) => item.name !== 'Others');
				seriesData = seriesData.concat(others);

				setServiceByCostSeriesData(seriesData);
				setGrafanaServicesByCostLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});

		fetchFinOpsExecutiveSummary('teamcost', selectedProgram)
			.then((res) => {
				let data = res.data.result;
				// Transform data into ECharts series format
				let seriesData = data
					.map((item: any) => ({
						name: item.team,
						value: item.total_cost,
						formattedName: `${item.percentage ? item.percentage : 0}%`
					}))
					.sort((a: any, b: any) => a.name.localeCompare(b.name));
					
				setDATeamsSeriesData(seriesData);
				setGrafanaDATeamsLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	useEffect(() => {
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/executive-dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
				document.body.classList.add('program-arrow');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				// document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		fetchSectorOptionsData('Sectors');

		getExecutiveDashboardDetails(programName, 'all');
	}, []);

	const handleProgramsChange = (e: any) => {
		const value = e.target.value;
		setProgramName(value);

		// Set the title based on the selected program(s)		
		if (value === 'all') {
			setChartTitle('Top 10 Programs by Cost (YTD) by Environment');
		} else {
			setChartTitle('Cost by Environment');
		}

		setPieTitle(value === 'EDF' 
			? 'Cost % Distribution Across Sectors (YTD)' 
			: 'Cost % Distribution Across Teams (YTD)'
		);

		getExecutiveDashboardDetails(value, sectorName);
	}

	return (
		<Box mt="45px" p={4}>			
				{/* Code for Byte filter */}
				<div className="program-filter">
					<Stack>
						<div className="program-select">
							<FormControl fullWidth>
								<Select
									name="byte-select"
									labelId="single-program-select-label"
									id="single-program-select"
									value={programName}
									label="Program"
									onChange={handleProgramsChange}
									MenuProps={{
										PaperProps: {
											style: {
											height: '160px', 
											top: '113px',
											left: '1500px',
											},
										},
										}}
								>
									<MenuItem value="all">{'All Programs'}</MenuItem>
									{programOptionsList.map((program) => (
										<MenuItem key={program} value={program}>
											{program.length > 25 ? program.substring(0, 25) + '...' : program}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</Stack>
				</div>
				{/* Code for sector filter */}
				<div className="service-sector-filter t-70">
					<Stack className="sector-filter-dataestate mh-43">
						{userSector === 'all' ? (
								<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>All Sectors</span> <FilterIcon /></span></>
							) : (
								<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
							)}
						<Popover
							open={filterMenu !== null}
							onClose={handleSectorClose}
							className="filterContainer-finops"
							anchorReference="anchorPosition"
							anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
						>
							<div className="text-filter-row">
								<div className="selectAll-sec">
									<MenuItem className="selectAll">
										<FormControlLabel
											className={classes.checkbox}
											label="All"
											control={
												<Checkbox
													color="success"
													value="all"
													checked={itemsChecked}
													onChange={(e) => {
														selectAllSectorCheck(e);
													}}
												/>
											}
										/>
									</MenuItem>
									<div className="checkbox-sec">
										{sectorOptionsList &&
										sectorOptionsList.map((filter: any) => (
											<MenuItem key={filter} className="all-sector">
												<FormControlLabel
													className={classes.checkbox}
													label={filter}
													title={filter}
													control={
														<Checkbox
															className="data-check"
															color="success"
															value={filter}
															checked={sectorName === filter}
															onChange={(e) => {
																handleSectorChange(e);
															}}
														/>
													}
												/>
											</MenuItem>
										))}
									</div>
								</div>
								<div className="buttonSec">
									<Button variant="contained" className={classes.cancelBtn} onClick={handleSectorClose}>
										cancel
									</Button>
									<Button variant="contained" className={classes.okBtn} onClick={handleSectorOkClick}>
										ok
									</Button>
								</div>
							</div>
						</Popover>
					</Stack>
				</div>
				<WidgetCardContainer title="Cost Summary">
					<Grid container spacing={1} m={0}>
						<Grid item container spacing={1} xs={12} md={6}>
							<Grid item xs={12} lg={6}>
								<NumberWidget
									title="Azure Cloud Cost (YTD)"
									number={'$' + formatNumberM(CloudCostYTD)}
									isLoading={CloudCostYTD === '-'}
									tooltipLabel={''}
									hasTooltip={false}
									units={ytdUnit}
									handleEvent={handleDefault}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<NumberWidget
									title="Azure Cloud Cost (MTD)"
									number={'$' + formatNumberM(CloudCostMTD)}
									isLoading={CloudCostMTD === '-'}
									tooltipLabel={''}
									hasTooltip={false}
									units={mtdUnit}
									handleEvent={() => handleNavigatePrev(true)}
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<NumberWidget
									title="Average Cost Growth (MoM)"
									number={formatAbsNumber(costGrowth)}
									isLoading={costGrowth === '-'}
									tooltipLabel={''}
									hasTooltip={false}
									units="%"
									unitIcon={
										costGrowth === null || costGrowth === undefined
										  ? null
										  : parseFloat(costGrowth) > 0.0
										  ? ArrowIcon
										  : parseFloat(costGrowth) < 0.0
										  ? ArrowIconRed
										  : null
									}
									color={
										parseFloat(costGrowth) !== 0.0
										  ? parseFloat(costGrowth) < 0.0
											? 'error.main'
											: 'success.main'
										  : 'white'
									  }
									// unitIcon={
									// 	!costGrowth && parseFloat(costGrowth) != 0.0
									// 		? parseFloat(costGrowth) > 0.0
									// 			? ArrowIcon
									// 			: ArrowIconRed
									// 		: null
									// }
									// // unitIcon={costGrowth && parseFloat(costGrowth) != 0 ? (parseFloat(costGrowth) > 0 ? ArrowIcon : ArrowIconRed) : null}
									//color={parseFloat(costGrowth) != 0.0 ? (parseFloat(costGrowth) < 0.0 ? 'error.main' : 'success.main'): undefined}
									handleEvent={handleDefault} // handleEvent={() => handleSetComponent('MAJOR_INCIDENTS_LAST_MONTH', {
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<NumberWidget
									title="Cost Change Compared to Last Month"
									number={formatAbsNumber(CostChange)}
									isLoading={CostChange === '-'}
									tooltipLabel={''}
									hasTooltip={false}
									units="%"
									unitIcon={
										CostChange === null || CostChange === undefined
										  ? null
										  : parseFloat(CostChange) > 0.0
										  ? ArrowIcon
										  : parseFloat(CostChange) < 0.0
										  ? ArrowIconRed
										  : null
									}
									color={
										parseFloat(CostChange) !== 0.0
										  ? parseFloat(CostChange) < 0.0
											? 'error.main'
											: 'success.main'
										  : 'white'
									  }
									//unitIcon={CostChange ? (parseFloat(CostChange) > 0 ? ArrowIcon : ArrowIconRed) : null}
									//color={parseFloat(CostChange) < 0 ? 'error.main' : 'success.main'}
									handleEvent={() => handleNavigatePrev(false)} // handleEvent={() => handleSetComponent('MAJOR_INCIDENTS_LAST_MONTH', {
								/>
							</Grid>
						</Grid>
						{/* right side */}
						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Azure Cloud Cost Trend (MoM)"
								minTitleHeight="40px"
								data={grafanaCloudCostTrendOptions}
								hasBadge={!grafanaCloudCostTrendLoading && !isNoAnnualCloudCostTrend ? true : false}
								isLoading={grafanaCloudCostTrendLoading}
								conversionUnitProp="cost"
							/>
						</Grid>
					</Grid>
				</WidgetCardContainer>
				{/* second part */}
				<Grid item container mt={2} xs={12}>
					<WidgetCardContainer title="Cost Breakdown">
						<Grid container spacing={1}>
							<Grid item xs={12} md={4}>
								<ProgressChartWidget
									title={chartTitle}
									data={programByCostByEnvData}
									envNames={programByCostByEnvNames}
									seriesData={programByCostByEnvSeriesData}
									isLoading={grafanaProgramByCostByEnvironmentLoading}
									handleEvent={handleNavigateDetails}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<PieWidget
									title="Top Services by Cost (YTD)"
									isLoading={grafanaServicesByCostLoading}
									seriesData={serviceByCostSeriesData}
									handleEvent={handleNavigateServiceDetails}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<PieWidget
									title={pieTitle}
									isLoading={grafanaDATeamsLoading}
									seriesData={DATeamsSeriesData}
									handleEvent={handleNavigateTeamDetails}
								/>
							</Grid>
						</Grid>
					</WidgetCardContainer>
				</Grid>			
		</Box>
	);
};

export default ExecutiveSummary;

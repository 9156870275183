import React, { useState, useEffect, useRef } from 'react';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Grid, Box, Chip, TextField, FormControl, Button, Select, Menu, MenuItem } from '@mui/material';
// import { Stack } from '@mui/system';
import { Link, useSearchParams } from 'react-router-dom';
// import GraphGreen from '../../assets/graph_icon_green.svg';
// import GraphGray from '../../assets/graph_icon_gray.svg';
// import GraphRed from '../../assets/graph_icon_red.svg';
// import FailureRed from '../../assets/success_rate_negative.png';
// import SuccessGreen from '../../assets/success_rate_positive.svg';
// import OtherFailure from '../../assets/success_rate_negative_other.svg';
// import OtherSuccess from '../../assets/success_rate_positive_other.svg';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { textEditor } from 'react-data-grid';

import { OverflowTooltip } from '../overflow_tooltip';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment-timezone';

// import useStyles from './jobExecutionsstyles';
import '../jobExecutions/jobExecutions.css';
import '../jobs/jobs.css';
import Loader from '../Loader/loader';

import { CellExpanderFormatter } from '../cell_expander_formatter';

import {
	getJobExecutions,getJobExecutionsFilter,
	// getJobExecutionsMetrices,
	jobSearchForExecutions,
	updateJobRunHistory,
	getBoxRunsList,
	getActionTakenData,
	getReasons
} from '../../service/service';

import convertSeconds from '../../utils/convertSeconds';

export default function jobExecutions() {
	interface Row {
		runStatus: string;
		commonStatus: string;
		reasonForDelay: string;
		actionTaken: string;
		ticketNumber: string;
		userStatus: string;
		downstream: string;
		slaStatus: string;
		_id: string;
		name: string;
		jobId: string;
		status: string;
		exitCode: string;
		runNum: string;
		application: string;
		subApplication: string;
		runStartTime: string;
		runEndTime: string;
		slaEndTime: string;
		criticalFlag: boolean;
		machine: string;
		createdAt: string;
		updatedAt: string;
		updatedBy: string;
		boxName: string;
		jobType: string;
		children?: Row[];
		ischildren?: boolean;
		isExpanded?: boolean;
		actualDuration: string;
		overRun: boolean;
		thresholdPercent: string;
		durationDelay: string;
		avgRunTime: number;
		estimatedEndTime: string;
	}

	function toggleSubRow(name: string, runStartTime: string, runEndTime: any) {
		const boxRows = localStorage.getItem('boxRows');
		const rows = boxRows ? JSON.parse(boxRows) : executionsList;
		const rowIndex = rows.findIndex((r: any) => r.name === name && r.runStartTime === runStartTime);
		const row = rows[rowIndex];
		const newRows = [...rows];
		let children: Row[] = [];
		let childRows: Row[] = [];
		if (!row.isExpanded) {
			const start = moment(runStartTime).startOf('day').subtract(1, 'hour');
			const end = moment(runStartTime).endOf('day').subtract(1, 'hour');
			const time = row.scheduleTime;
			const boxPromise = getBoxRunsList(name, start, end, time);
			setBoxLoading(true);
			boxPromise.then((res) => {
				setBoxLoading(false);
				if (res.success) {
					childRows = res.data;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				setExecutionsList(newRows);
			});
		} else {
			children = row.children;
			newRows.splice(Number(rowIndex) + 1, children.length);
			newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			setExecutionsList(newRows);
		}
	}

	// const classes = useStyles();
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const [executionsList, setExecutionsList] = useState<any[]>([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [loading, setLoading] = useState(false);
	const [boxLoading, setBoxLoading] = useState(false);
	// const [metricLoading, setMetricLoading] = useState(false);
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const endDt = moment(dateTo).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
	const [fromDateTime, setFromDateTime] = useState<any>(
		hasDrillDowned && searchParams.has('fromDate') 
		  ? startDt 
		  : hasDrillDowned && searchParams.has('runStartTime')
			? moment.utc(searchParams.get('runStartTime')).tz('America/New_York')
			: moment().startOf('day').subtract(1, 'hours')
	  );	  
	const [toDateTime, setToDateTime] = useState<any>(
		hasDrillDowned && searchParams.has('toDate')
			? endDt
			: hasDrillDowned && searchParams.has('runEndTime')
				? moment.utc(searchParams.get('runEndTime')).tz('America/New_York')
				: moment().endOf('day').subtract(1, 'hours')
	);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [reasonList, setReasonList] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	moment.updateLocale(moment.locale(), { invalidDate: '' });

	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
				return <Chip label={status} color="success" />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	const renderThreshold = (threshold: any) => {
		let color = '#2DC8A8';
		if (threshold > 80 && threshold <= 100) color = '#FFC258';
		else if (threshold > 100) color = '#FE8083';
		return (
			<span color="success" style={{ color: color }}>
				{threshold} %
			</span>
		);
	};

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Scheduled Job',
				width: 308,
				minWidth: 150,
				formatter({ row, isCellSelected }) {
					const hasChildren = row.jobType === 'BOX';
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.name, row.runStartTime, row.runEndTime);
									}}
								/>
							)}
							{'   '}
							<Link
								className="jobsNameList"
								to={`/jobs/${row.jobId}`}
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '20px' }}
							>
								{row.name}
							</Link>
						</>
					);
				}
			},
			{
				key: 'boxName',
				name: 'Box Name',
				width: 200,
				minWidth: 150
			},
			{
				key: 'sector',
				name: 'Sector',
				width: 100,
				minWidth: 100
			},
			{
				key: 'system',
				name: 'System',
				width: 100,
				minWidth: 100
			},
			{
				key: 'process',
				name: 'Process',
				width: 120,
				minWidth: 120
			},
			{
				key: 'runStatus',
				name: 'Run Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.runStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'runStartTime',
				name: 'Start Time',
				width: 140,
				minWidth: 140,
				formatter(props) {
					const startDate = props.row.runStartTime;
					if (startDate) {
						return moment(startDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'runEndTime',
				name: 'End Time',
				width: 140,
				minWidth: 140,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return moment(endDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'slaStatus',
				name: 'SLA Status',
				width: 120,
				minWidth: 120,
				cellClass: 'center-text',
				// editor: (p) => (
				// 	<div>
				// 		<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
				// 			<Select
				// 				labelId="SLAMet"
				// 				id="SLAMet"
				// 				defaultOpen={true}
				// 				value={p.row.slaStatus}
				// 				onChange={(e: any) => {
				// 					p.onRowChange({ ...p.row, slaStatus: e.target.value }, true);
				// 				}}
				// 				label="SLA Met"
				// 			>
				// 				{[
				// 					{ key: 'Met', name: 'Met' },
				// 					{ key: 'Not Met', name: 'Not Met' },
				// 					{ key: 'NA', name: 'NA' }
				// 				].map((slaStatus) => (
				// 					<MenuItem key={slaStatus.key} value={slaStatus.key}>
				// 						{slaStatus.name}
				// 					</MenuItem>
				// 				))}
				// 			</Select>
				// 		</FormControl>
				// 	</div>
				// ),
				formatter(props) {
					return ['Met', 'Not Met'].includes(props.row.slaStatus) ? (
						<Chip
							label={props.row.slaStatus}
							color={props.row.slaStatus === 'Met' ? 'success' : 'secondary'}
							className="slaChip"
						/>
					) : (
						props.row.slaStatus
					);
				}
			},
			{
				key: 'slaEndTime',
				name: 'Normal Completion Time',
				minWidth: 200,
				width: 200,
				// maxWidth: 250,
				formatter(props) {
					return (
						<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
							{props.row.slaEndTime}
						</OverflowTooltip>
					);
				}
			},
			{
				key: 'estimatedEndTime',
				name: 'Estimated End Time',
				formatter(props) {
					return props.row.estimatedEndTime ? moment(props.row.estimatedEndTime).format() : '';
				},
				minWidth: 180
				// maxWidth: 200
			},
			{
				key: 'criticalFlag',
				name: 'Critical',
				formatter(props) {
					return props.row.criticalFlag ? 'Yes' : 'No';
				},
				minWidth: 105
				// maxWidth: 130
			},
			{
				key: 'reasonForDelay',
				name: 'Reason',
				width: 120,
				minWidth: 120,
				cellClass: 'has-editor',
				formatter(props) {
					if (props.row.reasonForDelay) {
						return props.row.reasonForDelay.length > 15 ? (
							<p className="reasonForDelay">{props.row.reasonForDelay}</p>
						) : (
							<p>{props.row.reasonForDelay}</p>
						);
					}
				},
				editor: (p) => (
					<div>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
							<Select
								labelId="Reason for Delay"
								id="reasonForDelay"
								defaultOpen={true}
								value={p.row.reasonForDelay}
								onChange={(e: any) => {
									p.onRowChange({ ...p.row, reasonForDelay: e.target.value }, true);
								}}
								label="Reason for Delay"
							>
								<MenuItem value=""></MenuItem>
								{reasonList.map((reason) => (
									<MenuItem key={reason.id} value={reason.description}>
										{reason.description}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)
			},
			{
				key: 'actionTaken',
				name: 'Action Taken',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.actionTaken ? <OverflowTooltip>{props.row.actionTaken}</OverflowTooltip> : 'Action Taken?';
				}
			},

			{
				key: 'ticketNumber',
				name: 'Ticket No',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.ticketNumber ? <OverflowTooltip>{props.row.ticketNumber}</OverflowTooltip> : 'Ticket No?';
				}
			},
			{
				key: 'downstream',
				name: 'Follow Up',
				width: 120,
				minWidth: 120,
				editor: textEditor,
				cellClass: 'has-editor',
				formatter(props) {
					return props.row.downstream ? <OverflowTooltip>{props.row.downstream}</OverflowTooltip> : 'Follow Up?';
				}
			},
			{
				key: 'userName',
				name: 'Last Updated By',
				minWidth: 150,
				width: 150
				// maxWidth: 200
			},
			{
				key: 'updatedAt',
				name: 'Last Updated At',
				minWidth: 150,
				width: 150,
				// maxWidth: 200,
				formatter(props) {
					const updatedAt = props.row.updatedAt;
					if (updatedAt) {
						return moment(updatedAt).format();
					}
					return '';
				}
			},
			{
				key: 'schedulerName',
				name: 'Scheduler',
				width: 100,
				minWidth: 100
			},
			{
				key: 'application',
				name: 'Application',
				formatter(props) {
					return props.row.application || ' ';
				},
				minWidth: 115,
				width: 115
				// maxWidth: 130
			},
			{
				key: 'subApplication',
				name: 'Sub Application',
				formatter(props) {
					return props.row.subApplication || ' ';
				},
				minWidth: 135,
				width: 135
				// maxWidth: 180
			},
			{
				key: 'commonStatus',
				name: 'Common Status',
				minWidth: 155,
				width: 155,
				// maxWidth: 220,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.commonStatus;
					return renderStatus(status);
				}
			},
			{
				key: 'machine',
				name: 'Machine',
				minWidth: 125,
				width: 125,
				// maxWidth: 220,
				formatter(props) {
					return props.row.machine;
				}
			},
			{
				key: 'jobType',
				name: 'Job Type',
				minWidth: 95
				// maxWidth: 160
			},
			{
				key: 'actualDuration',
				name: 'Actual Duration',
				minWidth: 160,
				width: 150
				// maxWidth: 200
			},
			{
				key: 'overRun',
				name: 'Over Run',
				minWidth: 95,
				formatter(props) {
					return props.row.overRun ? 'Yes' : 'No';
				},
				// maxWidth: 160
			},
			{
				key: 'thresholdPercent',
				name: 'Threshold %',
				minWidth: 120,
				width: 150,
				// maxWidth: 200,
				cellClass: 'center-text',
				formatter(props) {
					const thresholdPercent = props.row.thresholdPercent;
					return renderThreshold(thresholdPercent);
				}
			},
			{
				key: 'avgRunTime',
				name: 'Avg Exec Duration',
				minWidth: 150,
				width: 180,
				// maxWidth: 200,
				formatter(props) {
					const avgRunTime = props.row.avgRunTime;
					return convertSeconds(avgRunTime);
				}
			},
			{
				key: 'durationDelay',
				name: 'Duration of Delay',
				minWidth: 150,
				width: 180
				// maxWidth: 200
			}
		];
	}

	const defaultColumnNames = [
		'Scheduled Job',
		'Box Name',
		'Sector',
		'System',
		'Process',
		'Run Status',
		'Start Time',
		'End Time',
		'SLA Status',
		'Normal Completion Time',
		'Estimated End Time',
		'Critical',
		'Schedule Time',
		'Schedule Date',
		'Reason',
		'Action Taken',
		'Ticket No',
		'Follow Up',
		'Last Updated By',
		'Last Updated At'
	];
	// const metricDetails = [
	// 	{
	// 		name: 'Total Jobs',
	// 		value: null,
	// 		imgSrc: '',
	// 		arrowSrc: '',
	// 		percent: 0,
	// 		key: 'jobCount',
	// 		className: 'total'
	// 	},
	// 	{
	// 		name: 'Total Runs',
	// 		value: null,
	// 		imgSrc: '',
	// 		arrowSrc: '',
	// 		percent: 0,
	// 		key: 'runsCount',
	// 		className: 'total'
	// 	},
	// 	{
	// 		name: 'Successful',
	// 		value: null,
	// 		imgSrc: '',
	// 		arrowSrc: '',
	// 		percent: 0,
	// 		key: 'successCount',
	// 		className: 'success'
	// 	},
	// 	{
	// 		name: 'Not Started',
	// 		value: null,
	// 		imgSrc: '',
	// 		arrowSrc: '',
	// 		percent: 0,
	// 		key: 'pendingCount',
	// 		className: 'pending'
	// 	},
	// 	{
	// 		name: 'Failed/Terminate',
	// 		value: null,
	// 		imgSrc: '',
	// 		arrowSrc: '',
	// 		percent: 0,
	// 		key: 'failedCount',
	// 		className: 'failed'
	// 	},
	// 	{
	// 		name: 'Others',
	// 		value: null,
	// 		imgSrc: '',
	// 		arrowSrc: '',
	// 		percent: 0,
	// 		key: 'other',
	// 		className: 'others'
	// 	}
	// ];
	// const [scheduleMetrices, setScheduleMetrices] = useState<any[]>(metricDetails);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobExecutions(
			value,
			pageSize,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const handlepageSizeChange = (event: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobExecutions(
			'1',
			event.target.value,
			sortingInfo,
			fromDateTime,
			toDateTime,
			parseFilter(filterInfo)
		);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const handleSorting = (sortingInfo: any) => {
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobExecutions(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};

	const resetSearchParams = () => {
		if (hasDrillDowned || searchParams.has('fromDate') || searchParams.has('toDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('isDrillDowned');
			searchParams.delete('fromDate');
			searchParams.delete('toDate');
			setSearchParams(searchParams);
		}
	};

	const handleDateTimeChange = (field: any, dateTime: any) => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		else setToDateTime(dateTime);
	};
	const applyDateTimeRange = (e: any) => {
		resetSearchParams();
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobExecutions(page, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		// const promise2 = fetchJobMetrices(fromDateTime, toDateTime);
		setDateRangeMenuOpen(null);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
	};
	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		const promise1 = fetchJobExecutions(1, pageSize, sortingInfo, fromDateTime, toDateTime, parseFilter(filterInfo));
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator')
			setLoading(false);
		});
	};

	const handleSearch = (searchDetails: any) => {
		jobSearchForExecutionsFunc(searchDetails, parseFilter(filterInfo, 'name'));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// document.body.classList.add('loading-indicator');
		setLoading(true);
		let promise1: any;
		const filterQuerySkeleton = {} as any;
		const _status = searchParams.get('status');
		const _filter = searchParams.get('filterReason');
		const _slaStatus = searchParams.get('slaStatus');
		const _name = searchParams.get('name');
		const _runStartTime = searchParams.get('runStartTime')
		const _runEndTime = searchParams.get('runEndTime');

		if (_status && hasDrillDowned) {
			switch (_status) {
				case 'SLA met':
					filterQuerySkeleton.slaStatus = ['Met'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'SLA not met':
					filterQuerySkeleton.slaStatus = ['Not Met'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'SLA not applicable':
					filterQuerySkeleton.slaStatus = ['NA'];
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.commonStatus = ['Hold', 'In Progress', 'Terminated', 'Failure'];
					break;
				case 'SLA action taken':
					filterQuerySkeleton.slaStatus = ['Not Met'];
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.slaActionTaken = ['Yes'];
					break;
				case 'Total Critical Executions':
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Successful':
					filterQuerySkeleton.commonStatus = ['Successful'];
					break;
				case 'Hold/ In Progress':
					filterQuerySkeleton.commonStatus = ['Hold', 'In Progress'];
					break;
				case 'Failed/ Terminated':
					filterQuerySkeleton.commonStatus = ['Failure', 'Terminated'];
					break;
				case 'Not Started':
					filterQuerySkeleton.commonStatus = ['Not Started'];
					break;
				case 'Not Started Critical':
					filterQuerySkeleton.commonStatus = ['Not Started'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Stable (Below 80% threshold)':
					filterQuerySkeleton.stable = ['Yes'];
					break;
				case 'Attention required(Est.duration exceeded)':
					filterQuerySkeleton.overRun = ['Yes'];
					break;
				case 'At risk (80% threshold exceeded)':
					filterQuerySkeleton.atRisk = ['Yes'];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Exec. success':
					filterQuerySkeleton.commonStatus = ['Successful'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Exec. failed':
					filterQuerySkeleton.commonStatus = ['Failure', 'Terminated'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Not started':
					filterQuerySkeleton.commonStatus = ['Not Started'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Others':
					filterQuerySkeleton.commonStatus = ['Hold', 'In Progress'];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'At Risk':
					filterQuerySkeleton.atRisk = ['Yes'];
					filterQuerySkeleton.runStatus = ['SUCCESS'];
					break;
				case 'Action Taken':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.slaStatus = ['Not Met'];
					filterQuerySkeleton.actionTaken = ['NA'];
					break;
				case 'Exec Failed':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.commonStatus = ['Failure', 'Terminated'];
					break;
				case 'Non Critical Execution Failed':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.commonStatus = ['Failure', 'Terminated'];
					break;
				case 'SLAs Not Met':
					filterQuerySkeleton.slaStatus = ['Not Met'];
					break;
				case 'Reason Not Met':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.reasonForDelay = [`${_filter}`];
					break;
				default:
					break;
			}
		} else {
			getColumns().forEach((column: any) => {
				filterQuerySkeleton[column.key] = [];
			});
		}

		let storedJobExecutionsState = localStorage.getItem('jobExecutionsState')
			? JSON.parse(localStorage.getItem('jobExecutionsState') ?? '')
			: null;
		if (localStorage.getItem('selectedAlert')) {
			storedJobExecutionsState = localStorage.getItem('alertJobExecutionsState')
				? JSON.parse(localStorage.getItem('alertJobExecutionsState') ?? '')
				: null;
			localStorage.removeItem('alertJobExecutionsState');
		}
		if (hasDrillDowned && _status === 'SLA action taken') {
			const drillDownPromise = fetchActionTakenData();
			Promise.all([drillDownPromise]).then((values) => {
				filterQuerySkeleton.actionTaken = values[0];
				promise1 = fetchJobExecutions(
					page,
					pageSize,
					sortingInfo,
					fromDateTime,
					toDateTime,
					parseFilter(filterQuerySkeleton)
				);
			});
			localStorage.setItem('drill-down-execution', 'drill-true');
		} else if (hasDrillDowned) {
			if (_slaStatus) {
				filterQuerySkeleton.slaStatus = ['Not Met'];
			}
			if (_name) {
				filterQuerySkeleton.name = [_name];
			}
			if (_runStartTime && _runEndTime) {
				filterQuerySkeleton.runStartTime = [moment(_runStartTime).format('YYYY-MM-DD HH:mm')];
				filterQuerySkeleton.runEndTime = [moment(_runEndTime).format('YYYY-MM-DD HH:mm')];
			}
			promise1 = fetchJobExecutions(
				page,
				pageSize,
				sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(filterQuerySkeleton)
			);
			localStorage.setItem('drill-down-execution', 'drill-true');
		} else if (storedJobExecutionsState && storedJobExecutionsState.pageNumber && storedJobExecutionsState.pageSize) {
			const isDrillDownCondition = localStorage.getItem('drill-down-execution');
			if (isDrillDownCondition === 'drill-true') {
				localStorage.setItem('drill-down-execution', 'false');
			} else {
				localStorage.setItem('drill-down-execution', 'true');
			}
			promise1 = fetchJobExecutions(
				1,
				storedJobExecutionsState.pageSize,
				storedJobExecutionsState.sortingInfo,
				storedJobExecutionsState.selectedDate
					? moment(storedJobExecutionsState.selectedDate).startOf('day').subtract(1, 'hours')
					: fromDateTime,
				storedJobExecutionsState.selectedDate
					? moment(storedJobExecutionsState.selectedDate).endOf('day').subtract(1, 'hours')
					: toDateTime,
				parseFilter(storedJobExecutionsState.filterInfo)
			);
			setPaginationData(storedJobExecutionsState.page);
			setFilterData(storedJobExecutionsState.filterOptions);
			setPage(1);
			setPageSize(storedJobExecutionsState.pageSize);
			setSortingInfo(storedJobExecutionsState.sortingInfo);
			setFilterInfo(storedJobExecutionsState.filterInfo);
		} else {
			promise1 = fetchJobExecutions(
				page,
				pageSize,
				sortingInfo,
				fromDateTime,
				toDateTime,
				parseFilter(filterQuerySkeleton)
			);
			const isDrillDownCondition = localStorage.getItem('drill-down-execution');
				if (isDrillDownCondition === 'drill-true') {
					localStorage.setItem('drill-down-execution', 'false');
				} else {
					localStorage.setItem('drill-down-execution', 'true');
				}
		}
		// const promise2 = fetchJobMetrices(fromDateTime, toDateTime);
		Promise.all([promise1]).then((values) => {
			// document.body.classList.remove('loading-indicator');
			setLoading(false);
		});
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		const jobName = url.substring(url.lastIndexOf('/') + 1);
		if (window.location.pathname === '/jobExecutions') {
			if (activeId) {
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align');
			}
		} else if (jobName !== window.location.pathname && window.location.pathname === '/jobExecutions/' + jobName) {
			if (activeId) {
				activeId.classList.add('jobs-list');
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('root');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				activeId.classList.remove('jobs-list-table');
				activeId.classList.remove('jobs-list');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.remove('jobs-exe-datas');
			}
		}
	}, []);

	async function jobSearchForExecutionsFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await jobSearchForExecutions(searchDetails, jobFilterInfo, fromDateTime, toDateTime);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleEditCell = (rows: any[], editData: any) => {
		const runId = rows[editData.indexes[0]].id;
		const key = editData.column.key;
		const patchData: any = {};
		patchData[key] = rows[editData.indexes[0]][key];
		patchData.runId = runId;
		patchJobSchedule(patchData, rows);
	};

	async function fetchJobExecutions(
		pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
		toDateTime: any,
		jobFilterInfo: any
	) {
		setLoading(true);
		setFromDateTime(fromDateTime);
		setToDateTime(toDateTime);
		const res = await getJobExecutions(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			toDateTime,
			false
		);
		
		setLoading(false);
		
		if (res.success) {
			setExecutionsList(res.data.jobs);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			const jobExecutionsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: jobFilterInfo
			};
			localStorage.setItem('jobExecutionsState', JSON.stringify(jobExecutionsState));
			localStorage.setItem('boxRows', JSON.stringify(res.data.jobs));
		}
		setLoading(false);
		const res1 = await getJobExecutionsFilter(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			jobFilterInfo,
			fromDateTime,
			toDateTime
		);
		if(res1.success){
			setFilterData(res1.data.filterOptions);
			const reasonsRes = await getReasons('1', '500', '');
			setReasonList(reasonsRes.data.slaNotMetReasons);
			
		}
	}

	async function fetchActionTakenData() {
		setLoading(true);
		const res = await getActionTakenData(fromDateTime, toDateTime);
		if (res.success) {
			return res.data;
		}
		setLoading(false);
	}

	// async function fetchJobMetrices(fromDateTime: any, toDateTime: any) {
	// 	setMetricLoading(true);
	// 	const res = await getJobExecutionsMetrices(fromDateTime, toDateTime);
	// 	if (res.success) {
	// 		const data = res.data;
	// 		const metrices = metricDetails.map((metric: any) => {
	// 			const change =
	// 				(data.currentDetails[metric.key] - data.previousDetails[metric.key]) / data.previousDetails[metric.key];
	// 			const percentage = isNaN(change) ? 0 : change * 100;
	// 			const percent = percentage.toFixed(0);
	// 			return {
	// 				...metric,
	// 				value: data.currentDetails[metric.key],
	// 				percent,
	// 				arrowSrc: +percent < 0 ? OtherFailure : metric.key === 'runsCount' ? SuccessGreen : OtherSuccess,
	// 				imgSrc: ''
	// 			};
	// 		});
	// 		setScheduleMetrices(metrices);
	// 		setMetricLoading(false);
	// 	}
	// 	setMetricLoading(false);
	// }

	async function patchJobSchedule(patchData: any, rows: Row[]) {
		setLoading(true);
		const res = await updateJobRunHistory(patchData);
		if (res.success) {
			setLoading(false);
			setExecutionsList(rows);
		}
	}
	// [Date Range Menu]
	const [dateRangeMenuOpen, setDateRangeMenuOpen] = React.useState<null | HTMLElement>(null);

	const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
		setDateRangeMenuOpen(event.currentTarget);
	};

	const handleDateRangeMenu = () => {
		setDateRangeMenuOpen(null);
	};

	const open = Boolean(dateRangeMenuOpen);

	return (
		<div className="job-schedules-wrap">
			<div className="job-exexutions-dateFilter-div">
				{/* [Date Range Menu] */}
				<div style={{ display: 'flex', alignItems: 'center', padding: '5px 0px 5px 0px', marginTop: '5px', marginBottom: '1px' }}>
					{/* condition for default Today  */}
					{moment(fromDateTime).format('DD MM YYYY, HH:mm') ===
						moment().startOf('day').subtract(1, 'hours').format('DD MM YYYY, HH:mm') &&
					moment(toDateTime).format('DD MM YYYY, HH:mm') ===
						moment().endOf('day').subtract(1, 'hours').format('DD MM YYYY, HH:mm') ? (
						<div style={{ marginRight: '80px' }}>Today</div>
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginRight: '5px'
							}}
						>
							<div>{fromDateTime && moment(fromDateTime).tz('America/New_York').format('DD-MM-YYYY hh:mm')}</div>
							<div style={{ marginLeft: '4px', marginRight: '4px' }}>to</div>
							<div>{toDateTime && moment(toDateTime).tz('America/New_York').format('DD-MM-YYYY hh:mm')}</div>
						</div>
					)}

					<div onClick={handleDateClick} style={{ cursor: 'pointer' }}>
						<DatePickerIcon />
					</div>
				</div>

				<Menu
					id="date-range-menu"
					MenuListProps={{
						'aria-labelledby': 'date-range-button'
					}}
					anchorEl={dateRangeMenuOpen}
					open={open}
					onClose={handleDateRangeMenu}
					PaperProps={{
						style: {
							width: '30ch'
						}
					}}
				>
					{/* From date */}
					<span className="range_menu_title">Absolute time range</span>
					<div key="defaultView">
						<div className="label">From</div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DateTimePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{ placement: 'auto' }}
								toolbarTitle={
									<>
										Select Date Time{' '}
										<div className="time-picker-zone">{`Timezone: ${
											moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
										}`}</div>
									</>
								}
								className="dateFilter"
								ampm={false}
								value={moment(fromDateTime).tz('America/New_York')}
								minDateTime={moment(minJobExecDate).tz('America/New_York').startOf('day').subtract(1, 'hour')}
								maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hours')}
								onChange={(dateTime) => {
									handleDateTimeChange('fromDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
							/>
						</LocalizationProvider>
					</div>

					{/* To date */}
					<div>
						<div className="label">To</div>
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<DateTimePicker
								showToolbar={true}
								components={{
									OpenPickerIcon: DatePickerIcon
								}}
								PopperProps={{
									placement: 'auto'
								}}
								toolbarTitle={
									<>
										Select Date Time{' '}
										<div className="time-picker-zone">{`Timezone: ${
											moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
										}`}</div>
									</>
								}
								className="dateFilter"
								ampm={false}
								value={moment(toDateTime).tz('America/New_York')}
								minDateTime={moment(fromDateTime).tz('America/New_York')}
								maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hour')}
								onChange={(dateTime) => {
									handleDateTimeChange('toDateTime', dateTime);
								}}
								renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
							/>
						</LocalizationProvider>
					</div>
					<div className="date_range_button">
						<Button
							variant="contained"
							color="primary"
							style={{ width: '50%' }}
							onClick={() => {
								setFromDateTime(moment().startOf('day').subtract(1, 'hours'));
								setToDateTime(moment().endOf('day').subtract(1, 'hours'));
								setDateRangeMenuOpen(null);
							}}
						>
							Clear
						</Button>

						<Button variant="contained" onClick={applyDateTimeRange} style={{ width: '50%', color: '#00d08d' }}>
							Apply
						</Button>
					</div>
				</Menu>

				{/* <LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
					<FormControl className="dateFilter-formControl" sx={{ m: 1 }}>
						<DateTimePicker
							label="From Date"
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
							toolbarTitle={
								<>
									Select Date Time{' '}
									<div className="time-picker-zone">{`Timezone: ${
										moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
									}`}</div>
								</>
							}
							className="dateFilter"
							ampm={false}
							value={moment(fromDateTime).tz('America/New_York')}
							minDateTime={moment(minJobExecDate).tz('America/New_York').startOf('day').subtract(1, 'hour')}
							maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hours')}
							onChange={(dateTime) => {
								handleDateTimeChange('fromDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</FormControl>
					<FormControl className="dateFilter-formControl" style={{ marginBottom: '0px' }} sx={{ m: 1 }}>
						<DateTimePicker
							label="To Date"
							showToolbar={true}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{
								placement: 'auto'
							}}
							toolbarTitle={
								<>
									Select Date Time{' '}
									<div className="time-picker-zone">{`Timezone: ${
										moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
									}`}</div>
								</>
							}
							className="dateFilter"
							ampm={false}
							value={moment(toDateTime).tz('America/New_York')}
							minDateTime={moment(fromDateTime).tz('America/New_York')}
							maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hour')}
							onChange={(dateTime) => {
								handleDateTimeChange('toDateTime', dateTime);
							}}
							renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
						/>
					</FormControl>
				</LocalizationProvider>
				<Button variant="contained" color="primary" className="dateRangeApplyBtn" onClick={applyDateTimeRange}>
					Apply
				</Button> */}
			</div>
			<Box>
				{/* <Grid container className={classes.metricContainer} spacing={2}>
					{!metricLoading &&
						scheduleMetrices.map((metric) => (
							<Grid item xs={2} key={metric.name} className="job-widgets">
								<Paper elevation={2} className={classes.paperForMetric}>
									<Box sx={{ marginBottom: '10px', marginLeft: '10px' }}>
										{metric.name}
										<Stack direction="row" alignItems="flextStart" spacing={15} sx={{ marginBottom: '10px' }}>
											<p className={metric.className}>{metric.value}</p>
											<span className={classes.metricImageSpan}>
												{/* <img src={metric.imgSrc} className={classes.metricImgSrc}></img> */}
				{/* {metric.arrowSrc ? (
													<>
														<img src={metric.arrowSrc} className={classes.metricArrowImg} />
														<b>{Math.abs(metric.percent)}%</b>
													</>
												) : (
													''
												)}
											</span>
										</Stack>
									</Box>
								</Paper>
							</Grid>
						))}
				</Grid> */}
				<div className="job-schedule-table">
					<Grid container item xs={12}>
						<Grid item xs={5}></Grid>
						<Grid item xs={4} />
					</Grid>
					<Grid className="job_executions_tabledata">
						{!loading ? (
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={executionsList}
								tableType="Executions"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								showExport={true}
								fileName={`Job Executions-${moment(fromDateTime).format()}.xlsx`}
								searchDataValues={searchDetails}
								onFilterSearch={handleSearch}
								onCellEdit={handleEditCell}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={executionsList.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={true}
								isDisabled={false}
							/>
						) : (
							<Loader />
						)}
						{boxLoading ? <Loader /> : ''}
					</Grid>
				</div>
			</Box>
		</div>
	);
}

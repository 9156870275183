import { Autocomplete, Button, Checkbox, debounce, Dialog, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Theme } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import TeradataIcon from '../../assets/icon-teradata.svg'
import DatabricksIcon from '../../assets/databricks.svg'
import PowerBiIcon from '../../assets/PowerBI.svg'
import AzureIcon from '../../assets/azure-adf.svg'
import PlusSymbolIcon from '../../assets/close-panel.svg'
import { CustomizedDataGrid } from "../customized_data_grid";
import { Column } from "react-data-grid";
import Loader from "../Loader/loader";
import { getSearchScheduleJobNameList, getTeradataJobNameList, linkAddScheduleToProject, getNormalCompletionTime, getAddedSchedules, getAddedScheduleSearchFilterColumn } from "../../service/service"
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Link, useParams } from "react-router-dom"

import { ReactComponent as EditPencilIcon } from '../../assets/material-symbols_edit-rounded_white.svg';

import { ReactComponent as MaskedInputIcon } from "../../assets/clockIcon.svg"

import MaskedInput from 'react-maskedinput'
import { getHoursList } from "utils/common-util"
import { CellExpanderFormatter } from "components/cell_expander_formatter"

  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

    
const AddSchedules = () => {
    interface Row {
        id: number;
        selected: boolean;
        jobName: string;
        schedulerName: string;
        schedule: string;
        normalCompletionTime: string;
        freqExecution: string;
    };

    interface Filter {
        filterType: string;
        keywords: string | Array<string>;
        fromTime?: any;
        fromTimeValue?: string;
        toTime?: any;
        toTimeValue?: string;
        id?: number;
        operation?: string;
        type?: string;
        subType?: string;
    }

    interface AddedScheduleData {
       Job_Name: string;
       Platform_Id: number;
       startTime: string;
       normalCompletionTime: number;
       scheduler: string;
       dependentData: any;
       isExpanded: boolean;
       children: AddedScheduleData[];
       ischildren?: boolean;
       Dep_Job_Name?: string;
       Project_Schedule_ID: number;
       Primary_Project_Schedule_Id: number;
       Days: number;
       Start_Time: number;
       Normal_Completion_Time: number;
       Scheduler_Name: string
    }

    const { prgmid, prjid, prgname, prjname } = useParams();

	let selectedRowsId: any = [];
        
    const ITEM_HEIGHT = 38;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
    };

    const platformList = [
        'Teradata'
    ];

    const filterCriteriaList = [
        'Job Name',
        'Days',
        'Start Time'
    ];

    const operationList = [
        'Equals to (=)',
        'Not Equals to (!=)',
        'Like (%)',
        'Not Like (%)'
    ];

    const [timeList, setTimeList] = useState<any>(getHoursList);
    const [addedSchedulesList, setAddedSchedulesList] = useState<AddedScheduleData[]>([]);

    const defaultColumnNames = ['HeaderCheckbox','Job Name','Scheduler','Start Time (CST)','Normal Completion Time (CST)'];
    
    const [daysList, setDaysList]  = React.useState<any>(['All', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']);
    
    const [selectedRows, setSelectedRows] = useState<Row[]>([]);
    const [tableDataList, setTableDataList] = useState<Row[]>([]);
    const [jobDataList, setJobDataList] = useState<any[]>([]);
    const [jobNameList, setJobNameList] = useState<any[]>([]);
    const [platformName, setPlatformName] = React.useState<string>('Teradata');
    const [criteriaName, setCriteriaName] = React.useState<string>('');
    const [operationName, setOperationName] = React.useState<string>('');
    const [keywordName, setKeywordName] = React.useState<string>('');
    const [searchFilterDetails, setFilterSearchInfo] = useState([]);
    const [addClassFilterHoverEffect, setAddClassFilterHoverEffect] = React.useState<boolean>(false);

    const [openId, setOpenId] = React.useState<number>();

    const [requestBody, setRequestBody] = React.useState<any>();
    const [successMsg, setSuccessMsg] = React.useState<string>('');

    const [hasDaysAdded, setHasDaysAdded] = React.useState<boolean>();
    const [hasTimeAdded, setHasTimeAdded] = React.useState<boolean>();

    const [tableLoading, setTableLoading] = React.useState<boolean>();
    const [addProjectLoading, setAddProjectLoading] = React.useState<boolean>();
    const [openModal, setOpenModal] = useState<any>({isOpen: false})

    const [selectedJobName, setSelectedJobName] = React.useState<boolean>(false);
    const [searchScheduleBtn, setSearchScheduleBtn] = React.useState<boolean>();
    const [jobSearchKeyword, setJobSearchKeyword] = React.useState<string>('');
    const [searchInputLoading, setSearchInputLoading] = React.useState<boolean>();

    const [filterCriteria, setFilterCriteria]  = React.useState<Filter[]>([]);
    
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState('1');
	const [pageSize, setPageSize] = useState('100');

    const [paginationDataSchedule, setPaginationDataSchedule] = useState({ totalElements: 0, totalPages: 0 });
	const [pageSchedule, setPageSchedule] = useState('1');
	const [pageSizeSchedule, setPageSizeSchedule] = useState('100');
    const [scheduleTableLoading, setScheduleTableLoading] = React.useState<boolean>();
    let reqRows: Row[] = []

    let isTimeMenuId = 99999999;
    const [isFromTimeActive, setIsFromTimeActive] = useState(false);
    const [isEndTimeActive, setIsEndTimeActive] = useState(false);

    const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: 'desc' });
    const [filterInfo, setFilterInfo] = useState([]);
    const [filterData, setFilterData] = useState<Row[]>([]);
    const [filterOptions, setFilterOptions] = useState<any>({
    });

    function getColumns(): Array<Column<Row>> {
        return [
			{
                key: 'selected',
                name: 'HeaderCheckbox',
                formatter: ({ row }) => (
                    <input 
                        type="checkbox"
                        className="transparent-checkbox row-checkbox"
                        checked={row.selected}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            const updatedRow = { ...row, selected: checked };
                            console.log(updatedRow)
							const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
                            let tempSelectedRows = selectedRows;
                            console.log(selectedRows)
							if (checked) {
								tempSelectedRows.push(updatedRow)
								selectedRowsId.push(updatedRow.id)
							} else {
								selectedRowsId.splice(selectedRowsId.indexOf(row.id), 1)
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = false;	
								}
							}
					
							tempSelectedRows = tempSelectedRows.filter((eachRow:any) => { 
								return selectedRowsId.includes(eachRow.id)

							})
							if (tempSelectedRows.length == tableDataList.length) {
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = true;	
								}
							}
                            setSelectedRows(tempSelectedRows);
                            setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
                        }}
                    />
                ),
                minWidth: 40,
                maxWidth: 60
            },
            {
                key: 'jobName',
                name: 'Job Name',
                formatter(props) {
					return <div title={props.row.jobName} className="has-ellipsis">{props.row.jobName ? props.row.jobName : '--'}</div>
				},
                minWidth: 280
            },
            {
                key: 'schedulerName',
                name: 'Scheduler',
                formatter(props) {
					return <div title={props.row.schedulerName}>{props.row.schedulerName == 'AutoSys' ? 'AutoSys' : 'Control-M'}</div>
				},
                minWidth: 170
            },
            {
                key: 'schedule',
                name: 'Start Time (CST)',
                formatter(props) {
					return <div title={props.row.schedule} style={{textTransform: 'capitalize'}}>{props.row.schedule ? props.row.schedule : '--'}</div>
				},
                minWidth: 170
            },
            {
                key: 'normalCompletionTime',
                name: 'Normal Completion Time (CST)',
                formatter: ({row}) => {
                    return <div className="normal-compl-time-wrap">
                            <div className={isTimeMenuId == row.id ? 'active custom-time-picker' : 'custom-time-picker'}>
                                <MaskedInputIcon className="custom-icon-calc" />
                                <EditPencilIcon className="pencil-icon" />
                                <MaskedInput className='custom-time-input-ele' mask="11:11" placeholder="hh:mm" name="time" value={row.normalCompletionTime}
                                onBlurCapture={handleTimeMenuPopupClose}  onBlur={handleTimeMenuPopupClose}
                                onFocus={(e) => onClickMaskInput(e, row)} onClickCapture={(e) => onClickMaskInput(e, row)} 
                                onChange={(e) => onChangeNormalComplTime(e, row, true)} />
                                    <div className="custom-timepicker-menu">
                                        {timeList.map((time: string) => (
                                            <div className="list" onClick={(e)=> onChangeNormalComplTime(e, row) } key={time}>{time}</div>
                                        ))}
                                    </div>
                            </div> 
                        </div>
                },
                minWidth: 170
            }
        ];
	}

    const getDays = (day: number) => {
        let days;
        if (day == 0) {
            days = 'Sun'
        } else if (day == 1) {
            days = 'Mon'
        } else if (day == 2) {
            days = 'Tue'
        } else if (day == 3) {
            days = 'Wed'
        } else if (day == 4) {
            days = 'Thur'
        } else if (day == 5) {
            days = 'Fri'
        } else if (day == 6) {
            days = 'Sat'
        }
        return days;
    } 

    const defaultAddedScheduleColumnNames = ['Job Schedules','Platform','Start Time (CST)','Normal Completion Time (CST)','Scheduler'];
    
    function getAddedScheduleColumns(): Array<Column<AddedScheduleData>> {
        return [
            {
                key: 'Job_Name',
                name: 'Job Schedules',
                // formatter(props) {
				// 	return <div title={props.row.Job_Name} className={`has-ellipsis ${props.row.successor >0? 'has-successor' : ''}`}>{props.row.Job_Name ? props.row.Job_Name : '--'}</div>
				// },
                formatter({ row, isCellSelected }) {
                    const hasChildren = row.dependentData;
                    return (
                        <>
                            {hasChildren && (
                                <CellExpanderFormatter
                                    isCellSelected={isCellSelected}
                                    expanded={row.isExpanded === true}
                                    onCellExpand={() => {
                                        toggleSubRow(String(row.Project_Schedule_ID),row.dependentData);
                                    }}
                                />
                            )}
                            {'   '}
                            <Link
                                className="jobsNameList"
                                to=''
                                state={row}
                                style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
                            >
                               <span className={`${row.Primary_Project_Schedule_Id >0? 'has-successor' : ''}`}>{row.Job_Name}</span>  
                            </Link>
                            {' '}
                           
                        </>
                    );
                },
                minWidth: 170
            },
            {
                key: 'platform',
                name: 'Platform',
                formatter(props) {
					return <div className={`${props.row.Primary_Project_Schedule_Id >0? 'has-successor' : ''}`} >{props.row.Platform_Id == 1 ? 'Teradata' : 'Databricks'}</div>
				},
                minWidth: 170
            },
            {
                key: 'Start_Time',
                name: 'Start Time (CST)',
                formatter(props) {
					return <div className={`${props.row.Primary_Project_Schedule_Id >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{String(props.row.Start_Time)  ? `${getDays(props.row.Days)}, ${convertToTimeString(props.row.Start_Time)}` : '--'}</div>
				},
                minWidth: 220
            },
            {
                key: 'normalCompletionTime',
                name: 'Normal Completion Time (CST)',
                formatter(props) {
					return <div className={`${props.row.Primary_Project_Schedule_Id >0? 'has-successor' : ''}`} style={{textTransform: 'capitalize'}}>{props.row.Normal_Completion_Time ?  (props.row.Normal_Completion_Time < 10 ? `000${props.row.Normal_Completion_Time}`.replace(/(.{2})$/,':$1') : props.row.Normal_Completion_Time < 100 ? `00${props.row.Normal_Completion_Time}`.replace(/(.{2})$/,':$1') : props.row.Normal_Completion_Time.toString().replace(/(.{2})$/,':$1')) : '--'}</div>
				},
                minWidth: 170
            },

            {
                key: 'Scheduler_Name',
                name: 'Scheduler',
                formatter(props) {
					return <div className={`${props.row.Primary_Project_Schedule_Id >0? 'has-successor' : ''}`} >{props.row.Scheduler_Name}</div>
				},
                minWidth: 170
            },
        ];
	}

    function compareTimes(time1: string, time2: string) {
        const format = 'HH:mm';
      
        // Parse both times using moment
        const momentTime1 = moment(time1, format);
        const momentTime2 = moment(time2, format);
      
        // Compare and return true if time1 is greater than time2, else false
        return momentTime1.isAfter(momentTime2);
      }

    let jobName:any='';
    function toggleSubRow(name: string,resourceTypes:any) {
        
        const rows = addedSchedulesList;
        const rowIndex = rows.findIndex((r: any) => r.Project_Schedule_ID == name);
        console.log("HEREEEEEE", rows, name)
        const row = rows[rowIndex];
        const newRows = [...rows];
        let children: AddedScheduleData[] = [];
        let childRows: AddedScheduleData[] = [];
        jobName = name;
        if (!row.isExpanded) {
            if (resourceTypes.length>0) {
                childRows = resourceTypes;
            }
            children = childRows.map((x: any) => {
                return { ...x, isChildren: true };
            });
            newRows.splice(Number(rowIndex) + 1, 0, ...children);
            newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded, children };
            localStorage.setItem('boxRows', JSON.stringify(newRows));
            console.log("ROWWW", newRows)
            setAddedSchedulesList(newRows);
        }
        else
        {
            children = row.children;
            newRows.splice(Number(rowIndex) + 1, children.length);
            newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
            localStorage.setItem('boxRows', JSON.stringify(newRows));
            setAddedSchedulesList(newRows);
        }
    }   
    let addSchData: any = []
    const getAddedScheduleList = async (page: number = 1, size: number =100, filterInfo: any = {}, sortingInfo: any ={}) => {
        let params = {
            projectId:Number(prjid),
            page,
            size,
            filter: filterInfo,
            sortBy: sortingInfo.sortBy || '',
            orderBy: sortingInfo.sortDir || ''
        }
        setScheduleTableLoading(true)
       const result = await getAddedSchedules(params)
       if(result.success){
        setScheduleTableLoading(false)
        let jobArr = result.data.scheduleList.filter((obj: any) => !obj.Primary_Project_Schedule_Id || obj.Primary_Project_Schedule_Id === null)
        let depJobArr = result.data.scheduleList.filter((obj: any) => obj.Primary_Project_Schedule_Id && obj.Primary_Project_Schedule_Id !== null)
        let reqData: any = []
        jobArr.map((data: any) => {
                
            data['dependentData'] = []
            data['isExpanded'] = false
            depJobArr.map((dep: any) => {
                if (dep.Primary_Project_Schedule_Id === data.Project_Schedule_ID) {
                    data.dependentData.push(dep)
                }
            })
            data['dependentData'] = data.dependentData.length ? data.dependentData : false
            reqData.push(data);
        } )
        reqData.sort(function (a: any, b: any) {
            // First, compare by date
            const dateDiff = new Date(b.Date).getTime() - new Date(a.Date).getTime();
            
            // If dates are equal, compare by Days
            if (dateDiff === 0) {
              const daysDiff = a.Days - b.Days;
              
              // If Days are also equal, compare by Start_Time
              if (daysDiff === 0) {
                return a.Start_Time - b.Start_Time; // Assuming Start_Time is a comparable number or timestamp
              }
          
              return daysDiff;
            }
          
            return dateDiff;
          });
         
          setAddedSchedulesList(reqData)
          addSchData = reqData
          console.log("REQQQQQQQ", reqData, addedSchedulesList)
          setPaginationDataSchedule(result.data.page)
          setPageSchedule(result.data.page.currentPage)
          setPageSizeSchedule(result.data.page.size)
       }
      
      
    }

    const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

    const handleSorting = (sortingInfo: any) => {
        console.log("SORTTT", sortingInfo)
        if(parseFilter(sortingInfo).sortBy == 'platform'){
            let parsedData = parseFilter(sortingInfo)
            parsedData['sortBy'] = 'Platform_Id'
         
            // parsedData['platform'].map((data: any) => {
            //     switch (data) {
            //         case 'Teradata':
            //             sortingInfo['Platform_Id'].push(1)
            //             break;
                
            //         default:
            //             break;
            //     }
            // })
            setSortingInfo(parsedData)
           
            // delete parsedData.platform
           
            // getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
        } else {
            setSortingInfo(sortingInfo)
            getAddedScheduleList(Number(page), Number(pageSize), parseFilter(filterInfo), sortingInfo);
        }
   

	};

    const handleFilter = (filterData: any) => {
        if (filterData.Project_Schedule_ID) {
            delete filterData.Project_Schedule_ID
        }
      
        if (Object.keys(parseFilter(filterData)).includes('Job_Name')) {
            let filterFormat: any = {
                ...parseFilter(filterData),
              Project_Schedule_ID: []
            }
            parseFilter(filterData)['Job_Name'].map((data: any) => {
                
                let filteredData: any = addedSchedulesList.filter((fil: any) => fil.Job_Name == data)
               if (filteredData.length) {
                filterFormat.Project_Schedule_ID = filteredData.map((data: any) => data.Project_Schedule_ID)
               }
            
            });
            if (!Object.keys(filterFormat).includes('Job_Name')) {
               filterFormat = {} 
            }
            setFilterInfo(filterFormat);
            getAddedScheduleList(Number(page), Number(pageSize), filterFormat, sortingInfo);
        } else if(Object.keys(parseFilter(filterData)).includes('platform')){
            let parsedData = parseFilter(filterData)
            parsedData['Platform_Id'] = []
         
            parsedData['platform'].map((data: any) => {
                switch (data) {
                    case 'Teradata':
                        parsedData['Platform_Id'].push(1)
                        break;
                
                    default:
                        break;
                }
            })
            setFilterInfo(filterData);
            delete parsedData.platform
           
            getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
        }  else if(Object.keys(parseFilter(filterData)).includes('Start_Time')){
            let parsedData = parseFilter(filterData)
            console.log("PARRRRRRR", parsedData)
            let start = parsedData['Start_Time']
            parsedData['Days'] = []
            parsedData['Start_Time'] = []
            parsedData['Project_Schedule_ID'] = []
            console.log("PARRRRRRR", parsedData['Start_Time'])
            start.map((data: string) => {
                let obj = convertDayTime(data)
                parsedData['Start_Time'].push(obj.time)
                parsedData['Days'].push(obj.day)
            })
            parsedData['Days'].map((data: any, index: number) => {
                
                let filteredData: any = addedSchedulesList.filter((fil: any) => fil.Days == data && fil.Start_Time == parsedData['Start_Time'][index])
               if (filteredData.length) {
                parsedData.Project_Schedule_ID = filteredData.map((data: any) => data.Project_Schedule_ID)
               }
            
            });
            setFilterInfo(filterData);
            getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
        }  else if(Object.keys(parseFilter(filterData)).includes('normalCompletionTime')){
            let parsedData = parseFilter(filterData)
            let start = parsedData['normalCompletionTime']
           
            parsedData['Normal_Completion_Time'] = []
            parsedData['Project_Schedule_ID'] = []
            start.map((data: string) => {
                parsedData['Normal_Completion_Time'].push(convertTimeToNumber(data))
                
            })
            parsedData['Normal_Completion_Time'].map((data: any, index: number) => {
                
                let filteredData: any = addedSchedulesList.filter((fil: any) => fil.Normal_Completion_Time == data)
               if (filteredData.length) {
                parsedData.Project_Schedule_ID = filteredData.map((data: any) => data.Project_Schedule_ID)
               }
            
            });
            setFilterInfo(filterData);
            delete parsedData.normalCompletionTime
            getAddedScheduleList(Number(page), Number(pageSize), parsedData, sortingInfo);
        } 
         else {
            setFilterInfo(filterData);
            getAddedScheduleList(Number(page), Number(pageSize), parseFilter(filterData), sortingInfo);
        }
       
       
	};

    function convertTimeToNumber(time: string) {
        // Remove the colon and convert the string to a number
        const timeStr = time.replace(':', '');
    
        // Convert to a number, removing any leading zeros
        return Number(timeStr);
    }

    function convertDayTime(input: string) {
        // Split the input into day and time parts
        const [dayStr, time] = input.split(', ');
    
        // Map days of the week to their respective numbers
        const daysMap: any = {
            'sun': 0,
            'mon': 1,
            'tue': 2,
            'wed': 3,
            'thu': 4,
            'fri': 5,
            'sat': 6
        };
    
        // Get the numeric value for the day
        const day = daysMap[dayStr.toLowerCase()];
    
        // Convert the time part to a number without the colon
        const timeNum = Number(time.replace(':', ''));
    
        // Return the desired object
        return {
            day: day,
            time: timeNum
        };
    }
    

    const handleAndGetFilterOptions = async (column: any) => {
        
		let filterSch: any = {}
        let colObj: any = {
            Job_Name: 'Job_Name',
            platform: 'Platform_Id',
            Scheduler_Name: 'Scheduler_Name',
            Start_Time: 'Start_Time'
        }
        console.log("COLLLLLL", column, addSchData, addedSchedulesList)
        if (addedSchedulesList.length) {
            filterSch[column] = []
           
            addedSchedulesList.map((data: any) => {
                // Check if the value is already in the array before pushing
               
                    if (column == 'platform') {
                      switch (data[colObj[column]]) {
                        case 1:
                            if (!filterSch[column].includes('Teradata')){
                                filterSch[column].push('Teradata');
                            }   
                            break;
                      
                        default:
                            break;
                      }
                    } else if(column == 'Start_Time' && !filterSch[column].includes(`${getDays(data.Days)}, ${convertToTimeString(data.Start_Time)}`)){
                        filterSch[column].push(`${getDays(data.Days)}, ${convertToTimeString(data.Start_Time)}`)
                    }  else if(column == 'normalCompletionTime' && data.Normal_Completion_Time && !filterSch[column].includes(`${convertToTimeString(data.Normal_Completion_Time)}`)){
                        filterSch[column].push(`${convertToTimeString(data.Normal_Completion_Time)}`)
                    } else if (!filterSch[column].includes(data[colObj[column]])) {
                    filterSch[column].push(data[colObj[column]]);
                }
            
            });
            setFilterData(filterSch)
            console.log("FILYTERRRRRR", filterSch, filterData)
        }
	
	}

    const onClickMaskInput = (e: any, row: any) => {
        isTimeMenuId = row.id;
    }

    const handleTimeMenuPopupClose = () => {
        isTimeMenuId = 9999999;
    }

    const onClickMaskInputFromTime = (isOpen: boolean) => {
        if (isOpen) {
            setIsFromTimeActive(isOpen);
        } else {
            setTimeout(() => {
                setIsFromTimeActive(isOpen);
            }, 300);
        }
    }

    const onClickMaskInputToTime = (isOpen: boolean) => {
        if (isOpen) {
            setAddClassFilterHoverEffect(true);
            setIsEndTimeActive(isOpen);
        } else {
            setTimeout(() => {
                setIsEndTimeActive(isOpen);
            }, 300);
        }
    }

    const onChangeFromToTime = (event: any, index: number, type: any, subType: any, isTimeMenuClicked?: boolean) => {
        let val = event.target.value || event.target.innerText;
        val = val.split('');
        if (val[0] > 2 || val[0] == 2 && val[1] > 3) {
            val = ['2', '3', ':', '5', '9'];
            handleTimePickerMenuCloseFromOutside(event);
        }
        if (val[3] > 5) {
            val[3] = '5';
            val[4] = '9';
            handleTimePickerMenuCloseFromOutside(event);
        }
        val = val.join('');
        const filterData: any = [...filterCriteria];
        filterData[index][subType] = val;
        filterData[index][type] = val;
        setFilterCriteria(filterData);
        const findLenOfEnteredNum = val.replace(/_/g, '').replace(':', '').length;
        if ((isTimeMenuClicked ||  findLenOfEnteredNum == 4) && type == 'fromTime') {
            setIsFromTimeActive(false);
        }
        if ((isTimeMenuClicked ||  findLenOfEnteredNum == 4) && type == 'toTime') {
            setIsEndTimeActive(false);
        }
        console.log(filterCriteria)
    }

    const handleTimePickerMenuCloseFromOutside = (event: any) => {
        const inputEleList = document.getElementsByClassName('has-ellipsis') as HTMLCollection
        if (inputEleList.length) {
            const ele = inputEleList[0] as HTMLInputElement
            ele.click();
        }
    }

    const onClickOnInput = (id: number) => {
        const inputEle = document.getElementById('time-input-'+id) as HTMLInputElement
        inputEle.showPicker();
    }

    const onHandleOpenTimePicker = (idx: number) => {
        setOpenId(idx);
    }

    function replaceAt(str: string, index: number, replacement: string) {
        return str.substring(0, index) + replacement + str.substring(index + replacement.length);
        // let formatValue = '';
        // if (val.substring(0,1) > 2) {
        //     formatValue = replaceAt(val, 0, '2')
        // }
        // if (val.substring(0,1) == 2 && val.substring(1, 2) > 3) {
        //     formatValue = replaceAt(val, 1, '3')
        // }
        // if (val.substring(3,4) > 5) {
        //     formatValue = replaceAt(val, 3, '5')
        // }
      }

    const onChangeNormalComplTime = (event: any, row: any, isSelect?: boolean) => {
        let val = event.target.value || event.target.innerText;
        val = val.split('');
        if (val[0] > 2 || val[0] == 2 && val[1] > 3) {
            val = ['2', '3', ':', '5', '9'];
            handleTimePickerMenuCloseFromOutside(event);
        }
        if (val[3] > 5) {
            val[3] = '5';
            val[4] = '9';
            handleTimePickerMenuCloseFromOutside(event);
        }
        val = val.join('');
        const updatedRow = { ...row, normalCompletionTime: val };
        const allSelectedRows = selectedRows.length ? [...selectedRows] : reqRows;
        allSelectedRows.map((eachRow) => {
            if (eachRow.id == row.id) {
                eachRow.normalCompletionTime = val
            }
        })
        const findLenOfEnteredNum = val.replace(/_/g, '').replace(':', '').length;
        if (!isSelect ||  findLenOfEnteredNum == 4) {
            isTimeMenuId = 9999999;
        }
        setSelectedRows(allSelectedRows);
        setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
    }

    const onChangeNormalTime = (event: any, row: any) => {
        if (event && event._d) {
            let normalCompletionTime = moment(event._d).utc().format('HH:mm')
            const updatedRow = { ...row, normalCompletionTime: normalCompletionTime };
            setSelectedRows(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
            setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
        }
    }

    // const onChangeInputNormalTime = (event: any, row: any) => {
    //     console.log(event);
    //     console.log(row)
    //     let normalCompletionTime = event.target.value
    //     console.log(normalCompletionTime)
    //     if (!normalCompletionTime.includes(':')) {
    //         normalCompletionTime = normalCompletionTime.replace(/(..)/g, '$1:').slice(0,-1)
    //     }
    //     const updatedRow = { ...row, normalCompletionTime: normalCompletionTime };
    //     setTableDataList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
    // }
    
    const handleHeaderSelectAll = (event: any) => {
		selectedRowsId = [];
		let tempSelectedRows: any = [];
		tableDataList.map((row: any) => {
			row.selected = event.target.checked;
			if (event.target.checked) {
				selectedRowsId.push(row.id);
			}
		});
		if (event.target.checked) {
			tempSelectedRows = tableDataList;
		}
        console.log(tempSelectedRows)
        setSelectedRows(tempSelectedRows);
       
		setTableDataList(tableDataList);
		
        reqRows = tempSelectedRows

		let inputElements: any = document.getElementsByClassName('row-checkbox');
        
		for (let i = 0; i < inputElements.length; i++) {
			if (inputElements[i] instanceof HTMLInputElement) {
				inputElements[i].checked = event.target.checked;
			}
		}
	}

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
        searchScheduleFunc(requestBody, value, pageSize);
	};
	const handlepageSizeChange = (event: any) => {
		searchScheduleFunc(requestBody, page, event.target.value);
	};
    
	const handlePageChangeSchedule = (event: React.ChangeEvent<unknown>, value: string) => {
        getAddedScheduleList(Number(value), Number(pageSize));
	};

    const handleFilterSearch = async (searchKeyword: any, columnKey: any) => {
		console.log(searchKeyword, columnKey)
       
		if(searchKeyword?.length >= 3)  {
            let obj = {
                columnName: columnKey,
                key: searchKeyword
            }
            const res = await getAddedScheduleSearchFilterColumn(
                obj)
            if (res.success) {
                filterOptions[columnKey] = res.data?.map((obj: any) => obj.name) || [];
                setFilterData(filterOptions)
            }
        }
	};
	const handlepageSizeChangeSchedule = (event: any) => {
		getAddedScheduleList(Number(page), event.target.value);
	};
    const handleOnClose  = (event: any, index: number, autoValue?: any) => {
        if (autoValue == 'selectOption') {
            const { target: { textContent } } = event;
            const filterData = [...filterCriteria];
            filterData[index]['keywords'] = textContent;
            if (textContent) {
                jobDataList.filter((job: any) => {
                    if (job.JobName == textContent) {
                        filterData[index]['id'] = job.JobId;
                    }
                })
            }
            setSelectedJobName(false);
            filterData.filter((fil: any) => {
                console.log(fil.filterType, fil.keywords)            
                if (fil.filterType == 'Job Name' && fil.keywords) {
                    setSelectedJobName(true);
                }
            })
            setFilterCriteria(filterData);
        }
    }
    let selectedJobs: any = []
    const handleChangeDynamicKeywords = (event: any, index: number, autoValue?: any) => {
        const { target: { value } } = event;
        const filterData = [...filterCriteria];
        filterData[index]['keywords'] = autoValue ? autoValue : value;
        let jobNameArr = filterData.filter((obj: any) => obj.filterType == 'Job Name')
        if (autoValue) {
            jobDataList.filter((job: any) => {
                if (job.JobName == autoValue) {
                    filterData[index]['id'] = job.JobId;
                }
            })
        }
       
        if (jobNameArr.length) {
           jobNameArr.map((obj: any) => {
          
            jobDataList.filter((job: any) => {
                console.log("HEREEEEE", obj, job)
                if (job.JobName == obj.keywords) {
                    const pos = filterData.findIndex((data:any) => data.keywords === obj.keywords)
                    filterData[pos]['id'] = job.JobId;
                }
            })
           })
        }
        setSelectedJobName(false);
        filterData.filter((fil: any) => {
            console.log(fil.filterType, fil.keywords)            
            if (fil.filterType == 'Job Name' && fil.keywords) {
                setSelectedJobName(true);
            }
        })
        console.log("filterData",filterData)
        setFilterCriteria(filterData);
    };

    const debouncedFetchOptions = useMemo(
        () => debounce(async (filterData, value, isSubmit?: boolean) => {
          jobNameListFunc(filterData[0].keywords, value, isSubmit, filterData);
        }, 500),
        []
      );

    const onChangeJobNameKeyDown = (event: any) => {
        if(!/[0-9a-zA-Z[\]#!*?_-]/.test(event.key)){
            event.preventDefault();
        }
    }

    const onChangeJobNameInput = (event: any, index: number) => {
        const { target: { value } } = event;
        const valueTrim = value.trim();
        const filterData = [...filterCriteria];
        filterData[index]['keywords'] = valueTrim;
        setFilterCriteria(filterData);
        
        if (valueTrim && valueTrim.length > 0) {
            setJobSearchKeyword(valueTrim);
            setSearchInputLoading(true);
            debouncedFetchOptions(filterData, valueTrim);
        }
    }

    const onChangeTime = (event: any, index: number, type: any, subType: any) => {
        let selectedTime = moment(event);
        console.log(selectedTime)
        console.log(moment(event._d).utc().format('HH:mm'))
        const filterData: any = [...filterCriteria];
        if (event && event._d)
        filterData[index][subType] = moment(event._d).utc().format('HH:mm');
        filterData[index][type] = selectedTime;
        setFilterCriteria(filterData);
    }

    const handleChangePlatform = (event: SelectChangeEvent<typeof platformName>) => {
      const { target: { value } } = event;
      setPlatformName(value);
    };

    const onHoverFilterEffect = (isOpen: boolean) => {
        setAddClassFilterHoverEffect(isOpen);
    }

    const handleChangeDynamicFilter = (event: any, index: number) => {
        const { target: { value } } = event;
        const filterData = [...filterCriteria];
        filterData[index].filterType = value;
        if (value == 'Days') {
            setHasDaysAdded(true);
            filterData[index].keywords = []
            filterData[index]['id'] = index;
        } else if (value == 'Job Name') {
            filterData[index].id = index;
        } else if (value == 'Start Time') {
            setHasTimeAdded(true);
            filterData[index].fromTime = '';
            filterData[index].toTime = '';
            filterData[index].fromTimeValue = '';
            filterData[index].toTimeValue = '';
        }
        setFilterCriteria(filterData);
    };

    const handleAddFilter = (index: any) => {
        const newFilterData = {filterType: 'Job Name', operation: 'Equals to (=)', keywords: ''};
        filterCriteria.push(newFilterData);
        const insertedFilterCriteria = filterCriteria;
        setFilterCriteria([...insertedFilterCriteria]);
    }

    const handleRemoveFilter = (index: any) => {
        const filterData = [...filterCriteria]
        if (filterData[index].filterType == 'Days') {
            setHasDaysAdded(false);
        } else if (filterData[index].filterType == 'Start Time') {
            setHasTimeAdded(false);
        }
        filterData.splice(index, 1);
        setFilterCriteria([...filterData]);
    }

	const handleClear = () => {
        onHoverFilterEffect(false);
		setSelectedJobName(false);
        setSearchScheduleBtn(false);
        setHasDaysAdded(false);
        setHasTimeAdded(false);
        setJobSearchKeyword('');
        const filterData = [
            {filterType: 'Scheduler Name*', operation: 'Equals to (=)', keywords: 'All'},
            {filterType: 'Job Name', operation: 'Equals to (=)', keywords: '', id: 1}
        ]
        setFilterCriteria(filterData);
	};

    const onSubmitSearchFetchJobIds = () => {
        // if job name selected from the dropdown
        if (selectedJobName) {
            handleOnSubmit(null, filterCriteria);
        } else {
            console.log(filterCriteria)
            setTableLoading(true);
            debouncedFetchOptions(filterCriteria, jobSearchKeyword, true);
        }
    }

	const handleOnSubmit = (jobId?: any, filterCriterias?: any) => {
        let filterCriteriaData = filterCriterias;
        if (filterCriterias && filterCriteria.length) {
            filterCriteriaData = filterCriterias;
        }
        let hasJobName = false, hasSchedulerType = false;
        let reqBody: any = {jobIds: [], days: [], startTime: [], projectId: prjid};
        console.log(filterCriteriaData)
        filterCriteriaData.filter((filter: any)=> {
            console.log(filter.keywords + ' ' + filter.filterType)
            if (filter.filterType == 'Scheduler Name*' && filter.keywords != '') {
                hasSchedulerType = true;
            } else if (filter.filterType == 'Job Name' && filter.keywords != '') {
                if (jobId && jobId.length && !selectedJobName) {
                    reqBody.jobIds = jobId;
                    hasJobName = true;
                } else if (selectedJobName) {
                    hasJobName = true;
                    if (filter.id) {
                        reqBody.jobIds.push(filter.id);
                    }else{
                        reqBody.jobIds = []
                    }
                }
            } else if (hasSchedulerType && hasJobName && filter.filterType == 'Days' && filter.keywords != '') {
                reqBody.days = filter.keywords.includes('All') ? 'all' : filter.keywords.map((d: string) => d.substring(0,2).toLowerCase())
            } else if (hasSchedulerType && hasJobName && filter.filterType == 'Start Time' && filter.fromTimeValue != '' && filter.toTimeValue != '') {
                reqBody.startTime = [filter.fromTimeValue, filter.toTimeValue]
            }
        });

        if (hasJobName && hasSchedulerType) {
            onHoverFilterEffect(false);
            setSearchScheduleBtn(true);
            setRequestBody(reqBody);
            searchScheduleFunc(reqBody, '1', '100');
        }
	};

    const splitDays = (days: string) => {
        let dayNumber;
        if (days.includes('Sun')) {
            dayNumber = 0
        } else if (days.includes('Mon')) {
            dayNumber = 1
        } else if (days.includes('Tue')) {
            dayNumber = 2
        } else if (days.includes('Wed')) {
            dayNumber = 3
        } else if (days.includes('Thu') || days.includes('Thur')) {
            dayNumber = 4
        } else if (days.includes('Fri')) {
            dayNumber = 5
        } else if (days.includes('Sat')) {
            dayNumber = 6
        }
        return dayNumber;
    } 

    const addSchedulesToProject = () => {
        let reqBodySch: any = [];
        if (selectedRows.length) {
            selectedRows.filter((row: any) => {
                reqBodySch.push({
                    jobName: row.jobName,
                    jobId: row.jobId,
                    schedulerName: row.schedulerName,
                    startTime: Number(row.schedule.split(', ')[1].replace(':', '')),
                    days: splitDays(row.schedule),
                    normalCompletionTime: row.normalCompletionTime ? Number(row.normalCompletionTime.replace(/_/g, '0').replace(':','')) : null,
                    programId: Number(prgmid),
                    projectId: Number(prjid),
                    platformId: platformName == 'Teradata' ? 1 : 2
                })
            });
            addScheduleToProjectFunc(reqBodySch);
        }
    }

    async function addScheduleToProjectFunc(reqBody: any) {
        setAddProjectLoading(true);
        setOpenModal({
            isOpen: true
        })
		const res = await linkAddScheduleToProject(reqBody);
        if (res.statusCode == 200) {
            setSuccessMsg(res.data.message);
            setAddProjectLoading(false);
        } else if (res.code == 'ERR_BAD_REQUEST') {
            console.log('else if')
            setSuccessMsg('Schedules already added!');
            setAddProjectLoading(false);
        }
	}

    async function jobNameListFunc(schedulerType: string, jobName?: string, isSubmit?: boolean, filterData?: any) {
		const res = await getTeradataJobNameList(schedulerType, jobName);
		if (res.success) {
            setJobDataList(res.data.jobNames)
            setSearchInputLoading(false);
            setJobNameList(res.data.jobNames.map((jn: any) => jn.JobName));
            const jobIds = res.data.jobNames.map((jn: any) => jn.JobId)
            if (isSubmit) {
                console.log(jobIds)
                handleOnSubmit(jobIds, filterData)
            }
		}
	}

    function convertToTime(input: any) {
        // Convert the input to a string and pad with leading zeros if necessary
        let timeString = input.toString().padStart(4, '0');
        
        // Extract the hours and minutes
        let hours = timeString.slice(0, 2);
        let minutes = timeString.slice(2, 4);
        
        // Return the formatted time string
        return `${hours}:${minutes}`;
    }

    function convertToTimeString(num: any) {
        const hours = Math.floor(num / 100).toString().padStart(2, '0');
        const minutes = (num % 100).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
    

    async function searchScheduleFunc(reqBody: any, pageNumber: string, pageSize: string) {
        setTableLoading(true);
		const res = await getSearchScheduleJobNameList(reqBody, pageNumber, pageSize);
		if (res.success) {
            
            let schListData: any = res.data.selectedSchedules.map((row: any, index: number) => ({ ...row, normalCompletionTime: '', selected: false, id: index }));
            const lastData = [schListData[0]];
            let normalCompletionData = await getNormalCompletionTime(res.data.selectedSchedules[0]?.jobId)
            const defaultDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            if (normalCompletionData.data.length && schListData.length) {
                let normalCompletionList: any = []
                 schListData.map((data: any) => {
                 
                    let filteredData = normalCompletionData.data.filter((obj:any) => data.schedule === `${defaultDays[obj.Days]}, ${convertToTimeString(obj.Start_Time)}`)
       
                   if (filteredData.length) {
                    normalCompletionList.push({
                        ...data,
                        normalCompletionTime: convertToTime(filteredData[0].Normal_Completion_Time)
                    })
                   } else {
                    normalCompletionList.push({
                        ...data
                    })
                   }
                })
                schListData = normalCompletionList
            }
            addSchData = addSchData.length ? addSchData : addedSchedulesList
            console.log(addedSchedulesList, addSchData)
           if (addSchData.length) {
            let filData: any = []
            schListData.map((obj: any) => {
                console.log("FILTERRRRRRR1111", obj, addSchData)
                let filter = addSchData.filter((fil: any) => `${defaultDays[fil.Days]}, ${convertToTimeString(fil.Start_Time)}` == obj.schedule && obj.jobName == fil.Job_Name)
                console.log("FILTERRRRRRR1111", filter)
                if (!filter.length) {
                    filData.push(obj)
                }
            })
            schListData = filData
            res.data.page = {
                ...res.data.page ,
                totalElements: schListData.length
            }
           }

           setTimeout(() => {
            let table = document.getElementById(`sch_list_details${prjid}`)
            if (table) {
                const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
                    if (rdgElement) {
                        let jobsLength = schListData.length;
                        let height = 270;
                        // if (jobsLength === 1) height = jobsLength * 80;
                        // else if (jobsLength > 1 && jobsLength <=3) height = jobsLength * 65;
                        // else if (jobsLength > 3 && jobsLength <=5) height = jobsLength * 55;
                        // else 
                        if (jobsLength > 5 && jobsLength <=10) height = jobsLength * 50;
                        else if (jobsLength > 10 && jobsLength <=15) height = jobsLength * 40;
                        else height = 300;
                    rdgElement.style.height = `${height}px`
                    console.log("HEREE", height)
                }
            }
        }, 100);
          
            setTableLoading(false);
            setTableDataList(schListData);
            setPaginationData(res.data.page);
            setPage(res.data.page.currentPage);
            setPageSize(res.data.page.size);
		}
	}

    const handleModalClose = (data: any) => { 
        if (searchScheduleBtn) {
            handleAddMore()
        } else {
            setOpenModal({
                isOpen: false,
            })
        }
    }

    const handleModalExit = () => {
        setOpenModal({
            isOpen: false,
        })
    }

    const handleAddMore = () => {
        setSelectedRows([]);
        setOpenModal({
            isOpen: false,
        })
        handleClear();
    }

    useEffect(() => {
        getAddedScheduleList()
    }, [])

    useEffect(() => {
        console.log(moment('2024-08-15').format('ddd'))
        const filterData = [
            {filterType: 'Scheduler Name*', operation: 'Equals to (=)', keywords: 'All'},
            {filterType: 'Job Name', operation: 'Equals to (=)', keywords: ''}
        ]
        setFilterCriteria(filterData);
        const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/search-jobs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
    }, [openModal])

    return (
        <div className="component-container search-jobs-wrapper">        
            
            <div className={addClassFilterHoverEffect || isFromTimeActive || isEndTimeActive ? 'search-jobs--form has-cursor-hover' : 'search-jobs--form'}
            onClick={(e) => handleTimePickerMenuCloseFromOutside(e)}
            >
                <Grid container spacing={0} className="inner-grid-parent mb-5">
                    <Grid item xs={2}>
                        <div className="label mb-20">Select Platform</div>
                    </Grid>
                    <Grid item xs={10}>
                        <FormControl className="custom-dropdown-menu custom-dropdown-menu--platform">
                            <Select
                            id="custom-dropdown-menu-1"
                            displayEmpty
                            disabled
                            value={platformName}
                            onChange={handleChangePlatform}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                return <em>Platform</em>;
                                } else if (selected.includes('Teradata')) {
                                    return <><img className="platform-icon" src={TeradataIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                } else if (selected.includes('Databricks')) {
                                    return <><img className="platform-icon" src={DatabricksIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                } else if (selected.includes('PowerBI')) {
                                    return <><img className="platform-icon" src={PowerBiIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                } else if (selected.includes('Azure')) {
                                    return <><img className="platform-icon" src={AzureIcon} alt='' /> <span className='dd-title'>{selected}</span></>
                                }
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            {platformList.map((name) => (
                                <MenuItem key={name} value={name}> <img className="platform-icon" src={TeradataIcon} alt="" /> 
                                {name}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                
                {/* Dynamic filter */}
                <Grid container spacing={0} className="inner-grid-parent">
                    <Grid item xs={2}>
                        <div className="label">Filter Criteria</div>
                    </Grid>

                    <Grid item xs={10}>
                        { filterCriteria && filterCriteria.map((filter: any, idx: number) => (
                            <div key={filter.filterType+idx} className={idx == (filterCriteria.length - 1) ? '' : 'mb-20'}> 
                            { idx == 0 && <div className="form-label">Scheduler Name*</div> } 
                            { idx == 1 && <div className="form-label">Job Name</div> } 
                            { idx > 1 && 
                            <FormControl className="custom-dropdown-menu no-bg">
                                <Select
                                id={'custom-dropdown-menu-' + idx}
                                displayEmpty
                                value={filter.filterType}
                                onOpen={(e) => onHoverFilterEffect(true)}
                                onChange={(e) => {
                                    handleChangeDynamicFilter(e, idx);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <em>Filter Criteria</em>;
                                    }

                                    return selected
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {filterCriteriaList.map((name) => (
                                    <MenuItem key={name+idx} value={name} 
                                    disabled={
                                        name == 'Days' && hasDaysAdded ? true :
                                        name == 'Start Time' && hasTimeAdded ? true : false
                                    }
                                    className={hasDaysAdded || hasTimeAdded ? 'hide-temp' : ''}
                                    >{name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            }

                            {/* <FormControl className="custom-dropdown-menu no-bg">
                                <Select
                                id="custom-dropdown-menu-3"
                                displayEmpty
                                value={filter.operation}
                                onChange={(e) => {
                                    handleChangeDynamicOperation(e, idx);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <em>Operation</em>;
                                    }

                                    return selected
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {operationList.map((name) => (
                                    <MenuItem key={name} value={name}> {name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl> */}
                            
                            { idx == 0 && 
                                <FormControl className="custom-dropdown-menu no-bg">
                                    <Select
                                    id={'custom-dropdown-menu-sch-1'}
                                    displayEmpty
                                    value={filter.keywords}
                                    onChange={(e) => {
                                        handleChangeDynamicKeywords(e, idx);
                                    }}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                        return <em>Select Scheduler</em>;
                                        }

                                        return selected
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem key='all' value='All'>All</MenuItem>
                                    <MenuItem key='autosys' value='AutoSys'>AutoSys</MenuItem>
                                    <MenuItem key='control-m' value='Control-M'>Control-M</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            
                            { filter.filterType == 'Job Name' &&  <>
                                <FormControl className="job-name-dropdown">
                                <Autocomplete                 
                                options={jobNameList}
                                noOptionsText={searchInputLoading ? 'Loading...' : searchInputLoading == false ? 'No data' : 'Search Jobs'}
                                value={filter.keywords}
                                onClose={(e, val) => handleOnClose(e, idx, val)}
                                // onInputChange={(e,val) => handleChangeDynamicKeywords(e, idx, val)}
                                onChange={(e,val) => handleChangeDynamicKeywords(e, idx, val)}
                                renderInput={(params) => <TextField {...params} placeholder="Search Job Name" onChange={(e) => onChangeJobNameInput(e, idx)} onKeyDown={(e) => onChangeJobNameKeyDown(e)} />}
                                />
                                </FormControl>
                            </>}

                            { filter.filterType == 'Days' &&
                            <FormControl className="custom-dropdown-menu no-bg">
                                <Select
                                id={'custom-dropdown-menu-days-1'}
                                displayEmpty
                                multiple
                                value={filter.keywords}
                                onOpen={(e) => onHoverFilterEffect(true)}
                                onChange={(e) => {
                                    handleChangeDynamicKeywords(e, idx);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <em>All Days</em>;
                                    }

                                    return selected.join(', ')
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {daysList.map((name: any) => (
                                    <MenuItem key={name} value={name}>
                                    <Checkbox checked={filter.keywords.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            }
                            
                            { filter.filterType == 'Start Time' && <div className="filter-time-picker-wrapper">
                                {/* <div className={isFromTimeActive ? 'active custom-time-picker filter-time-picker' : 'custom-time-picker filter-time-picker'}>
                                    <MaskedInput className='custom-time-input-ele' mask="11:11" placeholder="From Time" name="time" value={filter.fromTime} onBlur={(e) => onClickMaskInputFromTime(false)} onClickCapture={(e) => onClickMaskInputFromTime(true)} 
                                    onChange={(e) => onChangeFromToTime(e, idx, 'fromTime', 'fromTimeValue')} />
                                        <div className="custom-timepicker-menu">
                                            {timeList.map((time: string) => (
                                                <div className="list" onClick={(e)=> onChangeFromToTime(e, idx, 'fromTime', 'fromTimeValue', true) } key={time}>{time}</div>
                                            ))}
                                        </div>
                                </div>
                                <div className={isEndTimeActive ? 'active custom-time-picker filter-time-picker' : 'custom-time-picker filter-time-picker'}>
                                    <MaskedInput className='custom-time-input-ele' mask="11:11" placeholder="To Time" name="time" value={filter.toTime} onBlur={(e) => onClickMaskInputToTime(false)} onClickCapture={(e) => onClickMaskInputToTime(true)} 
                                    onChange={(e) => onChangeFromToTime(e, idx, 'toTime', 'toTimeValue')} />
                                        <div className="custom-timepicker-menu">
                                            {timeList.map((time: string) => (
                                                
                                                    filter.fromTime ? compareTimes(filter.fromTime, filter.toTime) ? <div className="list" key={time}>{time}</div> : <div className="list" onClick={(e)=> onChangeFromToTime(e, idx, 'toTime', 'toTimeValue', true) } key={time}>{time}</div>  : <div className="list" onClick={(e)=> onChangeFromToTime(e, idx, 'toTime', 'toTimeValue', true) } key={time}>{time}</div>
                                                 
                                            ))}
                                        </div>
                                </div> */}
                                <div className={isFromTimeActive ? 'active custom-time-picker filter-time-picker' : 'custom-time-picker filter-time-picker'}>
    <MaskedInput 
        className='custom-time-input-ele' 
        mask="11:11" 
        placeholder="From Time" 
        name="time" 
        value={filter.fromTime} 
        onBlur={(e) => onClickMaskInputFromTime(false)} 
        onClickCapture={(e) => onClickMaskInputFromTime(true)} 
        onChange={(e) => onChangeFromToTime(e, idx, 'fromTime', 'fromTimeValue')} 
    />
    <div className="custom-timepicker-menu">
        {timeList.map((time: string) => (
            <div className="list" 
                onClick={(e) => onChangeFromToTime(e, idx, 'fromTime', 'fromTimeValue', true)} 
                key={time}>
                {time}
            </div>
        ))}
    </div>
</div>

<div className={isEndTimeActive ? 'active custom-time-picker filter-time-picker' : 'custom-time-picker filter-time-picker'}>
    <MaskedInput 
        className='custom-time-input-ele' 
        mask="11:11" 
        placeholder="To Time" 
        name="time" 
        value={filter.toTime} 
        onBlur={(e) => onClickMaskInputToTime(false)} 
        onClickCapture={(e) => onClickMaskInputToTime(true)} 
        disabled={!filter.fromTime}
        onChange={(e) => onChangeFromToTime(e, idx, 'toTime', 'toTimeValue')} 
    />
    <div className="custom-timepicker-menu">
        {timeList.map((time: string) => (
            <div 
                className={filter.fromTime && compareTimes(filter.fromTime, time) ? 'list-disabled' : 'list'} 
                key={time} 
                onClick={(e) => {
                    if (!filter.fromTime || !compareTimes(filter.fromTime, time)) {
                        onChangeFromToTime(e, idx, 'toTime', 'toTimeValue', true);
                    }
                }}
            >
                {time}
            </div>
        ))}
    </div>
</div>

                            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <TimePicker
                                        label="From"
                                        orientation="landscape"
                                        showToolbar={true}
                                        toolbarTitle={'From'}
                                        className="startTimeFrom"
                                        ampm={false}
                                        value={filter.fromTime}
                                        onChange={(e) => {onChangeTime(e, idx, 'fromTime', 'fromTimeValue')}}
                                        renderInput={(params: any) => (
                                            <TextField {...params} value={filter.fromTime} name="startTimeFrom" />
                                        )}
                                    />
                                     <TimePicker
                                        label="To"
                                        orientation="landscape"
                                        showToolbar={true}
                                        toolbarTitle={'To'}
                                        className="startTimeTo"
                                        ampm={false}
                                        value={filter.toTime}
                                        onChange={(e) => {onChangeTime(e, idx, 'toTime', 'toTimeValue')}}
                                        renderInput={(params: any) => (
                                            <TextField {...params} value={filter.toTime} name="startTimeTo" />
                                        )}
                                    />
                            </LocalizationProvider> */}
                            </div>}

                            {/* { idx > 1 && <FormControl>
                                <input className="form-input" type="text" placeholder="Type Keywords" onInput={(e) => {
                                    handleChangeDynamicKeywords(e, idx)
                                }} />
                            </FormControl>
                            } */}
                            
                            { idx != 0 && idx != 1 && 
                            <FormControl className="icon-symbol-remove" 
                                onClick={(e) => {
                                    handleRemoveFilter(idx);
                                }}
                            >
                                <img src={PlusSymbolIcon} alt='' />
                            </FormControl>
                            }

                            { idx == 1 && 
                                <FormControl className="icon-symbol-plus"
                                onClick={(e) => {
                                    handleAddFilter(idx);
                                }}
                            >
                                <img src={PlusSymbolIcon} alt='' />
                            </FormControl>
                            }

                            { idx == (filterCriteria.length - 1) && <>
                                <div className="cta-actions">
                                        <Button className="button-clear"  onClick={handleClear}>
                                            Clear
                                        </Button>
                                        <Button className="button-search" disabled={selectedJobName || jobSearchKeyword ? false : true} onClick={onSubmitSearchFetchJobIds}>
                                            Submit Search
                                        </Button>
                                    </div>
                                </>
                            }
                            </div>
                            ))}
                    </Grid>
                </Grid>
            </div>

            <div className="search-jobs--result">
                <div className="search-jobs--result-header" onClick={(e) => handleTimePickerMenuCloseFromOutside(e)}>
                    {
                        searchScheduleBtn && <h3 className="title">Select Schedules</h3>
                    } 
                    { searchScheduleBtn && tableDataList && tableDataList.length > 0 &&
                        <Button className={`${selectedRows.length ? 'add-schedules-btn' : 'add-schedules-btn disabled'}`} variant="contained" onClick={addSchedulesToProject}>Add Schedules to Project</Button>
                    }
                </div>
                { searchScheduleBtn ? (<div id={`sch_list_details${prjid}`} className="search-jobs--table">
                    <Grid>
                        {!tableLoading && tableDataList.length ? (
                            <CustomizedDataGrid
                                tableColumns={getColumns()}
                                tableData={tableDataList}
                                tableType="add-schedules"
                                defaultColumnNames={defaultColumnNames}
                                page={page}
                                pageSize={pageSize}
                                paginationData={paginationData}
                                listLength={tableDataList.length}
                                onHeaderSelectAll={handleHeaderSelectAll}
                                handlepageSizeChange={handlepageSizeChange}
                                handlePageChange={handlePageChange}
                                isDateRangeFilter={false}
                                isDisabled={false}
                                isFetching={true}
                                />
                            ) : !tableLoading && tableDataList.length == 0 ? <><p className="text-center">No result found</p></> : ( <Loader /> )
                        }
                    </Grid>
                </div>) : (<></>
                    // <><br /><br /><p className="text-center">Data will be displayed based on selected filters.</p></>
                )
                }
                <br />
            </div>

            <div className="search-jobs--result" style={{paddingTop: '0px'}}>
                <div className="search-jobs--result-header" onClick={(e) => handleTimePickerMenuCloseFromOutside(e)}>
                    <h3 className="title">Added Schedules</h3>
                    
                </div>
                { addedSchedulesList ? (<div className="search-jobs--table">
                    <Grid>
                        { !scheduleTableLoading ? (
                            <CustomizedDataGrid
                                tableColumns={getAddedScheduleColumns()}
                                tableData={addedSchedulesList}
                                tableType="added-schedules"
                                defaultColumnNames={defaultAddedScheduleColumnNames}                                onSorting={handleSorting}
                                currSorting={sortingInfo}
                                onFilterCheck={handleFilter}
                                currFilter={filterInfo}
                                filterDataOptions={filterData}
                                page={pageSchedule}
                                pageSize={pageSizeSchedule}
                                paginationData={paginationDataSchedule}
                                listLength={addedSchedulesList.length}
                                handlepageSizeChange={handlepageSizeChangeSchedule}
                                handlePageChange={handlePageChangeSchedule}
                                isDateRangeFilter={false}
                                isDisabled={false}
                                isFetching={true}
						        searchDataValues={searchFilterDetails}
						        onFilterSearch={handleFilterSearch}
                                onFilterClick={handleAndGetFilterOptions}
                                />
                            ) : scheduleTableLoading ? <><p className="text-center">Loading...</p></> : ( <Loader /> )
                        }
                    </Grid>
                </div>) : (
                    <><br /><br /><p className="text-center">Data will be displayed based on selected filters.</p></>
                )
                }
                <br />
            </div>
            <Dialog open={openModal.isOpen} onClose={handleModalClose} fullWidth={false} maxWidth="md" className="success-modal">
                <div className="modal-wrapper">
                    <div className="modal-header">Confirmation
                        { !searchScheduleBtn && <span className="close" onClick={handleModalClose}>x</span> }
                    </div>
                    <div className="modal-content">
                        { addProjectLoading && <div className="loading-wrapper">Loading...</div>}
                        { !addProjectLoading && <div className="success-content text-center">
                            <p>{ successMsg }</p>
                            {successMsg != 'Schedules already added!' && <div className="actions">
                                <Button className="close" onClick={handleModalExit}>
                                    <Link to={`/data-pipeline/projects/${prgmid}/${prjid}/${prgname}/${prjname}`}>
                                        SAVE & EXIT
                                    </Link>
                                    </Button>
                                <Button className="add-more" onClick={handleAddMore}>ADD MORE</Button>
                            </div>}
                            {successMsg == 'Schedules already added!' && <div className="actions">
                                <Button className="close" onClick={handleModalClose}>Close</Button>
                            </div>}
                        </div>}
                    </div>
                </div>
		    </Dialog>
        </div>
    )
}

export default AddSchedules
import { Stack, Typography } from '@mui/material';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';

type PageHeaderProps = {
	title: string;
	icon: string;
	lastSync?: string | null;
};

const PageHeader = ({ title, icon, lastSync }: PageHeaderProps) => {
	return (
		<Stack direction="row" gap={0.5} alignItems="center" mb={2}>
			<img src={icon} />
			<Typography variant="pageTitle">{title}</Typography>
			{!!lastSync && (
				<Stack direction="row" color="light.main" fontSize="12px" gap={0.5} ml={1}>
					<ReloadIcon /> Last Sync: {lastSync}
				</Stack>
			)}
		</Stack>
	);
};

export default PageHeader;

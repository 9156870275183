import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import '../DataEstate/data-estate.scss';
import ReactECharts from 'echarts-for-react';
import { getIncidentDetails, incidentSearchForJobExecutions, getSlaReportData, getDatabricksClusterImpactDetails, fetchChartData } from '../../service/service';
import { Column } from 'react-data-grid';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import moment from 'moment';
import { getJobExecutions } from '../../service/service';
import { OverflowTooltip } from '../overflow_tooltip';
import DateRangeFilter from 'components/Dropdown/DateRangeFilter';
import StatusChip from 'components/Table/StatusChip';
import RowTypography from 'components/Table/RowTypography';
import CustomDropdown from 'components/Dropdown';
import BarChartWidget from 'components/Widgets/BarChartWidget';

interface Row {
	id: string;
	scheduledJob: string,
	sector: string,
	system: string,
	process: string,
	projects: string,
	jobStatus: string,
	startTime: string,
	endTime: string,
	slaEndTime: string,
	slaStatus: string,
	normalCompletionTime: string,
	actualCompletionTime: string,
	currentExecutionTime: string,
	averageExecutionTime: string,
	reason: string,
	followUp: string,
	actionTaken: string,
	lastSuccessfulRun: string,
	estimatedDuration: string,
	executionFrequency: string,
	criticalFlag: string,
	date: string,
	jobName: string,
	name: string,
	runStatus: string,
	reasonForDelay: string,
	runStartTime: string,
	runEndTime: string,
	clusterName: string,
	driverNodeType: string,
	errorMessage: string,
	timestamp: string,
	projectCode: string,
	projectNumber: string,
	projectName: string,
	sparkVersion: string,
	workspaceName: string,
	clusterId: string,
	jobId: string,
	runName: string,
	status: string,
	time: string,
	dfProjectCode: string,
	dfDataAssetName: string,
	nodeType: string
}

export default function IncidentImpactAnalysis() {
	const [grafanaChartLoading, setGrafanaChartLoading] = useState(true);
	const [grafanaChartOptions, setGrafanaChartOptions] = useState<any>();
	const [performanceByDay, setPerformanceByDay] = useState<any>(false);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [month, setFilterMonth] = useState("0M");
	const params = useParams();
	const location = useLocation();
	const paramsData = location.state;
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const [executionsList, setExecutionsList] = useState<any[]>([]);
	const [boxLoading, setBoxLoading] = useState(false);
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const dateTo: string = searchParams.get('toDate')?.substring(0, 10) as string;
	const [dateTimeRange, setDateTimeRange] = useState<any>(
		[
			paramsData?.openedDate ? paramsData.openedDate : moment().startOf('day').subtract(1, 'hours'),
			paramsData?.closedDate ? paramsData.closedDate : moment().endOf('day').subtract(1, 'hours')
		]
	);
	const [reasonList, setReasonList] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	moment.updateLocale(moment.locale(), { invalidDate: '' });
	const [incidentType, setIncidentType] = useState('teradata');

	function getDatabricksColumns(): Array<Column<Row>> {
		return [
			{
                key: 'date',
                name: 'Date',
                width: 180,
                minWidth: 180,
				formatter(props) {
					const data = props.row.date ? moment(props.row.date).utc().format('YYYY-MM-DD') : '-';
					return data;
				},
			},
			{
                key: 'time',
                name: 'Time', 
                width: 180,
                minWidth: 180,
				formatter(props) {
					const data = props.row.time ? moment(props.row.time).utc().format('hh:mm:ss') : '-';
					return data;
				},
			},
			{
				key: 'workspaceName',
				name: 'Workspace Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.workspaceName ? props.row.workspaceName : '-';
					return data;
				}
			},
			{
				key: 'clusterId',
				name: 'Cluster ID',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.clusterId ? props.row.clusterId : '-';
					return data;
				}
			},
			{
				key: 'clusterName',
				name: 'Cluster Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.clusterName ? props.row.clusterName : '-';
					return <div title={props.row.clusterName} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'status',
				name: 'Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.status;
					return <StatusChip status={status}/>;
				}
			},
			{
				key: 'sparkVersion',
				name: 'Spark Version',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.sparkVersion ? props.row.sparkVersion : '-';
					return data;
				}
			},
			{
                key: 'driverNodeType',
                name: 'Driver Node Type',
                minWidth: 150,
                width: 180,
				formatter(props) {
					const data = props.row.driverNodeType ? props.row.driverNodeType : '-';
					return data;
				}
            },
			{
                key: 'nodeType',
                name: 'Node Type',
                minWidth: 150,
                width: 180,
				formatter(props) {
					const data = props.row.nodeType ? props.row.nodeType : '-';
					return data;
				}
            },
			
			{
				key: 'jobId',
				name: 'Job ID',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.jobId ? props.row.jobId : '-';
					return <div title={props.row.jobId} className='has-ellipsis'>{data}</div>;
				}
			},
			{
                key: 'runName',
                name: 'Run Name',
                width: 200,
				minWidth: 250,
				formatter(props) {
					const data = props.row.runName ? props.row.runName.replaceAll('?', '') : '-';
					return <div title={props.row.runName} className='has-ellipsis'>{data}</div>;
				}
            },
			{
                key: 'errorMessage',
                name: 'Error Message',
                width: 230,
                minWidth: 200,
				formatter(props) {
					const data = props.row.errorMessage ? props.row.errorMessage : '-';
					return <div title={props.row.errorMessage} className='has-ellipsis'>{data}</div>;
				}
            },
			{
				key: 'sector',
				name: 'Sector',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.sector ? props.row.sector : '-';
					return <div title={props.row.sector} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'projectCode',
				name: 'Project Code',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.projectCode ? props.row.projectCode : '-';
					return data;
				}
			},
			{
				key: 'projectNumber',
				name: 'Project Number',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.projectNumber ? props.row.projectNumber : '-';
					return data;
				}
			},
			{
				key: 'projectName',
				name: 'Project Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.projectName ? props.row.projectName : '-';
					return data;
				}
			},
			{
				key: 'dfProjectCode',
				name: 'DF Project Code',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.dfProjectCode ? props.row.dfProjectCode : '-';
					return data;
				}
			},
			{
				key: 'dfDataAssetName',
				name: 'DF Data Asset Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.dfDataAssetName ? props.row.dfDataAssetName : '-';
					return data;
				}
			},
		]
	}

	function getColumns(): Array<Column<Row>> {
        return [
			{
                key: 'runStartTime',
				// key: 'date',
                name: 'Date',
                width: 180,
                minWidth: 180,
				formatter(props) {
					const startDate = props.row.runStartTime;
					if (startDate) {
						return moment(startDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
					// const data = props.row.date ? moment(props.row.date).utc().format('YYYY-MM-DD') : '-';
					// return data;
				}
			},
			{
				key: 'name',
				// key: 'jobName',
				name: 'Job Name',
				minWidth: 200,
				width: 200,
				formatter(props) {
					const data = props.row.name ? props.row.name : '-';
					return <RowTypography>{data}</RowTypography>
					// const data = props.row.jobName ? props.row.jobName : '-';
					// return <RowTypography>{data}</RowTypography>
				}
			},
			{
                key: 'sector',
                name: 'Sector',
				width: 100,
				minWidth: 100,
				formatter(props) {
					const data = props.row.sector ? props.row.sector : '-';
					return <RowTypography>{data}</RowTypography>
				}
            },
			{
                key: 'system',
				name: 'System',
				width: 100,
				minWidth: 100,
				formatter(props) {
					const data = props.row.system ? props.row.system : '-';
					return <RowTypography>{data}</RowTypography>
				}
            },
			{
                key: 'process',
                name: 'Projects',
                width: 200,
				minWidth: 250,
				formatter(props) {
					const data = props.row.process ? props.row.process.replaceAll('?', '') : '-';
					return <RowTypography>{data}</RowTypography>
				}
            },
			{
				key: 'slaStatus',
				name: 'SLA Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.slaStatus;
					return <StatusChip status={status}/>;
				}
			},
			{
                key: 'slaEndTime',
				// key: 'normalCompletionTime',
                name: 'Normal Completion Time',
                minWidth: 150,
                width: 200,
				formatter(props) {
					return (
						<OverflowTooltip customTooltip={`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}>
							{props.row.slaEndTime}
						</OverflowTooltip>
					);
					// const data = props.row.normalCompletionTime ? props.row.normalCompletionTime : '-';
					// return <RowTypography>{data}</RowTypography>
				}
            },
            {
                key: 'runEndTime',
				// key: 'actualCompletionTime',
                name: 'Actual Completion Time',
                minWidth: 150,
                width: 200,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return moment(endDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
					// const data = props.row.actualCompletionTime ? props.row.actualCompletionTime : '-';
					// return data;
				}
            },
			{
				key: 'runStatus',
				// key: 'jobStatus',
				name: 'Job Status',
				width: 130,
				minWidth: 130,
				cellClass: 'center-text',
				formatter(props) {
					const status = props.row.runStatus;
					// const status = props.row.jobStatus;
					return <StatusChip status={status}/>;
				}
			},
			{
                key: 'reasonForDelay',
				// key: 'reason',
                name: 'Reason',
                width: 230,
                minWidth: 200,
				formatter(props) {
					const data = props.row.reasonForDelay ? props.row.reasonForDelay : '-';
					return <RowTypography>{data}</RowTypography>
					// const data = props.row.reason ? props.row.reason : '-';
					// return <RowTypography>{data}</RowTypography>
				}
            },
            // {
            //     key: 'scheduledJob',
            //     name: 'Scheduled Job',
            //     minWidth: 200,
            //     width: 250,
			// 	formatter(props) {
			// 		const data = props.row.scheduledJob ? props.row.scheduledJob : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'startTime',
            //     name: 'Start Time',
            //     width: 230,
            //     minWidth: 200,
			// 	formatter(props) {
			// 		const data = props.row.startTime ? props.row.startTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'endTime',
            //     name: 'End Time',
            //     width: 150,
            //     minWidth: 130,
			// 	formatter(props) {
			// 		const data = props.row.endTime ? props.row.endTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'slaEndTime',
            //     name: 'SLA End Time',
            //     minWidth: 150,
			// 	formatter(props) {
			// 		const data = props.row.slaEndTime ? props.row.slaEndTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'currentExecutionTime',
            //     name: 'Current Execution Time',
            //     minWidth: 150,
            //     width: 200,
			// 	formatter(props) {
			// 		const data = props.row.currentExecutionTime ? props.row.currentExecutionTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'averageExecutionTime',
            //     name: 'Average 15 Days Exec. Time',
            //     minWidth: 200,
            //     width: 250,
			// 	formatter(props) {
			// 		const data = props.row.averageExecutionTime ? props.row.averageExecutionTime : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'followUp',
            //     name: 'Follow Up',
            //     minWidth: 150,
            //     width: 150,
			// 	formatter(props) {
			// 		const data = props.row.followUp ? props.row.followUp : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'actionTaken',
            //     name: 'Action Taken',
            //     minWidth: 140,
            //     width: 140,
			// 	formatter(props) {
			// 		const data = props.row.actionTaken ? props.row.actionTaken : '-';
			// 		return data;
			// 	}
            // },
            // {
            //     key: 'estimatedDuration',
            //     name: 'Estimated Duration',
            //     width: 230,
            //     minWidth: 200,
			// 	formatter(props) {
			// 		const data = props.row.estimatedDuration ? props.row.estimatedDuration : '-';
			// 		return data;
			// 	}
            // },
        ];
    }

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [slaReport, setSlaReport] = useState([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);

	const defaultDatabricksColumnNames = [
		'Date',
		'Time',
		'Workspace Name',
		'Cluster ID',
		'Cluster Name',
		'Status',
		'Spark Version',
		'Driver Node Type',
		'Node Type',
		'Job ID',
		'Run Name',
		'Error Message',
		'Sector',
		'Project Code',
		'Project Number',
		'Project Name',
		'DF Project Code',
		'DF Data Asset Name',
		'Workspace ID',
        'DBR Version'
	];
	const defaultColumnNames = [
		'Date',
		'Job Name',
        'Sector',
        'System',
        'Projects',
		'SLA Status',
        'Normal Completion Time',
        'Actual Completion Time',
        'Job Status',
		'Reason'
		// 'Scheduled Job',
        // 'Start Time',
        // 'End Time',
        // // 'SLA End Time',
        // 'Current Execution Time',
        // 'Average 15 Days Exec. Time',
        // 'Reason',
        // 'Follow Up',
        // 'Action Taken',
        // 'Estimated Duration',
	];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		fetchIncidentsDetailFunc(value, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
	   fetchIncidentsDetailFunc('1', event.target.value, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo));
		// fetchIncidentsDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		resetSearchParams();
		setFilterInfo(filterInfo);
		fetchIncidentsDetailFunc(1, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	//commen
	const handleSearch = (searchDetails: any, columnKey: any) => {
		incidentSearchFunc(searchDetails, parseFilter(filterInfo, 'scheduledJob'));
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	// [Date Range Menu]
	const handleDateTimeChange = ([from, to]: any) => {
		setDateTimeRange([from, to]);
	};
	const applyDateTimeRange = ([fromDate, toDate]: any) => {
		resetSearchParams();
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, fromDate, toDate, parseFilter(filterInfo));
	};
	
	const resetSearchParams = () => {
		if (hasDrillDowned || searchParams.has('fromDate') || searchParams.has('toDate')) {
			searchParams.delete('status');
			searchParams.delete('scheduleDate');
			searchParams.delete('isDrillDowned');
			searchParams.delete('fromDate');
			searchParams.delete('toDate');
			setSearchParams(searchParams);
		}
	};

	async function fetchIncidentsDetailFunc(pageNumber: any,
		size: any,
		sortingInfo: any,
		fromDateTime: any,
    toDateTime: any,
		jobFilterInfo: any) {
		setLoading(true);
		setDateTimeRange([fromDateTime, toDateTime]);
		let incidentType = 'teradata'
		let incidentId = paramsData.incidentId
		if (paramsData?.assignmentGroup == 'ADVANCED ANALYTICS PLATFORM SUPPORT' ) {
			incidentType = 'databricks'
		}
		setIncidentType(incidentType);
		let res
		if (incidentType == 'teradata') {
			let jobFilter = {
				...jobFilterInfo,
				"criticalFlag": ["true"]
			}
			let fromDate = moment(fromDateTime).format('YYYY-MM-DD')
			let toDate = moment(fromDateTime).format('YYYY-MM-DD')
			res = await getJobExecutions(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilter,
				fromDate,
				toDate,
				true);
		} else if (incidentType == 'databricks') {
			res = await getDatabricksClusterImpactDetails(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				toDateTime,
				incidentId);
			databricksExecution(res, pageNumber, size, sortingInfo, jobFilterInfo)
		} else {
			res = await getIncidentDetails(
				pageNumber.toString(),
				size.toString(),
				sortingInfo,
				jobFilterInfo,
				fromDateTime,
				toDateTime,
				incidentType);
		}

		//DO NOT DELETE

    
	// async function fetchIncidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
	// 	setLoading(true);
	// 	setFromDateTime(fromDateTime);
	// 	setToDateTime(toDateTime);
	// 	let incidentType = 'teradata'
	// 	// if (paramsData.incidentId == 'INC08935506') {
	// 	// 	incidentType = 'databricks'
	// 	// }
	// 	setIncidentType(incidentType);
	let sla = {
		startDate: fromDateTime,
		endDate: toDateTime,
		incidentId: paramsData.incidentId
	}
		const slaData = await fetchChartData(sla)
		

		if (slaData.success) {
			// setSlaReport(slaData.data.slaReport)
			// incidentChartData(slaData.data.slaReport);
			console.log("data", slaData.data)
			setSlaReport(slaData.data)
			incidentChartData(slaData.data);
			
		}
	// 	console.log(slaData, slaReport)
	// 	const res = await getIncidentDetails(
	// 		pageNumber.toString(),
	// 		size.toString(),
	// 		sortingInfo,
	// 		jobFilterInfo,
	// 		fromDateTime,
	// 		toDateTime,
	// 		incidentType
	// 	);
		if (incidentType !== 'databricks' && res.success) {
			setIncidentDetails(res.data.jobs);
			setPaginationData(res.data.page);
			const filters = res.data.filterOptions;
			let formatedDates: any = [];
			Object.entries(filters).filter((d: any) => { if (d[0] == 'date') {
				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'))
			}  });
			filters['date'] = formatedDates
			setFilterData(filters);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			const jobExecutionsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: jobFilterInfo
			};
			localStorage.setItem('jobExecutionsState', JSON.stringify(jobExecutionsState));
		}

		setLoading(false);
	}
	const databricksExecution = (res : any,pageNumber :any, size: any, sortingInfo : any, jobFilterInfo : any) => {
		if (res.success) {
			setIncidentDetails(res.data.data);
			setPaginationData(res.data.page);
			const filters = res.data.filterOptions;
			let formatedDates: any = [];
			Object.entries(filters).filter((d: any) => { if (d[0] == 'date') {
				formatedDates = d[1].map((s: any) => moment(s).utc().format('YYYY-MM-DD'))
			}  });
			filters['date'] = formatedDates
			setFilterData(filters);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterInfo(jobFilterInfo);
			setLoading(false);
			const jobExecutionsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
			};
			localStorage.setItem('jobExecutionsState', JSON.stringify(jobExecutionsState));
		}
		setLoading(false);
	};

		async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any) {
		const res = await incidentSearchForJobExecutions(searchDetails, jobFilterInfo);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const incidentChartData = (reportData: any) => {
		// fetchChartData(paramsData).then(res => {
		// 	if (res) {
				const startDate = moment(paramsData?.openedDate);
				const endDate = moment(paramsData?.closedDate);
				const duration = moment.duration(endDate.diff(startDate));
				const hours = duration.asHours();
				if (hours >= 48) {
					setPerformanceByDay(true)
				}
				let option = {};
				let xAxisArray:any =[];
				let success:any =[];
				let failure:any=[];
				
				
				
				reportData.map((res:any,i:number)=>{
					xAxisArray.push(Object.keys(res)[0])
					// if (paramsData.incidentId != 'INC08935506') {
						success.push(res[xAxisArray[i]].success)
						
					// }
					failure.push(res[xAxisArray[i]].failure)
				})
				option = {
					backgroundColor: '#053367',
					grid: {
						top: '13%',
						left: '5%',
						right: '2%',
						bottom: '30%'
					},
					xAxis: {
						type: 'category',
						data: xAxisArray,
						axisLabel: {
							color: '#fff',
								// formatter: (element: string) => {
							// 		return element.split(' ')[2];
							// }
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							color: '#fff'
						}
					},
					series: [
						{
							silent:  true,
							name: 'Success',
							type: 'bar',
							stack: 'total',
							data: success,
							itemStyle: {
								color: 'rgba(41, 155, 255, 1)'
							}
						},
						{
							silent:  true,
							name: 'Failure',
							type: 'bar',
							stack: 'total',
							data: failure,
							itemStyle: {
								color: '#ff7f00'
							}
						}
						
					],
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					legend: {
						show: true,
						left: '35px',
						bottom: '15px',
						data: ['Successful', 'Failed'],
						textStyle: {
							color: '#fff'
						}
					},
				};
				setGrafanaChartOptions(option);
				setGrafanaChartLoading(false);
				}
		// 	});
		// }


	// const incidentChartData = (reportData: any) => {
	// 	// fetchChartData(paramsData.openedDate, paramsData.resolvedDate).then(res => {
	// 	// 	if (res) {
	// 			const startDate = moment(paramsData.openedDate);
	// 			const endDate = moment(paramsData.closedDate);
	// 			const duration = moment.duration(endDate.diff(startDate));
	// 			const hours = duration.asHours();
	// 			if (hours >= 48) {
	// 				setPerformanceByDay(true)
	// 			}
	// 			let option = {};
	// 			let xAxisArray:any =[];
	// 			let sla_met:any =[];
	// 			let sla_failed:any=[];
	// 			let sla_total:any=[];
				
	// 			// if (paramsData.incidentId == 'INC09193065') {
	// 			// 	data = [
	// 			// 	{
	// 			// 		'2024-06-15': {
	// 			// 			'sla_met': 51,
	// 			// 			'sla_missed': 34,
	// 			// 			'sla_pending': 1,
	// 			// 		}  
	// 			// 	},
	// 			// 	{
	// 			// 		'2024-06-16': {
	// 			// 			'sla_met': 47,
	// 			// 			'sla_missed': 38,
	// 			// 			'sla_pending': 1,
	// 			// 		} 
	// 			// 	},
	// 			// 	{ 
	// 			// 		'2024-06-17': {
	// 			// 		'sla_met': 82,
	// 			// 		'sla_missed': 3,
	// 			// 		'sla_pending': 1,
	// 			// 		} 
	// 			// 	},
	// 			// 	{ 
	// 			// 		'2024-06-18': {
	// 			// 			'sla_met': 82,
	// 			// 			'sla_missed': 0,
	// 			// 			'sla_pending': 1,
	// 			// 		}
	// 			// 	}];
	// 			// } else if (paramsData.incidentId == 'INC08954175') {
	// 			// 	data =  [
	// 			// 		{
	// 			// 			"00:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 3,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"01:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 1,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"02:00": {
	// 			// 				"sla_met": 1,
	// 			// 				"sla_missed": 2,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"03:00": {
	// 			// 				"sla_met": 1,
	// 			// 				"sla_missed": 1,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"04:00": {
	// 			// 				"sla_met": 1,
	// 			// 				"sla_missed": 2,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"05:00": {
	// 			// 				"sla_met": 2,
	// 			// 				"sla_missed": 4,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"06:00": {
	// 			// 				"sla_met": 1,
	// 			// 				"sla_missed": 1,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"07:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 2,
	// 			// 				"sla_pending": 1
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"08:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 3,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"09:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 0,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"10:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 3,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"11:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 0,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"12:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 1,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"13:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 2,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"14:00": {
	// 			// 				"sla_met": 1,
	// 			// 				"sla_missed": 1,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"15:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 3,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"16:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 3,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"17:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 2,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"18:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 0,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"19:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 1,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"20:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 0,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"21:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 0,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"22:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 3,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		},
	// 			// 		{
	// 			// 			"23:00": {
	// 			// 				"sla_met": 0,
	// 			// 				"sla_missed": 37,
	// 			// 				"sla_pending": 0
	// 			// 			}
	// 			// 		}
	// 			// 	]
	// 			// } else if (paramsData.incidentId == 'INC09011819') {
	// 			// 	data = [
	// 			// 		{
	// 			// 	'2024-06-13': {
	// 			// 		'sla_met': 22,
	// 			// 		'sla_missed': 59,
	// 			// 		'sla_pending': 1,
	// 			// 	}
	// 			// 	},
	// 			// 		{
	// 			// 	'2024-06-14': {
	// 			// 		'sla_met': 67,
	// 			// 		'sla_missed': 14,
	// 			// 		'sla_pending': 1,
	// 			// 		}  
	// 			// 	},
	// 			// 	{
	// 			// 		'2024-06-15': {
	// 			// 			'sla_met': 56,
	// 			// 			'sla_missed': 25,
	// 			// 			'sla_pending': 1,
	// 			// 		}  
	// 			// 	},
	// 			// 	{
	// 			// 		'2024-06-16': {
	// 			// 			'sla_met': 43,
	// 			// 			'sla_missed': 5,
	// 			// 			'sla_pending': 0,
	// 			// 		} 
	// 			// 	},
	// 			// 	{ 
	// 			// 		'2024-06-17': {
	// 			// 		'sla_met': 73,
	// 			// 		'sla_missed': 1,
	// 			// 		'sla_pending': 1,
	// 			// 		} 
	// 			// 	},
	// 			// 	{ 
	// 			// 		'2024-06-18': {
	// 			// 			'sla_met': 82,
	// 			// 			'sla_missed': 1,
	// 			// 			'sla_pending': 1,
	// 			// 		} 
	// 			// 	},
	// 			// 	{
	// 			// 	'2024-06-19': {
	// 			// 		'sla_met': 82,
	// 			// 		'sla_missed': 1,
	// 			// 		'sla_pending': 1 
	// 			// 		} 
	// 			// 	}];
	// 			// } else {
	// 			// 	data = [
	// 			// 		{
	// 			// 	'2024-05-30': {
	// 			// 		'sla_met': 0,
	// 			// 		'sla_missed': 752,
	// 			// 		'sla_pending': 0,
	// 			// 	}
	// 			// 	},
	// 			// 		{
	// 			// 	'2024-05-31': {
	// 			// 		'sla_met': 0,
	// 			// 		'sla_missed': 242,
	// 			// 		'sla_pending': 0,
	// 			// 		}  
	// 			// 	},
	// 			// 	{
	// 			// 		'2024-06-01': {
	// 			// 			'sla_met': 0,
	// 			// 			'sla_missed': 482,
	// 			// 			'sla_pending': 0,
	// 			// 		}  
	// 			// 	},
	// 			// 	{
	// 			// 		'2024-06-02': {
	// 			// 			'sla_met': 0,
	// 			// 			'sla_missed': 752,
	// 			// 			'sla_pending': 0,
	// 			// 		} 
	// 			// 	},
	// 			// 	{ 
	// 			// 		'2024-06-03': {
	// 			// 		'sla_met': 0,
	// 			// 		'sla_missed': 242,
	// 			// 		'sla_pending': 0,
	// 			// 		} 
	// 			// 	},
	// 			// 	{ 
	// 			// 		'2024-06-04': {
	// 			// 			'sla_met': 0,
	// 			// 			'sla_missed': 482,
	// 			// 			'sla_pending': 0,
	// 			// 		} 
	// 			// 	},
	// 			// 	{
	// 			// 	'2024-06-05': {
	// 			// 		'sla_met': 0,
	// 			// 		'sla_missed': 752,
	// 			// 		'sla_pending': 0 
	// 			// 		} 
	// 			// 	},
	// 			// 	{
	// 			// 	'2024-06-06': {
	// 			// 		'sla_met': 0,
	// 			// 		'sla_missed': 242,
	// 			// 		'sla_pending': 0 
	// 			// 		} 
	// 			// 	}
	// 			// ];
	// 			// }
	// 			reportData.map((res:any,i:number)=>{
	// 				xAxisArray.push(Object.keys(res)[0])
	// 				// if (paramsData.incidentId != 'INC08935506') {
	// 					sla_met.push(res[xAxisArray[i]].sla_met)
	// 					sla_total.push(res[xAxisArray[i]].sla_pending)
	// 				// }
	// 				sla_failed.push(res[xAxisArray[i]].sla_missed)
	// 			})
	// 			option = {
	// 				backgroundColor: '#053367',
	// 				grid: {
	// 					top: '13%',
	// 					left: '5%',
	// 					right: '2%',
	// 					bottom: '30%'
	// 				},
	// 				xAxis: {
	// 					type: 'category',
	// 					data: xAxisArray,
	// 					axisLabel: {
	// 						color: '#fff',
	// 						// formatter: (value: any) => {
	// 						// 	const evenHours = ['12AM', '2', '4', '6', '8', '10', '12PM', '2', '4', '6', '8', '10'];
	// 						// 	return evenHours.includes(value) ? value : '';
	// 						// }
	// 					}
	// 				},
	// 				yAxis: {
	// 					type: 'value',
	// 					axisLabel: {
	// 						color: '#fff'
	// 					}
	// 				},
	// 				series: [
	// 					{
	// 						silent:  paramsData.incidentId == 'INC08935506' ? false : true,
	// 						name: 'SLA MET',
	// 						type: 'bar',
	// 						stack: 'total',
	// 						data: sla_met,
	// 						itemStyle: {
	// 							color: 'rgba(41, 155, 255, 1)'
	// 						}
	// 					},
	// 					{
	// 						silent: paramsData.incidentId == 'INC08935506' ? false : true,
	// 						name: 'SLA MISSED',
	// 						type: 'bar',
	// 						stack: 'total',
	// 						data: sla_failed,
	// 						itemStyle: {
	// 							color: '#ff7f00'
	// 						}
	// 					},
	// 					{
	// 						silent:  true,
	// 						name: 'N/A',
	// 						type: 'bar',
	// 						stack: 'total',
	// 						data: sla_total,
	// 						itemStyle: {
	// 							color: '#A9ADCB'
	// 						}
	// 					}
	// 				],
	// 				tooltip: {
	// 					trigger: 'axis',
	// 					axisPointer: {
	// 						type: 'shadow'
	// 					}
	// 				},
	// 				legend: {
	// 					show: true,
	// 					left: '35px',
	// 					bottom: '15px',
	// 					data: paramsData.incidentId == 'INC08935506' ? ['SLA MISSED'] : ['SLA MET', 'SLA MISSED', 'N/A'],
	// 					textStyle: {
	// 						color: '#fff'
	// 					}
	// 				},
	// 			};
	// 			setGrafanaChartOptions(option);
	// 			setGrafanaChartLoading(false);
	// 		// 	}
	// 		// });
	// 	}
	useEffect(() => {
		localStorage.removeItem('incident_user_col_pref')
		fetchIncidentsDetailFunc(page, pageSize, sortingInfo, dateTimeRange[0], dateTimeRange[1], parseFilter(filterInfo));
		// fetchIncidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		// Chart
	}, [])

	const seriesData = [
		{ name: 'Success', dataKey: 'success', color: '#299bff' },
		{ name: 'Failure', dataKey: 'failure', color: '#ff7f00' },
	];

	return (
		<Stack p={2} mt='110px' gap={2}>
			<Box 
				display='flex' 
				alignItems='center' 
				gap={1}
				justifyContent='flex-end'
				position='sticky'
				top={120}
				zIndex={1}
				bgcolor="blue.darker"
			>
				<CustomDropdown
					// TODO: give correct options
					options={[
						{label: 'TERADATA PROD', value: 'TERADATA PROD'}
					]}
					value={'TERADATA PROD'}
					handleChange={()=>{}}
				/>
				{/* [Date Range Menu] */}
				<DateRangeFilter
					fromDateTime={dateTimeRange[0]}
					toDateTime={dateTimeRange[1]}
					handleChange={handleDateTimeChange}
					handleApplyDateChange={applyDateTimeRange}
					minDateTime={minJobExecDate}
				/>
			</Box>
			<Stack gap={2}>
				<BarChartWidget
					title={incidentType == 'teradata' ? (performanceByDay ? 'Job Performance By Day' : 'Job Performance By Hour') : 'Job clusters by Day'}
					isLoading={grafanaChartLoading}
					data={slaReport}
					seriesData={seriesData}
				/>
			</Stack>
			{/* Table */}
			<Box pb={2}>
				{!loading ? (
					<CustomizedDataGrid
						title='Job Executions'
						tableColumns={incidentType == 'teradata' ? getColumns() : getDatabricksColumns()}
						tableData={!!incidentDetails?.length ? incidentDetails : 0}
						tableType={incidentType == 'databricks' ? 'databricks' : 'incident'}
						defaultColumnNames={incidentType == 'teradata' ? defaultColumnNames : defaultDatabricksColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={incidentDetails && incidentDetails.length != 0 ? incidentDetails : 0}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={loading}
						showExport={false}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={false}
						isShowMoreButton
					/>
				) : (
					<Loader />
				)}
			</Box>
		</Stack>
	);
};
